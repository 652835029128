import React, { useEffect, useState } from "react"
import { Autocomplete, Grid, TextField, Typography, styled } from "@mui/material";
import DrawerCustom from "../../shared/general/Drawer";
import ButtonBlue from "../../shared/general/ButtonBlue";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import AlertResponse from "../../shared/general/AlertResponse";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import SelectAffiliateCompany from "../../shared/general/SelectAffiliateCompany";
import { getAffiliateOrganization } from "../../../../actions/company";
import LoadingGif from "../../assets/social-media.gif";
import { CompensateXlsx } from "./xlsx-export/compensate-xlsx";
import { getCompensateLeaveReport, getEmployeesFamilyReport } from "../../../../actions/report";
import { getAllEmployeesByIdCompany } from "../../../../actions/employee";
import { getUserFullName } from "../../../../utils/userData";
import { FamilyXlsx } from "./xlsx-export/family-xlsx";

const StyledRoot = styled("div")({
    maxWidth: 550,
    padding: 24,
    "& .GridTopicInput": {
        display: "flex",
        alignItems: "center",
    },
});

const StyledFooter = styled("div")({
    padding: 16,
    display: "flex",
    justifyContent: "flex-end",
    "& .cancel": {
        marginRight: 8,
    },
});

const StyledAutoComplete = styled(Autocomplete)({
    "& .MuiFilledInput-root": {
        padding: "13.5px 14px",
        paddingRight: "32px",
        "& input": {
            padding: 0
        }
    }
});

const StyledTextField = styled(TextField)({
    width: "100%",
    "& .MuiFilledInput-root": {
        backgroundColor: "#919eab14",
        height: 56,
        padding: "0px 12px",
        borderRadius: 8,
        "& .MuiFilledInput-input": {
            padding: "7px 4px",
        },
        "&.Mui-focused": {
            backgroundColor: "#919eab14",
        },
        "& .MuiInputAdornment-root": {
            width: 32,
            marginTop: "0!important",
            fontSize: 24,
            "& button": {
                color: "#919EAB",
            },
        },
        "&:hover": {
            backgroundColor: "#919eab29",
            "&:before": {
                border: "none !important",
            },
        },
        "&:after": {
            border: "none",
        },
        "&:before": {
            border: "none",
            borderBottomStyle: "none !important",
        }
    },
});

const FamilyReport = (props) => {
    const dispatch = useDispatch();
    const { open, handleClose } = props;
    const { t, i18n } = useTranslation();

    const { result: employeesList } = useSelector((state) => state.employees);
    const { result: userProfile } = useSelector((state) => state.userProfile);
    const { result: affiliateOrganizationList } = useSelector((state) => state.affiliateOrganization);

    const [openAlert, setOpenAlert] = useState(false);
    const [alertType, setAlertType] = useState(false);
    const [familyData, setFamilyData] = useState(null);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [isFetching, setIsFetching] = useState(false);
    const [selectedEmployee, setSelectedEmployee] = useState(null);

    const handleOpenAlert = () => {
        setOpenAlert(true);
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    const handleChangeAlertType = (newValue) => {
        setAlertType(newValue);
    };

    const onChangeCompany = (newValue) => {
        setSelectedCompany(newValue);
    }

    const handleSubmit = () => {
        if (familyData && familyData.length > 0) {
            FamilyXlsx(selectedEmployee, t, familyData);
        } else {
            handleChangeAlertType(t("NoData"));
            handleOpenAlert(true);
        }
    };

    const fetchData = async () => {
        try {
            setIsFetching(true);
            const response = await getEmployeesFamilyReport({
                idEmployees: selectedEmployee && selectedEmployee.idEmployees,
            });
            if (response && response.data && response.data.length > 0) {
                setIsFetching(false);
                setFamilyData(response.data);
            } else {
                handleChangeAlertType(t("NoData"));
                handleOpenAlert(true);
                setIsFetching(false);
            }
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        if (selectedEmployee && selectedEmployee.idEmployees) {
            fetchData();
        } else {
            setIsFetching(false);
        }
    }, [selectedEmployee]);

    useEffect(() => {
        dispatch(getAffiliateOrganization());
    }, []);

    useEffect(() => {
        if (userProfile && affiliateOrganizationList && affiliateOrganizationList.length > 0) {
            let ownCompany = affiliateOrganizationList.find(x => x.idCompany === userProfile.idCompany);
            if (!ownCompany) {
                ownCompany = affiliateOrganizationList[0];
            }
            setSelectedCompany(ownCompany)
        }
    }, [userProfile, affiliateOrganizationList]);

    useEffect(() => {
        if (selectedCompany) {
            dispatch(getAllEmployeesByIdCompany(selectedCompany.idCompany));
        }
    }, [selectedCompany]);

    useEffect(() => {
        if (employeesList && selectedCompany) {
            setSelectedEmployee(employeesList[0]);
        }
    }, [employeesList, selectedCompany]);

    return (
        <DrawerCustom
            title={`${t("FamilyInfo")}`}
            anchor="right"
            open={open}
            onClose={handleClose}
        >
            <StyledRoot style={{ width: 400 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography color="text.third" fontSize="14px" fontWeight="600" marginBottom="8px">{t("Company")}</Typography>
                        <SelectAffiliateCompany
                            options={affiliateOrganizationList}
                            value={selectedCompany}
                            disabled={isFetching}
                            onChange={(_, value) => {
                                onChangeCompany(value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color="text.third" fontSize="14px" fontWeight="600" marginBottom="8px">{t("Employee")}</Typography>
                        <StyledAutoComplete
                            options={employeesList ? employeesList : []}
                            getOptionLabel={(option) =>
                                getUserFullName(option)
                            }
                            isOptionEqualToValue={(option, value) => option.idEmployees === value.idEmployees}
                            renderInput={(params) => (
                                <StyledTextField
                                    {...params}
                                    InputProps={{ ...params.InputProps, endAdornment: null }}
                                    variant="filled"
                                    placeholder={t("SearchEmp")}
                                />
                            )}
                            value={selectedEmployee}
                            onChange={(event, newValue) => {
                                setSelectedEmployee(newValue);
                            }}
                            noOptionsText={t("NoData")}
                            disableClearable
                        />
                    </Grid>
                </Grid>

                <StyledFooter>
                    <ButtonBlue className="cancel" onClick={handleClose}>
                        {t("Cancel")}
                    </ButtonBlue>

                    {isFetching ? (
                        <img src={LoadingGif} style={{ width: 80, height: 80 }} />
                    ) : (
                        <ButtonBlue
                            variant="outlined"
                            startIcon={<DownloadRoundedIcon />}
                            onClick={() => handleSubmit()}
                            disabled={!familyData || isFetching}
                        >
                            {t("Download")}
                        </ButtonBlue>
                    )}
                </StyledFooter>
                <AlertResponse
                    open={openAlert}
                    handleClose={handleCloseAlert}
                    alertType={alertType}
                />
            </StyledRoot>
        </DrawerCustom>

    );
};

export default FamilyReport