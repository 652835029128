import { httpClient } from "./httpClient";
import dayjs from "dayjs";

const getNewemployees = (query) => {
  return httpClient.get("/report/newEmployees", { params: query });
};

const getResignEmployee = (query) => {
  return httpClient.get(`/report/resignEmployees`, { params: query });
};

const getContractTerminateEmployees = (query) => {
  return httpClient.get(`/report/contractTerminateEmployees`, { params: query });
};

const getAttendanceEmployees = (filter, start) => {
  return httpClient.get(
    `AttendanceEmployees?filter=${filter}&start=${dayjs(start).format(
      "YYYY-MM-DD"
    )}`
  );
};

const getProbationEmployees = (query) => {
  return httpClient.get(`/report/probationEmployees`, { params: query });
};

const getTaxemployee = (filter, start) => {
  return httpClient.get(
    `TaxEmployees?filter=${filter}&start=${dayjs(start).format("YYYY-MM-DD")}`
  );
};
const getProvidentemployee = (filter, start) => {
  return httpClient.get(
    `Providentemployee?filter=${filter}&start=${dayjs(start).format(
      "YYYY-MM-DD"
    )}`
  );
};

const getPayrollemployee = (filter, start) => {
  return httpClient.get(
    `Payrollemployee?filter=${filter}&start=${dayjs(start).format(
      "YYYY-MM-DD"
    )}`
  );
};

const getPayrollCompany = (filter, start) => {
  return httpClient.get(
    `PayrollCompany?filter=${filter}&start=${dayjs(start).format("YYYY-MM-DD")}`
  );
};

const getAllLeaveWithDraw = (filter, search) => {
  return httpClient.get(`AllLeaveWithDraw`, {
    params: {
      filter: filter ? filter : undefined,
      start:
        search && search.start
          ? dayjs(search.start).format("YYYY-MM-DD")
          : undefined,
      end:
        search && search.end
          ? dayjs(search.end).format("YYYY-MM-DD")
          : undefined,
    },
  });
};

const getAllRequestTime = (filter, search) => {
  return httpClient.get(`AllRequestTime`, {
    params: {
      filter: filter ? filter : undefined,
      start:
        search && search.start
          ? dayjs(search.start).format("YYYY-MM-DD")
          : undefined,
      end:
        search && search.end
          ? dayjs(search.end).format("YYYY-MM-DD")
          : undefined,
    },
  });
};

const getAllRequestTimeWithDraw = (filter, search) => {
  return httpClient.get(`AllRequestTimeWithDraw`, {
    params: {
      filter: filter ? filter : undefined,
      start:
        search && search.start
          ? dayjs(search.start).format("YYYY-MM-DD")
          : undefined,
      end:
        search && search.end
          ? dayjs(search.end).format("YYYY-MM-DD")
          : undefined,
    },
  });
};

const getLeaveRequestByCompany = (filter, search) => {
  return httpClient.get(`LeaveRequestByCompany`, {
    params: {
      filter: filter ? filter : undefined,
      start:
        search && search.start
          ? dayjs(search.start).format("YYYY-MM-DD")
          : undefined,
      end:
        search && search.end
          ? dayjs(search.end).format("YYYY-MM-DD")
          : undefined,
    },
  });
};

const getSummaryOvertimeShift = (query) => {
  return httpClient.get(`/report/summary-overtime-shift`, { params: query });
};

const getSummaryOvertimeShiftIndividual = (idEmployees, query) => {
  return httpClient.get(`/report/summary-overtime-shift/${idEmployees}`, { params: query });
};

const getLeaveAbsentLate = (query) => {
  return httpClient.get(`/report/leave-absent-late`, { params: query });
}

const getTotalWorkingHours = (query) => {
  const formattedStart = dayjs(query.start).format("YYYY-MM-DD");
  const formattedEnd = dayjs(query.end).format("YYYY-MM-DD");

  const url = `/report/total-working-hours?idCompany=${query.idCompany}&start=${formattedStart}&end=${formattedEnd}`;

  return httpClient.get(url);
}
const getSalaryAdjustEmployee = (query) => {
  return httpClient.get(`/salaryAdjustEmployee`, { params: query })
};

const getDashboardEmployee = (query) => {
  return httpClient.get(`/dashboardEmployee`, { params: query })
};

const getWorkingTime = (query) => {
  return httpClient.get(`/workingTime`, { params: query })
};

const getWorkingTimeReport = (query) => {
  return httpClient.get(`/report/working-time-report`, { params: query })
}

const getTimeAttendance = (query) => {
  return httpClient.get(`/report/timeAttendance`, { params: query })
};

const getLeaveDetails = (query) => {
  return httpClient.get(`groupedLeave`, { params: query })
}

const getSummaryTimeAttendanceDepartment = (query) => {
  return httpClient.get(`/report/summary-timeattendace-department`, { params: query })
};

const getLevelingReport = (query) => {
  return httpClient.get(`/updated-level-info`, { params: query })
};

const getEmploymentReport = (query) => {
  return httpClient.get(`updated-employment-type`, { params: query })
};

const getTransferPosition = (query) => {
  return httpClient.get(`updated-transfer-position`, { params: query })
};

const getDiligenceAllowanceReport = (query) => {
  return httpClient.get(`/report/diligence-allowance`, { params: query })
}

const getCompensateLeaveReport = (query) => {
  return httpClient.get(`/report/compente-report`, { params: query })
}

const getLeaveAbsentLateReport = (query) => {
  return httpClient.get(`/report/leave-absent-late-report`, { params: query });
}

const getEmployeesFamilyReport = (query) => {
  return httpClient.get(`/report/family-report`, { params: query });
}

export default {
  getWorkingTimeReport,
  getNewemployees,
  getResignEmployee,
  getContractTerminateEmployees,
  getAttendanceEmployees,
  getProbationEmployees,
  getTaxemployee,
  getProvidentemployee,
  getAllLeaveWithDraw,
  getAllRequestTime,
  getAllRequestTimeWithDraw,
  getLeaveRequestByCompany,
  getPayrollemployee,
  getPayrollCompany,
  getSummaryOvertimeShift,
  getSummaryOvertimeShiftIndividual,
  getLeaveAbsentLate,
  getTotalWorkingHours,
  getSalaryAdjustEmployee,
  getDashboardEmployee,
  getWorkingTime,
  getTimeAttendance,
  getLeaveDetails,
  getSummaryTimeAttendanceDepartment,
  getLevelingReport,
  getEmploymentReport,
  getTransferPosition,
  getDiligenceAllowanceReport,
  getCompensateLeaveReport,
  getLeaveAbsentLateReport,
  getEmployeesFamilyReport
};
