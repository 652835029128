import React, { useState, useEffect, useRef } from "react";
import { styled } from "@mui/styles";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, TextField, InputAdornment, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import SendIcon from "@mui/icons-material/Send";

import CardStyle from "../../shared/general/Card";
import Logo from "../../assets/Chatbot.png";

import {
  addChatbotDocument,
  getBotResponse,
  getChatbotDocument,
} from "../../../../actions/webhook";
import dayjs from "dayjs";

const StyledTextField = styled(TextField)({
  backgroundColor: "#E3E3E3",
  borderRadius: "40px",
  "& .MuiOutlinedInput-root": {
    borderRadius: "40px",
    "& input": {
      paddingLeft: "16px", // Adjust the left padding
    },
    "& textarea": {
      paddingLeft: "16px", // Adjust the left padding for multiline input
    },
    "& fieldset": {
      borderColor: "transparent", // Set the border color to transparent
    },
    "&:hover fieldset": {
      borderColor: "transparent", // Keep it transparent on hover
    },
    "&.Mui-focused fieldset": {
      borderColor: "transparent", // Keep it transparent when focused
    },
  },
});

const ChatBot = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [messages, setMessages] = useState([]);
  const [currentMessage, setCurrentMessage] = useState("");
  const [type, setType] = useState("");
  const [selectedFile, setSelectedFile] = useState(null); // State for file
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const [isLoading, setIsLoading] = useState(false);
  const firstDayOfMonth = dayjs().startOf("month");
  const lastDayOfMonth = dayjs().endOf("month");

  function useScrollToBottom() {
    const bottomRef = useRef(null);

    useEffect(() => {
      const observer = new MutationObserver(() => {
        if (bottomRef.current) {
          bottomRef.current.scrollIntoView({ behavior: "smooth" });
        }
      });

      const content = document.querySelector(".content");
      if (content) {
        observer.observe(content, { childList: true, subtree: true });
      }

      return () => observer.disconnect();
    }, []);

    return bottomRef;
  }
  const bottomRef = useScrollToBottom();

  useEffect(() => {
    if (userProfile && userProfile.firstname_TH) {
      setMessages([
        {
          text: `สวัสดีครับคุณ ${userProfile.firstname_TH} มีอะไรให้ Jarvis ช่วยไหมครับ?`,
          sender: "bot",
        },
      ]);
    } else {
      setMessages([
        { text: "สวัสดีครับ มีอะไรให้ Jarvis ช่วยไหมครับ?", sender: "bot" },
      ]);
    }
    // dispatch(getChatbotDocument());
  }, [userProfile]);

  const handleSend = async () => {
    if (currentMessage.trim() || selectedFile) {
      // Add user message and possibly the file
      handleSetMessages(currentMessage, "user", selectedFile);
      setCurrentMessage("");
      setSelectedFile(null); // Reset the file after sending
      setIsLoading(true);

      try {
        const response = await getBotResponse({
          message: currentMessage,
          file: selectedFile, // Pass the file if available
        });

        if (response && response.type) {
          const tag = response.type;
          setType(tag);
          handleSetMessages(response.message, "bot");
        }
      } catch (error) {
        handleSetMessages(`${t("ContactAdmin")}`, "bot");
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleSetMessages = async (
    text,
    sender,
    file = null,
    fileName = null
  ) => {
    setMessages((prevMessages) => [
      ...prevMessages,
      {
        text: text,
        sender: sender,
        file: file,
        fileName: fileName,
      },
    ]);
  };

  // File input handler
  const handleFileChange = async (event) => {
    setIsLoading(true)
    const file = event.target.files[0]; // Only select one file
    if (file) {
      // setSelectedFile(file);
      const formData = new FormData();
      formData.append("file", file);
      formData.append("fileName", file.name);
      const result = await addChatbotDocument(formData);

      if (result && result.status === 200) {
        handleSetMessages(`Uploaded file: ${file.name}`, "bot");
      } else {
        handleSetMessages(`Uploaded Error`, "bot");
      }
    }
    setIsLoading(false)
  };

  // const handleEditClick = async () => {

  // }

  return (
    <CardStyle
      style={{
        width: "100%",
        height: "80vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: "16px",
      }}
    >
      <Box className="content">
        {messages.map((message, index) => (
          <Box key={index} className={`${message.sender}-message`}>
            {message.sender === "bot" && (
              <img
                src={Logo}
                alt="Logo"
                className="bot-logo"
                width="40"
                height="40"
              />
            )}
            <Box className={`${message.sender}-bubble`}>{message.text}</Box>
          </Box>
        ))}

        {isLoading && (
          <Box className="bot-message">
            <img
              src={Logo}
              alt="Logo"
              className="bot-logo"
              width="40"
              height="40"
            />
            <Box className="bot-bubble">
              <span className="thinking-dots">
                <span></span>
                <span></span>
                <span></span>
              </span>
            </Box>
          </Box>
        )}
        <div ref={bottomRef} />
      </Box>

      <Box sx={{ display: "flex", alignItems: "center" }}>
        <input
          type="file"
          id="file-upload"
          style={{ display: "none" }}
          onChange={handleFileChange}
          accept="*/*" // Adjust file types if necessary
        />
        <label htmlFor="file-upload">
          <IconButton component="span">
            <AddIcon />
          </IconButton>
        </label>
        <IconButton component={Link} to={"/jarvis-chatbot/edit"}>
          <EditIcon />
        </IconButton>
        <StyledTextField
          multiline
          minRows={1}
          maxRows={4}
          variant="outlined"
          placeholder="Type your message here..."
          fullWidth
          value={currentMessage}
          onChange={(e) => setCurrentMessage(e.target.value)}
          disabled={isLoading}
          onKeyPress={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault();
              handleSend();
            }
          }}
          InputProps={{
            style: { resize: "none" },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleSend}>
                  <SendIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </CardStyle>
  );
};

export default ChatBot;
