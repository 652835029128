import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Chip,
  Grid,
  MenuItem,
  Popper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  autocompleteClasses,
  createFilterOptions,
  styled,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import DatePickerCustom from "../../shared/date/datePicker";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import ButtonBlue from "../../shared/general/ButtonBlue";
import SearchIcon from "@mui/icons-material/Search";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import CardStyle from "../../shared/general/Card";
import { useDispatch, useSelector } from "react-redux";
import {
  exportExcelAttendanceWeekly,
  getAttendanceWeekly,
} from "../../../../actions/attendance";

import FingerprintIcon from "@mui/icons-material/Fingerprint";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import QrCodeIcon from "@mui/icons-material/QrCode2";
import GpsFixedRoundedIcon from "@mui/icons-material/GpsFixedRounded";
import MoreTimeRoundedIcon from "@mui/icons-material/MoreTimeRounded";
import { getAllEmployees } from "../../../../actions/employee";
import { openNotificationAlert } from "../../../../actions/notificationAlert";

//Translator TH-EN
import { useTranslation } from "react-i18next";
import { getUserFullName, getUserPosition } from "../../../../utils/userData";

dayjs.extend(weekday);

const StyledRoot = styled(Box)({
  paddingTop: "24px",
  paddingBottom: "48px",
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
  "& .MuiAutocomplete-root": {
    "& .MuiFilledInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0,
      },
    },
  },
  ["@media only screen and (max-width: 599px)"]: {
    "& .button-wrap": {
      padding: 0,
    },
  },
});

const StyledTextField = styled(TextField)({
  width: "100%",
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "& .MuiFilledInput-input": {
      padding: "7px 4px",
    },
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      "& button": {
        color: "#919EAB",
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
    "&.Mui-error": {
      border: "1.5px solid #d32f2f",
    },
  },
});

const StyledCellHeader = styled(TableCell)({
  borderBottom: "none",
  color: "#637381",
  backgroundColor: "#f4f6f8",
  boxSizing: "border-box",
  "& .MuiTypography-root": {
    color: "#637381",
    backgroundColor: "#f4f6f8",
    "&.weekend": {
      fontStyle: "oblique",
    },
    "&.workday": {
      fontWeight: 600,
    },
  },
  "&.time-in-out": {
    width: 150,
    minWidth: 150,
  },
  "&:first-of-type": {
    paddingLeft: 24,
    // position: "sticky",
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    boxShadow: "20px 0px 20px #EEEEEE",
    "& .MuiTableCell-root": {
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
    },
  },
  "&:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
});

const StyledRowContent = styled(TableRow)({
  textDecoration: "none",
  "&.MuiTableRow-hover:hover": {
    backgroundColor: "#f7f7f7",
    "& .sticky": {
      backgroundColor: "#f7f7f7",
    },
  },
  "&:last-of-type": {
    "& .MuiTableCell-root": {
      borderBottom: "none",
    },
  },
});

const StyledCellContent = styled(TableCell)(({ theme }) => ({
  borderBottom: "1px dashed rgba(224, 224, 224, 1)",
  padding: 16,
  boxSizing: "border-box",
  "& .time-in-out": {
    width: 150,
    minWidth: 150,
  },
  "&:first-of-type": {
    paddingLeft: 24,
  },
  "&:last-of-type": {
    paddingRight: 24,
  },
}));

const StyledChipTime = styled(Chip)({
  height: 28,
  width: 85,
  fontSize: 14,
  borderRadius: 8,
  border: "none",
  "&.check-in": {
    color: "#229a16",
    backgroundColor: "#54d62c29",
    "& .MuiSvgIcon-root": {
      color: "#229a16",
      fontSize: 20,
    },
  },
  "&.check-out": {
    color: "#b72136",
    backgroundColor: "#ff484229",
    "& .MuiSvgIcon-root": {
      color: "#b72136",
      fontSize: 20,
    },
  },
});

const StyledPopper = styled(Popper)({
  width: "330px !important",

  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      padding: "8px 16px",
      margin: "0 8px",
      borderRadius: 8,
    },
    [`& .${autocompleteClasses.groupLabel}`]: {
      lineHeight: "32px",
    },
  },
});

const WeeklyPanel = (props) => {
  const { selectedCompany } = props;

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: AttendanceWeeklyResult, isFetching } = useSelector(
    (state) => state.attendanceWeekly
  );
  const { result: EmployeeList } = useSelector((state) => state.employees);
  const [pageConfig, setPageConfig] = useState({
    page: 0,
    count: 0,
    rowsPerPage: 10,
  });

  const validateYupSchema = yup.object({
    start: yup
      .date()
      .required(`${t("ThisFieldIsRequired")}`)
      .test("isBeforeEnd", "", (value, ctx) => {
        return dayjs(value).isSameOrBefore(dayjs(ctx.parent.end), "date");
      }),
    end: yup
      .date()
      .required(`${t("ThisFieldIsRequired")}`)
      .test("isAfterStart", "", (value, ctx) => {
        return dayjs(value).isSameOrAfter(dayjs(ctx.parent.start), "date");
      }),
  });

  let firstDayOfCurrentWeek = dayjs().weekday(1);

  const useHookForm = useForm({
    defaultValues: {
      start: new Date(firstDayOfCurrentWeek),
      end: new Date(firstDayOfCurrentWeek.add(6, "day")),
      employee: null,
    },
    resolver: yupResolver(validateYupSchema),
    mode: "all",
  });

  const fetchingAttendanceWeekly = (start, end, idEmployees, employeeID) => {
    const query = {
      start,
      end,
      idEmployees,
      employeeID
    };

    return dispatch(getAttendanceWeekly(query));
  };

  const filterOptions = createFilterOptions({
    stringify: (option) =>
      `${String(getUserFullName(option))} ${option.employeeID}`,
  });

  useEffect(() => {
    if (selectedCompany) {
      useHookForm.setValue("employee", null);
      const idEmployees = useHookForm.getValues("employee")
        ? useHookForm.getValues("employee").idEmployees
        : undefined;
      const employeeID = useHookForm.getValues("employee")
        ? useHookForm.getValues("employee").employeeID
        : undefined;
      fetchingAttendanceWeekly(
        dayjs(useHookForm.getValues("start")).format("YYYY-MM-DD"),
        dayjs(useHookForm.getValues("end")).format("YYYY-MM-DD"),
        idEmployees,
        employeeID
        
      ).then((res) => {
        if (res && res.status === 200) {
          setPageConfig((prev) => ({
            ...prev,
            page: 0,
            count: res.data.length,
          }));
        }
      });
      dispatch(getAllEmployees(null, selectedCompany.idCompany));
    }
  }, [selectedCompany]);

  const onSubmit = (data) => {
    const idEmployees = data.employee ? data.employee.idEmployees : undefined;
    const employeeID = data.employee ? data.employee.employeeID : undefined;

    fetchingAttendanceWeekly(
      dayjs(data.start).format("YYYY-MM-DD"),
      dayjs(data.end).format("YYYY-MM-DD"),
      idEmployees,
      employeeID
    ).then((res) => {
      if (res && res.status === 200) {
        setPageConfig((prev) => ({
          ...prev,
          page: 0,
          count: res.data.length,
        }));
      }
    });
  };

  const getFilterdRow = () => {
    return AttendanceWeeklyResult.slice(
      pageConfig.page * pageConfig.rowsPerPage,
      pageConfig.page * pageConfig.rowsPerPage + pageConfig.rowsPerPage
    );
  };

  const onExportExcel = async () => {
    if (!(await useHookForm.trigger())) {
      return;
    }

    const employee = useHookForm.getValues("employee");
    const idEmployees = useHookForm.getValues("employee")
      ? useHookForm.getValues("employee").idEmployees
      : undefined;

    const result = await dispatch(
      exportExcelAttendanceWeekly({
        start: dayjs(useHookForm.getValues("start")).format("YYYY-MM-DD"),
        end: dayjs(useHookForm.getValues("end")).format("YYYY-MM-DD"),
        idEmployees,
      })
    );

    if (result) {
      if (result.status === 200) {
        dispatch(
          openNotificationAlert({
            type: "success",
            message: `${t("Successfully")}`,
          })
        );
        const excelBlob = new Blob([result.data], {
          type: result.headers["content-type"],
        });
        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(excelBlob);
        downloadLink.download = `UniHR ${t("TimeStampReport")} ${
          employee
            ? [employee.firstname_TH,employee.lastname_TH]
                .filter(Boolean)
                .join(" ")
            : `${t("EmployeeList")}`
        } ${dayjs(useHookForm.getValues("start")).format(
          "DD_MM_BBBB"
        )} - ${dayjs(useHookForm.getValues("end")).format(
          "DD_MM_BBBB"
        )} ${t("DataAsOf")} ${dayjs().format("DD MMMM BBBB HH.mm")}${t("Unit.Minute")}.xlsx`;
        downloadLink.click();
      } else {
        dispatch(
          openNotificationAlert({
            type: "error",
            message: `${t("AnErrorOccurred")}``${t("ContactAdmin")}`,
          })
        );
      }
    } else {
      dispatch(
        openNotificationAlert({
          type: "error",
          message: `${t("AnErrorOccurred")}``${t("ContactAdmin")}`,
        })
      );
    }
  };

  return (
    <StyledRoot>
      <form onSubmit={useHookForm.handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <Typography className="label" color="text.third">
              {t("StartDate")}
            </Typography>
            <Controller
              name="start"
              control={useHookForm.control}
              render={({ field }) => (
                <DatePickerCustom
                  {...field}
                  minDate={dayjs().subtract(4, "year")}
                  maxDate={dayjs()}
                  views={["year", "month", "day"]}
                  inputFormat="DD/MM/YYYY"
                  openTo="year"
                  value={field.value}
                  onChange={(newValue) => {
                    field.onChange(newValue);
                  }}
                  renderInput={(params) => (
                    <StyledTextField
                      {...params}
                      variant="filled"
                      inputProps={{
                        ...params.inputProps,
                        placeholder: `${t("DayMonthYear(AD)")}`,
                      }}
                      onBlur={field.onBlur}
                      // helperText={useHookForm.formState.errors[field.name]? useHookForm.formState.errors[field.name].message: null}
                      error={
                        useHookForm.formState.errors[field.name] ? true : false
                      }
                    />
                  )}
                  PaperProps={{
                    sx: {
                      "& .Mui-selected": {
                        backgroundColor: "#46cbe2!important",
                      },
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography className="label" color="text.third">
              {t("EndDate")}
            </Typography>
            <Controller
              name="end"
              control={useHookForm.control}
              render={({ field }) => (
                <DatePickerCustom
                  {...field}
                  minDate={dayjs(useHookForm.watch("start"))}
                  maxDate={dayjs()}
                  views={["year", "month", "day"]}
                  inputFormat="DD/MM/YYYY"
                  openTo="year"
                  value={field.value}
                  onChange={(newValue) => {
                    field.onChange(newValue);
                  }}
                  renderInput={(params) => (
                    <StyledTextField
                      {...params}
                      variant="filled"
                      inputProps={{
                        ...params.inputProps,
                        placeholder: `${t("DayMonthYear(AD)")}`,
                      }}
                      onBlur={field.onBlur}
                      // helperText={useHookForm.formState.errors[field.name]? useHookForm.formState.errors[field.name].message: null}
                      error={
                        useHookForm.formState.errors[field.name] ? true : false
                      }
                    />
                  )}
                  PaperProps={{
                    sx: {
                      "& .Mui-selected": {
                        backgroundColor: "#46cbe2!important",
                      },
                    },
                  }}
                  DialogProps={{
                    sx: {
                      "& .Mui-selected": {
                        backgroundColor: "#46cbe2!important",
                      },
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography className="label" color="text.third">
              {t("SearchEmp")}
            </Typography>
            <Controller
              name="employee"
              control={useHookForm.control}
              render={({ field }) => (
                <Autocomplete
                  options={
                    EmployeeList
                      ? EmployeeList.filter((item) => {
                          if (!item.isTerminate) {
                            if (userProfile.isFillIn === 1) {
                              return (
                                item.idDepartment === userProfile.idDepartment
                              );
                            } else {
                              return item;
                            }
                          }
                        }).sort((a, b) => {
                          if (a.positionName !== b.positionName) {
                            return a.positionName.localeCompare(b.positionName);
                          } else {
                            return a.firstname_TH.localeCompare(b.firstname_TH);
                          }
                        })
                      : []
                  }
                  renderOption={(props, option) => (
                    <MenuItem {...props} key={option.idEmployees}>
                      <Box>
                        <Typography>
                          {i18n.resolvedLanguage === "th"
                          ? `${option.firstname_TH} ${option.lastname_TH}`
                          : `${option.firstname_EN} ${option.lastname_EN}`}
                        </Typography>
                        <Typography color="text.secondary" fontSize="12px">{option.employeeID}</Typography>
                      </Box>
                    </MenuItem>
                  )}
                  getOptionLabel={(option) =>
                    i18n.resolvedLanguage === "th"
                      ? `${option.firstname_TH} ${option.lastname_TH}`
                      : `${option.firstname_EN} ${option.lastname_EN}`
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.idEmployees === value.idEmployees
                  }
                  groupBy={(option) =>
                    i18n.resolvedLanguage === "th"
                      ? option.positionName
                      : option.positionName_EN
                  }
                  renderInput={(params) => (
                    <StyledTextField
                      {...params}
                      InputProps={{ ...params.InputProps, endAdornment: null }}
                      variant="filled"
                      placeholder={`${t("SelectEmp")}`}
                      onBlur={field.onBlur}
                      helperText={
                        useHookForm.formState.errors[field.name]
                          ? useHookForm.formState.errors[field.name].message
                          : null
                      }
                      error={
                        useHookForm.formState.errors[field.name] ? true : false
                      }
                    />
                  )}
                  value={field.value}
                  onChange={(_, value) => {
                    field.onChange(value);
                  }}
                  noOptionsText={`${t("NoData")}`}
                  PopperComponent={StyledPopper}
                  filterOptions={filterOptions}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3} container alignItems="center">
            <Box
              className="button-wrap"
              paddingTop="21px"
              display="flex"
              gap="8px"
            >
              <ButtonBlue
                type="submit"
                variant="contained"
                startIcon={<SearchIcon />}
              >
                {t("Search")}
              </ButtonBlue>
              <ButtonBlue
                variant="outlined"
                startIcon={<DownloadRoundedIcon />}
                onClick={onExportExcel}
              >
                {t("Download")}
              </ButtonBlue>
            </Box>
          </Grid>
        </Grid>
      </form>
      <Box paddingTop="36px">
        <Typography fontSize="24px">{t("WeeklyInOutTime")}</Typography>
      </Box>
      <Box paddingTop="36px">
        {!isFetching ? (
          <CardStyle>
            <Box padding="16px">
              <TableContainer>
                <Table>
                  <TableHead>
                    <StyledRowContent>
                      <StyledCellHeader
                        style={{ textAlign: "center", minWidth: "150px" }}
                      >
                        {t("EmployeeID")}
                      </StyledCellHeader>
                      <StyledCellHeader
                        style={{ textAlign: "center", minWidth: "200px" }}
                      >
                        {t("FirstName")} - {t("LastName")}
                      </StyledCellHeader>
                      <StyledCellHeader
                        style={{ textAlign: "center", minWidth: "300px" }}
                      >
                        {t("Position")}
                      </StyledCellHeader>
                      <StyledCellHeader
                        style={{ textAlign: "center", minWidth: "130px" }}
                      >
                        {t("Date")}
                      </StyledCellHeader>
                      <StyledCellHeader
                        className="time-in-out"
                        style={{ textAlign: "center", minWidth: "200px" }}
                      ></StyledCellHeader>
                    </StyledRowContent>
                  </TableHead>
                  <TableBody>
                    {getFilterdRow().map((item) => (
                      <StyledRowContent key={item.idAttendance}>
                        <StyledCellContent
                          style={{ textAlign: "center", minWidth: "150px" }}
                        >
                          <Typography fontSize="14px" color="text.secondary">
                            {item.employeeID}
                          </Typography>
                        </StyledCellContent>
                        <StyledCellContent
                          style={{ textAlign: "center", minWidth: "200px" }}
                        >
                          {getUserFullName(item)}
                        </StyledCellContent>
                        <StyledCellContent
                          style={{ textAlign: "center", minWidth: "300px" }}
                        >
                          <Typography 
                            fontSize="14px"
                            color="text.secondary"
                          >
                            {getUserPosition(item)}
                          </Typography>
                        </StyledCellContent>
                        <StyledCellContent
                          style={{ textAlign: "center", minWidth: "130px" }}
                        >
                          {dayjs(item.attendanceDateTimeText).format(
                            i18n.resolvedLanguage === "th"
                              ? "DD/MM/BBBB"
                              : "DD/MM/YYYY"
                          )}
                        </StyledCellContent>
                        <StyledCellContent
                          className="time-in-out"
                          style={{ textAlign: "center", minWidth: "200px" }}
                        >
                          <StyledChipTime
                            icon={
                              item.idAttendanceType === 2 ? (
                                <FingerprintIcon />
                              ) : item.idAttendanceType === 5 ? (
                                <QrCodeIcon />
                              ) : item.idAttendanceType === 4 ? (
                                <GpsFixedRoundedIcon />
                              ) : item.idAttendanceType === 8 ? (
                                <MoreTimeRoundedIcon />
                              ) : (
                                <LocationOnIcon />
                              )
                            }
                            label={dayjs(item.attendanceDateTimeText).format(
                              "HH:mm"
                            )}
                            variant="outlined"
                            className={
                              item.isCheckIn ? "check-in" : "check-out"
                            }
                          />
                          <Typography fontSize="12px" color="text.secondary">
                            {item.gpsAddress
                              ? item.gpsAddress
                              : item.gpsLocationName
                              ? item.gpsLocationName
                              : item.groupGpsLocationName
                              ? item.groupGpsLocationName
                              : ""}
                          </Typography>
                        </StyledCellContent>
                      </StyledRowContent>
                    ))}
                    {AttendanceWeeklyResult.length === 0 && (
                      <StyledRowContent>
                        <StyledCellContent colSpan={5}>
                          <Typography
                            fontSize="24px"
                            color="text.secondary"
                            textAlign="center"
                          >
                            {t("NoData")}
                          </Typography>
                        </StyledCellContent>
                      </StyledRowContent>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={pageConfig.count}
                rowsPerPage={pageConfig.rowsPerPage}
                page={pageConfig.page}
                onPageChange={(_, newPage) => {
                  setPageConfig((prev) => ({
                    ...prev,
                    page: newPage,
                  }));
                }}
                onRowsPerPageChange={(event) => {
                  setPageConfig((prev) => ({
                    ...prev,
                    page: 0,
                    rowsPerPage: Number(event.target.value),
                  }));
                }}
                labelRowsPerPage={`${t("RowsPerPage")}`}
                        labelDisplayedRows={({ from, to, count }) =>
                          `${from}-${to} ${t("OutOf")} ${
                            count !== -1 ? count : `${t("MoreThan")} ${to}`
                          }`
                        }
              />
            </Box>
          </CardStyle>
        ) : (
          <Typography fontSize="24px" textAlign="center">
            {`${t("LoadingData")}`}
          </Typography>
        )}
      </Box>
    </StyledRoot>
  );
};

export default WeeklyPanel;
