import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { Badge, Container, Grid, MenuItem, Tab, Tabs } from "@mui/material";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import DatePickerCustom from "../../shared/date/datePicker";
import dayjs from "dayjs";
import "dayjs/locale/th";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import useMediaQuery from "@mui/material/useMediaQuery";

import ButtonBlue from "../../shared/general/ButtonBlue";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import SnackBarCustom from "../../shared/snackbarCustom";
import RequestTime from "./RequestTime";
import RequestOverTime from "./RequestOverTime";
import RequestLeave from "./RequestLeave";
import RequestTimeShift from "./RequestTimeShift";
import RequestProbation from "./RequestProbation";
// import RequestEstimateYear from "./RequestEstimateYear";
// import RequestListExitInterview from "./RequestListExitInterview";
// import RequestJobs from "./RequestJobs";
import RequestCompensate from "./RequestCompensate";
import RequestInputHourUserWorkforce from "./RequestInputHourUserWorkforce"

import {
  getAllRequestTimeBy,
  getAllRequestTimeByManager,
  getAllRequestTimeWithDrawBy,
  getAllRequestTimeWithDrawByManager,
  getNumberOfEachPendingListManager,
} from "../../../../actions/requestTime";
import {
  getLeaveRequest,
  getEmployeeChangeTime,
  getProbationEmployees,
  getEstimatePerformanceEmployees,
  getExitInterviewEmployees,
} from "../../../../actions/employee";
import { getAllLeaveWithDrawBy, getManagerPendingRequestLeave } from "../../../../actions/leave";
import { getPayrollSetting } from "../../../../actions/paytypes";
import {
  getEstimatePerformance,
  getPeriodEstimate,
} from "../../../../actions/estimatePerformance";
import { getExitInterviewAndAnswer } from "../../../../actions/exitInterview";
import { getJobsManager } from "../../../../actions/jobRecruit";

//Translator TH-EN
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { getManagerProjectToApprove } from "../../../../actions/project";
import RequestProject from "./RequestProjectProfile";
import {
  getUsersTimeforApprove
} from "../../../../actions/workforceEmployees";
import loading from "../../assets/social-media.gif";
var isSameOrBefore = require("dayjs/plugin/isSameOrBefore");

dayjs.extend(isSameOrBefore);

const StyledTextField = styled(TextField)({
  width: "100%",
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "& .MuiFilledInput-input": {
      padding: "7px 4px",
    },
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      "& button": {
        color: "#919EAB",
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledBoxSearch = styled(Box)({
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
  "& label": {
    display: "none",
  },
});

const StyledCard = styled(Card)({
  padding: 16,
  boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  borderRadius: 20,
  "& .MuiCardContent-root": {
    padding: 24,
  },
});

const StyledDivTab = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingBottom: 24,
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "flex-end",
    "& .numberOfList": {
      marginTop: 16,
    },
  },
}));

const StyledRoot = styled("div")(({ theme }) => ({
  background: "#FFFFFF !important",
  "& .displayFlexEnd": {
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "8px 0",
    },
  },
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -24,
    top: 8,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
    backgroundColor: "#e46a76",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Requestlist(props) {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [search, setSearch] = React.useState({
    start: null,
    end: null,
  });
  const [isSubmit, setIsSubmit] = useState(false);
  const [approveTabsValue, setApproveTabsValue] = React.useState(0);
  const [numberOfList, setNumberOfList] = useState(0);
  const [employeeProject, setEmployeeProject] = useState(null);

  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { isFetching: isFetchingEmployeeChangeShift, result: employeeChangeShiftList } = useSelector(
    (state) => state.employeeChangeShift
  );
  const { isFetching: isFetchingRequestTime, result: requestTimeList } = useSelector((state) => state.requestTime);
  const { isFetching: isFetchingRequestTimeWithDraw, result: requestTimeWithDrawList } = useSelector(
    (state) => state.requestTimeWithDraw
  );
  const { isFetching: isFetchingLeaveEmployees, result: leaveEmployeesList } = useSelector(
    (state) => state.leaveEmployees
  );
  const { isFetching: isFetchingLeaveEmployeeWithdraws, result: leaveEmployeeWithdrawsList } = useSelector(
    (state) => state.leaveEmployeesWithDraw
  );
  const { result: payrollSetting } = useSelector(
    (state) => state.payrollSetting
  );
  const { isFetching: isFetchingEmployeesProbation, result: employeesProbation } = useSelector(
    (state) => state.employeesProbation
  );
  const { result: employeesEstimatePerformance } = useSelector(
    (state) => state.employeesEstimatePerformance
  );
  const { result: employeesExitInterview } = useSelector(
    (state) => state.employeesExitInterview
  );
  const { result: jobRecruitManager } = useSelector(
    (state) => state.jobRecruitManager
  );

  const { result: managerPendingRequestLeave, isFetching: isFetchingManagerPendingRequestLeave } = useSelector(state => state.managerPendingRequestLeave);

  const mobile = useMediaQuery("(max-width:600px)");

  const [snackBarConfig, setSnackBarConfig] = useState({
    open: false,
    message: "",
    type: "success",
  });

  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    if (userProfile) {
      dispatch(getPayrollSetting());
    }
  }, [userProfile]);

  useEffect(() => {
    if (userProfile) {
      if (selectedTab === 0 || selectedTab === 1 || selectedTab === 8) {
        dispatch(getAllRequestTimeByManager({
          filter: "manager",
          start: search.start ? dayjs(search.start).format("YYYY-MM-DD") : null,
          end: search.end ? dayjs(search.end).format("YYYY-MM-DD") : null,
          tab: selectedTab
        }));
        dispatch(getAllRequestTimeWithDrawByManager({
          filter: "manager",
          start: search.start ? dayjs(search.start).format("YYYY-MM-DD") : null,
          end: search.end ? dayjs(search.end).format("YYYY-MM-DD") : null,
          tab: selectedTab
        }));
      } else if (selectedTab === 2) {
        dispatch(getLeaveRequest("manager", search));
        dispatch(getAllLeaveWithDrawBy("manager", search));
        dispatch(getManagerPendingRequestLeave({
          start: search.start ? dayjs(search.start).format("YYYY-MM-DD") : null,
          end: search.end ? dayjs(search.end).format("YYYY-MM-DD") : null,
        }));
      } else if (selectedTab === 3) {
        dispatch(getEmployeeChangeTime("manager"));
      } else if (selectedTab === 9) {
        dispatch(getProbationEmployees());
      } else {
        // dispatch(getEstimatePerformanceEmployees());
        // dispatch(getEstimatePerformance());
        // dispatch(getExitInterviewAndAnswer());
        // dispatch(getExitInterviewEmployees());
        // dispatch(getJobsManager());
        dispatch(getUsersTimeforApprove());
      }
    }
  }, [userProfile, selectedTab]);

  const { isFetching: isFetchingProjectGetUserTimeforApprove, result: projectGetUserTimeforApprove } = useSelector(
    (state) => state.projectGetUserTimeforApprove
  );

  const fetchedProjectToApprove = async (date) => {
    try {
      const response = await getManagerProjectToApprove(date.start, date.end);
      if (response && response.data && response.status === 200) {
        setEmployeeProject(response.data);
      } else {
        setEmployeeProject(null);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchedProjectToApprove(search);
  }, []);

  const handleApproveTabsChange = (_, newValue) => {
    setSelectedTab(newValue);
    setApproveTabsValue(newValue);
  };

  const handleApproveTabsChangeSelect = (event) => {
    setApproveTabsValue(event.target.value);
    setSelectedTab(event.target.value);
    setApproveTabsValue(event.target.value);
  };

  const handleClickSearch = async () => {
    setIsSubmit(true);
    if (
      dayjs(search.start).isValid() &&
      dayjs(search.end).isValid() &&
      dayjs(search.start).isSameOrBefore(search.end, "day")
    ) {
      dispatch(getLeaveRequest("manager", search));
      dispatch(getAllLeaveWithDrawBy("manager", search));
      dispatch(getAllRequestTimeBy("manager", search));
      dispatch(getAllRequestTimeWithDrawBy("manager", search));
      dispatch(getManagerPendingRequestLeave({
        start: search.start ? dayjs(search.start).format("YYYY-MM-DD") : null,
        end: search.end ? dayjs(search.end).format("YYYY-MM-DD") : null,
      }));
      fetchedProjectToApprove(search);
      setIsSubmit(false);
    }
  };

  const [numberOfPendingList, setNumberOfPendingList] = useState(null);

  const fetchedNumberOfPendingListManager = async () => {
    try {
      const response = await getNumberOfEachPendingListManager({
        start: search.start ? dayjs(search.start).format("YYYY-MM-DD") : null,
        end: search.end ? dayjs(search.end).format("YYYY-MM-DD") : null,
      });
      if (response && response.data) {
        setNumberOfPendingList(response.data);
      }
    } catch (error) {
      console.error(error);
    };
  };

  useEffect(() => {
    if (userProfile) {
      fetchedNumberOfPendingListManager();
    }
  }, [userProfile]);

  const renderListCount = (mode) => {
    if (
      userProfile &&
      requestTimeList &&
      requestTimeWithDrawList &&
      (mode === "time" || mode === "ot" || mode === "compensate")
    ) {
      let numberPending = 0;

      requestTimeList.map((r) => {
        if (
          r.idRequestType ===
          (mode === "time" ? 1 : mode === "compensate" ? 3 : 2)
        ) {
          if (r.isDoubleApproval === 1) {
            if (r.managerLV1ApproveBy === userProfile.idEmployees) {
              if (r.isManagerLV1Approve !== 1 && r.isManagerLV1Approve !== 0) {
                numberPending++;
              }
            } else if (r.managerLV2ApproveBy === userProfile.idEmployees) {
              if (r.isManagerLV2Approve !== 1 && r.isManagerLV2Approve !== 0) {
                numberPending++;
              }
            }
          } else {
            if (r.approvalLevel === 1) {
              if (r.isManagerLV1Approve !== 1 && r.isManagerLV1Approve !== 0) {
                numberPending++;
              }
            } else if (r.approvalLevel === 2) {
              if (r.isManagerLV2Approve !== 1 && r.isManagerLV2Approve !== 0) {
                numberPending++;
              }
            }
          }
        }
      });

      requestTimeWithDrawList.map((r) => {
        if (
          r.idRequestType ===
          (mode === "time" ? 1 : mode === "compensate" ? 3 : 2)
        ) {
          if (r.isApprove === null) {
            numberPending++;
          }
        }
      });

      return numberPending;
    } else if (
      userProfile &&
      leaveEmployeesList &&
      leaveEmployeeWithdrawsList &&
      mode === "leave"
    ) {
      let numberPending = 0;

      leaveEmployeesList.map((r) => {
        if (r.isApprove === null) {
          numberPending++;
        }
      });

      leaveEmployeeWithdrawsList.map((r) => {
        if (r.isApprove === null) {
          numberPending++;
        }
      });

      return numberPending;
    } else if (userProfile && employeeChangeShiftList && mode === "timeShift") {
      let numberPending = 0;

      employeeChangeShiftList.map((r) => {
        if (r.isApprove === null) {
          numberPending++;
        }
      });

      return numberPending;
    } else if (userProfile && employeesProbation && mode === "probation") {
      return employeesProbation.length;
    } else if (
      userProfile &&
      employeesEstimatePerformance &&
      mode === "timeEstimate"
    ) {
      return employeesEstimatePerformance.length;
    } else if (
      userProfile &&
      employeesExitInterview &&
      mode === "exitInterview"
    ) {
      let numberPending = 0;
      employeesExitInterview.map((r) => {
        numberPending++;
      });
      return numberPending;
    } else if (userProfile && jobRecruitManager && mode === "jobOpening") {
      let numberPending = 0;
      jobRecruitManager.map((r) => {
        numberPending++;
      });
      return numberPending;
    } else if (userProfile && employeeProject && mode === "Project") {
      let numberPending = 0;
      employeeProject.map((r) => {
        numberPending++;
      });
      return numberPending;
    } else if (userProfile && projectGetUserTimeforApprove && mode === "workforce") {
      let numberPending = 0;
      if (!search || !search.start || !search.end) {
        projectGetUserTimeforApprove.filter((data) => data.isApprove === 0 || !data.isApprove).map((r) => {
          numberPending++;
        });
      } else if (search.start && search.end) {
        let tempPending = projectGetUserTimeforApprove.filter((item) =>
          dayjs(item.workDate).isBetween(
            search.start || dayjs(),
            search.end || dayjs(),
            null,
            '[]'
          )
          && (item.isApprove === 0 || !item.isApprove)
        ).map((r) => {
          numberPending++;
        })
      }

      return numberPending;
    } else {
      return 0;
    }
  };

  // const getLeaveRequestCount = () => {
  //   return managerPendingRequestLeave.length;
  // }

  return (
    <StyledRoot className="page">
      <SnackBarCustom config={snackBarConfig} setConfig={setSnackBarConfig} />

      <Container maxWidth="lg" style={{ paddingBottom: 40 }}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h4" gutterBottom style={{ paddingTop: 8 }}>
              {`${t("PendingApprovalList")}`}
            </Typography>
          </Grid>
          <Grid item className="displayFlexEnd">
            <ButtonBlue
              variant="contained"
              style={{ height: "fit-content" }}
              startIcon={<AccessTimeIcon />}
              component={Link}
              to={"/history-approve"}
            >
              {`${t("ApprovalHistory")}`}
            </ButtonBlue>
          </Grid>
        </Grid>
        <Box style={{ paddingBottom: 16 }}>
          <Grid container alignItems="flex-end" spacing={2}>
            <Grid item xs={12} sm={3}>
              <StyledBoxSearch>
                <Typography className="label" color="text.third">
                  {`${t("StartDate")}`}
                </Typography>
                <div className="search-date">
                  <DatePickerCustom
                    label={`${t("SelectDate")}`}
                    inputFormat="DD/MM/YYYY"
                    minDate={dayjs().subtract(1, "year").startOf("year")}
                    maxDate={dayjs().add(1, "year").endOf("year")}
                    value={search.start}
                    name="start"
                    views={["year", "month", "day"]}
                    onChange={(newValue) => {
                      if (isSubmit) {
                        setIsSubmit(false);
                      }
                      setSearch({
                        ...search,
                        start: newValue,
                      });
                    }}
                    showToolbar={false}
                    renderInput={(params) => (
                      <StyledTextField
                        {...params}
                        variant="filled"
                        fullWidth
                        error={
                          isSubmit &&
                          (!dayjs(search.start).isValid() ||
                            !dayjs(search.start).isSameOrBefore(
                              search.end,
                              "day"
                            ))
                        }
                        helperText={
                          isSubmit &&
                          (!dayjs(search.start).isValid() ||
                            !dayjs(search.start).isSameOrBefore(
                              search.end,
                              "day"
                            )) &&
                          `${t("PleaseEnterCorrectDate")}`
                        }
                      />
                    )}
                  />
                </div>
              </StyledBoxSearch>
            </Grid>
            <Grid item xs={12} sm={3}>
              <StyledBoxSearch>
                <Typography className="label" color="text.third">
                  {`${t("EndDate")}`}
                </Typography>
                <div className="search-date">
                  <DatePickerCustom
                    label={`${t("SelectDate")}`}
                    inputFormat="DD/MM/YYYY"
                    minDate={dayjs(search.start)}
                    maxDate={dayjs().add(1, "year").endOf("year")}
                    value={search.end}
                    name="start"
                    views={["year", "month", "day"]}
                    onChange={(newValue) => {
                      if (isSubmit) {
                        setIsSubmit(false);
                      }
                      setSearch({
                        ...search,
                        end: newValue,
                      });
                    }}
                    showToolbar={false}
                    renderInput={(params) => (
                      <StyledTextField
                        {...params}
                        variant="filled"
                        fullWidth
                        error={
                          isSubmit &&
                          (!dayjs(search.end).isValid() ||
                            !dayjs(search.start).isSameOrBefore(
                              search.end,
                              "day"
                            ))
                        }
                        helperText={
                          isSubmit &&
                          (!dayjs(search.end).isValid() ||
                            !dayjs(search.start).isSameOrBefore(
                              search.end,
                              "day"
                            )) &&
                          `${t("PleaseEnterCorrectDate")}`
                        }
                      />
                    )}
                  />
                </div>
              </StyledBoxSearch>
            </Grid>
            <Grid item xs={12} sm={3}>
              <div
                style={{
                  paddingBottom:
                    isSubmit &&
                      (!dayjs(search.start).isValid() ||
                        !dayjs(search.end).isValid() ||
                        !dayjs(search.start).isSameOrBefore(search.end, "day"))
                      ? 32
                      : 12,
                }}
              >
                <ButtonBlue variant={"contained"} onClick={handleClickSearch}>
                  {`${t("Search")}`}
                </ButtonBlue>
              </div>
            </Grid>
          </Grid>
        </Box>
        <StyledCard>
          <StyledDivTab>
            {mobile ? (
              <TextFieldTheme
                value={approveTabsValue}
                onChange={handleApproveTabsChangeSelect}
                select
              >
                <MenuItem value={0}>
                  <StyledBadge
                    // badgeContent={renderListCount("time")}
                    badgeContent={numberOfPendingList ? numberOfPendingList.workingTime + numberOfPendingList.withdrawWorkingTime : 0}
                    color="secondary"
                    max={999}
                  >
                    {`${t("WorkingTimeRequest")}`}
                  </StyledBadge>
                </MenuItem>
                <MenuItem value={1}>
                  <StyledBadge
                    // badgeContent={renderListCount("ot")}
                    badgeContent={numberOfPendingList ? numberOfPendingList.overTime + numberOfPendingList.withdrawOverTime : 0}
                    color="secondary"
                    max={999}
                  >
                    {`${t("Overtime")}`}
                  </StyledBadge>
                </MenuItem>
                <MenuItem value={8}>
                  <StyledBadge
                    // badgeContent={renderListCount("compensate")}
                    badgeContent={numberOfPendingList ? numberOfPendingList.compensate + numberOfPendingList.withdrawCompensate : 0}
                    color="secondary"
                    max={999}
                  >
                    {`${t("CompensateRequest")}`}
                  </StyledBadge>
                </MenuItem>
                <MenuItem value={2}>
                  <StyledBadge
                    // badgeContent={renderListCount("leave")}
                    badgeContent={numberOfPendingList ? numberOfPendingList.leave : 0}
                    // badgeContent={getLeaveRequestCount()}
                    color="secondary"
                    max={999}
                  >
                    {`${t("LeaveRequest")}`}
                  </StyledBadge>
                </MenuItem>
                <MenuItem value={3}>
                  <StyledBadge
                    badgeContent={numberOfPendingList ? numberOfPendingList.shift : 0}
                    // badgeContent={renderListCount("timeShift")}
                    color="secondary"
                    max={999}
                  >
                    {`${t("ShiftRequest")}`}
                  </StyledBadge>
                </MenuItem>
                <MenuItem value={9}>
                  <StyledBadge
                    badgeContent={renderListCount("workforce")}
                    color="secondary"
                    max={999}
                  >
                    {t("TimekeepingRequest")}
                  </StyledBadge>
                </MenuItem>
              </TextFieldTheme>
            ) : (
              <Tabs
                value={approveTabsValue}
                onChange={handleApproveTabsChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="basic tabs example"
                sx={{
                  "& .MuiTab-root": {
                    textTransform: "none",
                  },
                  "& .MuiTab-root.Mui-selected": {
                    color: "#46cbe2",
                  },
                }}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "#46cbe2",
                  },
                }}
              >
                <Tab
                  style={{
                    // minWidth: renderListCount("time") ? 240 : 160 
                    minWidth: numberOfPendingList && (numberOfPendingList.workingTime || numberOfPendingList.withdrawWorkingTime) ? 240 : 160
                  }}
                  label={
                    <>
                      <StyledBadge
                        // badgeContent={renderListCount("time")}
                        badgeContent={numberOfPendingList ? numberOfPendingList.workingTime + numberOfPendingList.withdrawWorkingTime : 0}
                        color="secondary"
                        max={999}
                      >
                        {`${t("WorkingTimeRequest")}`}
                      </StyledBadge>
                    </>
                  }
                  {...a11yProps(0)}
                  value={0}
                />
                <Tab
                  style={{
                    // minWidth: renderListCount("ot") ? 200 : 140
                    minWidth: numberOfPendingList && (numberOfPendingList.overTime || numberOfPendingList.withdrawOverTime) ? 200 : 140
                  }}
                  label={
                    <>
                      <StyledBadge
                        // badgeContent={renderListCount("ot")}
                        badgeContent={numberOfPendingList ? numberOfPendingList.overTime + numberOfPendingList.withdrawOverTime : 0}
                        color="secondary"
                        max={999}
                      >
                        {`${t("Overtime")}`}
                      </StyledBadge>
                    </>
                  }
                  {...a11yProps(1)}
                  value={1}
                />

                <Tab
                  style={{
                    // minWidth: renderListCount("compensate") ? 260 : 220,
                    minWidth: numberOfPendingList && (numberOfPendingList.compensate || numberOfPendingList.withdrawCompensate) ? 260 : 220,
                  }}
                  label={
                    <>
                      <StyledBadge
                        badgeContent={numberOfPendingList ? numberOfPendingList.compensate + numberOfPendingList.withdrawCompensate : 0}
                        // badgeContent={renderListCount("compensate")}
                        color="secondary"
                        max={999}
                      >
                        {`${t("CompensateRequest")}`}
                      </StyledBadge>
                    </>
                  }
                  {...a11yProps(8)}
                  value={8}
                />
                <Tab
                  style={{
                    // minWidth: getLeaveRequestCount() ? 200 : 120 
                    minWidth: numberOfPendingList && numberOfPendingList.leave ? 200 : 120
                  }}
                  label={
                    <>
                      <StyledBadge
                        badgeContent={numberOfPendingList ? numberOfPendingList.leave : 0}
                        // badgeContent={getLeaveRequestCount()}
                        color="secondary"
                        max={999}
                      >
                        {`${t("LeaveRequest")}`}
                      </StyledBadge>
                    </>
                  }
                  {...a11yProps(2)}
                  value={2}
                />
                <Tab
                  style={{
                    // minWidth: renderListCount("probation") ? 220 : 140,
                    minWidth: numberOfPendingList && numberOfPendingList.probation ? 220 : 140,
                  }}
                  label={
                    <>
                      <StyledBadge
                        // badgeContent={renderListCount("probation")}
                        badgeContent={numberOfPendingList ? numberOfPendingList.probation : 0}
                        color="secondary"
                        max={999}
                      >
                        {t("Probation")}
                      </StyledBadge>
                    </>
                  }
                  {...a11yProps(9)}
                  value={9}
                />
                <Tab
                  style={{
                    // minWidth: renderListCount("timeShift") ? 180 : 140
                    minWidth: numberOfPendingList && numberOfPendingList.shift ? 180 : 140,
                  }}
                  label={
                    <>
                      <StyledBadge
                        // badgeContent={renderListCount("timeShift")}
                        badgeContent={numberOfPendingList ? numberOfPendingList.shift : 0}
                        color="secondary"
                        max={999}
                      >
                        {`${t("ShiftRequest")}`}
                      </StyledBadge>
                    </>
                  }
                  {...a11yProps(3)}
                  value={3}
                />
                {userProfile && userProfile.idCompany === 1 && (
                  <Tab
                    style={{ minWidth: renderListCount("Project") ? 160 : 120 }}
                    label={
                      <>
                        <StyledBadge
                          badgeContent={renderListCount("Project")}
                          color="secondary"
                          max={999}
                        >
                          {`${t("Project")}`}
                        </StyledBadge>
                      </>
                    }
                    {...a11yProps(10)}
                    value={10}
                  />
                )}
                {/*<Tab
                  style={{
                    minWidth: renderListCount("timeEstimate") ? 220 : 140,
                  }}
                  label={
                    <>
                      <StyledBadge
                        badgeContent={renderListCount("timeEstimate")}
                        color="secondary"
                        max={999}
                      >
                        {`${t("WaitingWorkPerformanceEvaluation")}`}
                      </StyledBadge>
                    </>
                  }
                  {...a11yProps(5)}
                  value={5}
                />
                 <Tab
                  style={{
                    minWidth: renderListCount("exitInterview") ? 160 : 120,
                  }}
                  label={
                    <>
                      <StyledBadge
                        badgeContent={renderListCount("exitInterview")}
                        color="secondary"
                        max={999}
                      >
                        {`${t("ResignationRequest")}`}
                      </StyledBadge>
                    </>
                  }
                  {...a11yProps(6)}
                  value={6}
                />
                <Tab
                  style={{
                    minWidth: renderListCount("jobOpening") ? 160 : 120,
                  }}
                  label={
                    <>
                      <StyledBadge
                        badgeContent={renderListCount("jobOpening")}
                        color="secondary"
                        max={999}
                      >
                        {`${t("JobOpenings")}`}
                      </StyledBadge>
                    </>
                  }
                  {...a11yProps(7)}
                  value={7}
                /> */}
                <Tab
                  // style={{ minWidth: renderListCount("workforce") ? 200 : 140 }}
                  style={{ minWidth: numberOfPendingList && numberOfPendingList.workforce ? 200 : 140 }}
                  label={
                    <>
                      <StyledBadge
                        // badgeContent={renderListCount("workforce")}
                        badgeContent={numberOfPendingList && numberOfPendingList.workforce}
                        color="secondary"
                        max={999}
                      >
                        {t("TimekeepingRequest")}
                      </StyledBadge>
                    </>
                  }
                  {...a11yProps(11)}
                  value={11}
                />
              </Tabs>
            )}
            <div className="numberOfList">
              <Typography color="error" variant="h6">{`${numberOfList} ${t("List")}`}</Typography>
            </div>
          </StyledDivTab>

          {userProfile && (
            <Box style={{ width: "100%" }}>
              {(
                [0, 1, 8].includes(approveTabsValue) ? (isFetchingRequestTime || isFetchingRequestTimeWithDraw) :
                  approveTabsValue === 2 ? (isFetchingLeaveEmployees || isFetchingLeaveEmployeeWithdraws) :
                    approveTabsValue === 3 ? isFetchingEmployeeChangeShift :
                      approveTabsValue === 4 ? isFetchingEmployeesProbation :
                        (approveTabsValue === 11 && isFetchingProjectGetUserTimeforApprove)
              ) &&
                <Box
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "40px"
                  }}
                >
                  <img width="120" alt="loading" src={loading} />
                </Box>
              }
              {requestTimeList && requestTimeWithDrawList && (
                <TabPanel value={approveTabsValue} index={0}>
                  <RequestTime
                    searchDate={search}
                    setSnackBarConfig={setSnackBarConfig}
                    setNumberOfList={setNumberOfList}
                  />
                </TabPanel>
              )}

              {requestTimeList && requestTimeWithDrawList && payrollSetting && (
                <TabPanel value={approveTabsValue} index={1}>
                  <RequestOverTime
                    searchDate={search}
                    setSnackBarConfig={setSnackBarConfig}
                    setNumberOfList={setNumberOfList}
                  />
                </TabPanel>
              )}

              {leaveEmployeesList && leaveEmployeeWithdrawsList && (
                <TabPanel value={approveTabsValue} index={2}>
                  <RequestLeave
                    searchDate={search}
                    setSnackBarConfig={setSnackBarConfig}
                    setNumberOfList={setNumberOfList}
                  />
                </TabPanel>
              )}

              {employeeChangeShiftList && (
                <TabPanel value={approveTabsValue} index={3}>
                  <RequestTimeShift
                    searchDate={search}
                    setSnackBarConfig={setSnackBarConfig}
                    setNumberOfList={setNumberOfList}
                  />
                </TabPanel>
              )}

              {employeesProbation && (
                <TabPanel value={approveTabsValue} index={9}>
                  <RequestProbation
                    searchDate={search}
                    setSnackBarConfig={setSnackBarConfig}
                    setNumberOfList={setNumberOfList}
                  />
                </TabPanel>
              )}
              {/* {employeesEstimatePerformance && (
                <TabPanel value={approveTabsValue} index={5}>
                  <RequestEstimateYear
                    searchDate={search}
                    setSnackBarConfig={setSnackBarConfig}
                    setNumberOfList={setNumberOfList}
                  />
                </TabPanel>
              )}
              {employeesExitInterview && (
                <TabPanel value={approveTabsValue} index={6}>
                  <RequestListExitInterview
                    searchDate={search}
                    setSnackBarConfig={setSnackBarConfig}
                    setNumberOfList={setNumberOfList}
                  />
                </TabPanel>
              )}
              {jobRecruitManager && (
                <TabPanel value={approveTabsValue} index={7}>
                  <RequestJobs
                    searchDate={search}
                    setSnackBarConfig={setSnackBarConfig}
                    setNumberOfList={setNumberOfList}
                  />
                </TabPanel>
              )} */}

              {requestTimeList && requestTimeWithDrawList && (
                <TabPanel value={approveTabsValue} index={8}>
                  <RequestCompensate
                    searchDate={search}
                    setSnackBarConfig={setSnackBarConfig}
                    setNumberOfList={setNumberOfList}
                  />
                </TabPanel>
              )}
              {userProfile && userProfile.idCompany === 1 && (
                <TabPanel value={approveTabsValue} index={10}>
                  <RequestProject
                    searchDate={search}
                    setSnackBarConfig={setSnackBarConfig}
                    setNumberOfList={setNumberOfList}
                    ProjectData={employeeProject ? employeeProject : null}
                    setProjectData={setEmployeeProject}
                  />
                </TabPanel>
              )}
              {projectGetUserTimeforApprove && (
                <TabPanel value={approveTabsValue} index={11}>
                  <RequestInputHourUserWorkforce
                    searchDate={search}
                    setSnackBarConfig={setSnackBarConfig}
                    setNumberOfList={setNumberOfList}
                  />
                </TabPanel>
              )}
            </Box>
          )}
        </StyledCard>
      </Container>
    </StyledRoot>
  );
}

export default Requestlist;