import dayjs from 'dayjs';
import 'dayjs/locale/th';
import ExcelJS from 'exceljs';
function convertToThaiDate(date) {
    const thaiDay = date.locale('th').format("DD");
    const thaiMonth = date.locale('th').format("MMMM");
    const year = date.format('YYYY');
    const thaiYear = parseInt(year) + 543;
    const thaiDate = `${thaiDay + " " + thaiMonth + " " + thaiYear}`;

    return thaiDate;
}

export const PersonalWorkingTime = (t, data, leaveData, empLeaveData, date) => {
    try {
        const workbook = new ExcelJS.Workbook();
        const worksheet1 = workbook.addWorksheet(t("SummaryLeaveList"));

        const dateRange = [];
        let currentDate = dayjs(date.start);
        let endDate = dayjs(date.end);

        while (currentDate.isSameOrBefore(endDate, 'day')) {
            dateRange.push({ date: currentDate.format("YYYY-MM-DD"), thDate: convertToThaiDate(currentDate) });
            currentDate = currentDate.add(1, 'day');
        }

        const leaveTypeNames = leaveData && leaveData.map(leaveType => leaveType.name);

        const headerRow = worksheet1.addRow([
            t("EmployeeID"),
            t("FirstName"),
            t("LastName"),
            t("Position"),
            t("Company"),
            t("Division"),
            t("Department"),
            t("Section"),
            t("StartDate"),
            t("EndDate"),
            t("TotalWorkingDays"),
            t("PercentageOfWorkingDays"),
            t("TotalWorkingHours"),
            t("TotalWorkingHoursByEmp"),
            t("TotalLeaveHours"),
            t("TotalOvertimeHours"),
            ...dateRange.map((item) => item.thDate),
            t("Absent"),
            t("Late"),
            t("LeaveEarly"),
            ...leaveTypeNames
        ]);

        headerRow.height = 50;

        const headerStyle1 = {
            font: { bold: true, size: 18, name: 'TH SarabunPSK', color: { argb: 'FFFFFF' } },
            alignment: { horizontal: "center", vertical: 'middle' },
            fill: {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '002060' }
            },
        }

        const headerStyleRed = {
            font: { bold: false, size: 18, name: 'TH SarabunPSK', color: { argb: '9F0A10' } },
            alignment: { horizontal: "center", vertical: 'middle' },
            fill: {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFC7CE' }
            },
        }

        const headerStyleGreen = {
            font: { bold: false, size: 18, name: 'TH SarabunPSK', color: { argb: '006100' } },
            alignment: { horizontal: "center", vertical: 'middle' },
            fill: {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'C6EFCE' }
            },
        }

        headerRow.eachCell((cell) => {
            cell.style = headerStyle1;
        });

        const leaveTypeColWidths = [];
        leaveData && leaveData.forEach(leaveType => {
            leaveTypeColWidths.push({
                key: `leaveType_${leaveType.idLeaveType}`,
                width: leaveType.name.length * 2
            });
        });

        const colWidths = [
            { key: "employeeID", width: 30 },
            { key: "firstname", width: 30 },
            { key: "lastname", width: 30 },
            { key: "position", width: 40 },
            { key: "company", width: 40 },
            { key: "division", width: 40 },
            { key: "department", width: 40 },
            { key: "section", width: 40 },
            { key: "start", width: 30 },
            { key: "end", width: 30 },
            { key: "totalWorkingDays", width: 30 },
            { key: "%work", width: 20 },
            { key: "totalWorkingHour", width: 40 },
            { key: "TotalWorkingHoursByEmp", width: 40 },
            { key: "totalLeaveHour", width: 30 },
            { key: "totalOTHour", width: 30 },
            ...dateRange.map(date => ({ key: date, width: 40 })),
            { key: "absent", width: 20 },
            { key: "late", width: 20 },
            { key: "early", width: 20 },
            ...leaveTypeColWidths
        ];

        let summaries = {};

        const groupedData = data && data.reduce((acc, x) => {
            const startDate = dayjs(date.start).format("YYYY-MM-DD");
            const endDate = dayjs(date.end).format("YYYY-MM-DD");

            x.schedule.forEach(item => {
                const key = `${item.employeeID}_${startDate}_${endDate}`;

                let totalAbsent = 0;
                let totalLate = 0;
                let totalEarly = 0;
                let totalWorkingDays = 0;
                let totalWorkingHours = 0;
                let everyWorkingDay = 0;
                let totalLeaveHours = 0;
                let totalHoursOT = 0;
                let totalWorkingHoursByEmp = 0;
                let totalLeaveSpecificHour = 0;

                let string_totalLeaveHours = "";
                let string_totalWorkingHours = "";
                let string_totalHoursOT = "";
                let string_totalWorkingHoursByEmp = "";

                if (!acc[key]) {
                    acc[key] = {
                        employeeID: item.employeeID || "-",
                        firstname_TH: item.firstname_TH || "-",
                        lastname_TH: item.lastname_TH || "-",
                        positionName: item.positionName || "-",
                        companyName: item.companyName || "-",
                        divisionName: item.divisionName || "-",
                        departmentName: item.departmentName || "-",
                        sectionName: item.sectionName || "-",
                        startDate: convertToThaiDate(date.start),
                        endDate: convertToThaiDate(date.end),
                        totalWorkingDay: 0,
                        percentWorkingDays: 0.00,
                        totalWorkingHours: string_totalWorkingHours || "-",
                        totalWorkingHoursByEmp: string_totalWorkingHoursByEmp || "-",
                        totalLeaveHour: string_totalLeaveHours || "-",
                        otHourstotal: string_totalHoursOT || "-",
                        checkTime: [],
                        absentData: 0,
                        lateData: 0,
                        earlyData: 0,
                        Num_Leave: [],
                    };
                }

                dateRange.forEach(dateObj => {
                    let workingStatus = "";

                    const checkTimeEntries = x.schedule.filter(check => check.date === dateObj.date);
                    // const checkLeaveEmployees = empLeaveData && empLeaveData.filter(emp => dayjs(emp.start).format("YYYY-MM-DD") === dateObj.date && emp.idEmployees === item.idEmployees);

                    if (checkTimeEntries && checkTimeEntries.length > 0) {
                        checkTimeEntries.forEach(timeItem => {
                            totalHoursOT += timeItem.otHourstotal;

                            const checkIfEmployeeLeaves = timeItem.employeeLeaveData;

                            if (timeItem.shiftType) {
                                const shiftTimeIn = timeItem.shiftType.formattedTimeIn;
                                const shiftTimeOut = timeItem.shiftType.formattedTimeOut;

                                const currentDate = dayjs(timeItem.date).format("YYYY-MM-DD");

                                if (checkIfEmployeeLeaves && checkIfEmployeeLeaves.idEmployees === timeItem.idEmployees) {
                                    if ((checkIfEmployeeLeaves.date && dayjs(checkIfEmployeeLeaves.date).format("YYYY-MM-DD")) === currentDate) {
                                        totalLeaveHours += (checkIfEmployeeLeaves.used * (timeItem.shiftGroup && timeItem.shiftGroup.workingTimeToday));

                                        if (checkIfEmployeeLeaves.isFullDay === 0) {
                                            totalLeaveSpecificHour += (checkIfEmployeeLeaves.used * (timeItem.shiftGroup && timeItem.shiftGroup.workingTimeToday));
                                        };

                                        const totalHours = Math.floor(totalLeaveHours);
                                        const totalMinutes = Math.round((totalLeaveHours - totalHours) * 60);

                                        string_totalLeaveHours = `${totalHours} ${t("Unit.Hours")} ${totalMinutes} ${t("Unit.Minute")}`;
                                    } else {
                                        string_totalLeaveHours = `0 ${t("Unit.Hours")} 0 ${t("Unit.Minute")}`;
                                    }
                                };


                                if (timeItem.shiftType.isWorkingDay === 1 && timeItem.isHoliday === false) {
                                    everyWorkingDay++;
                                    totalWorkingHours += (timeItem.shiftGroup && timeItem.shiftGroup.workingTimeToday);

                                    if (timeItem.isFinger === 0) { // update condition for 0 time frame in this condition.
                                        if (checkIfEmployeeLeaves) {
                                            workingStatus = checkIfEmployeeLeaves ? `${checkIfEmployeeLeaves.name}` : t("AppMenu.Leave");
                                        } else {
                                            totalWorkingDays++;
                                            workingStatus = t("CompleteTimeEntry");
                                        };
                                    } else {
                                        if (timeItem.checkInAndcheckOutList) {
                                            const timeIn = timeItem.checkInAndcheckOutList.checkIn;
                                            const timeOut = timeItem.checkInAndcheckOutList.checkOut;

                                            if ((timeIn > shiftTimeIn) && timeOut) {
                                                totalWorkingDays++;
                                                const [timeInHour, timeInMinute] = timeIn.split(":").map(Number);
                                                const [shiftTimeInHour, shiftTimeInMinute] = shiftTimeIn.split(":").map(Number);

                                                const timeDiffInMinutes = (timeInHour - shiftTimeInHour) * 60 + (timeInMinute - shiftTimeInMinute);

                                                if (timeDiffInMinutes > timeItem.shiftType.lateIn) {
                                                    totalLate++; // มาสาย
                                                    workingStatus = t("CompleteTimeEntry");
                                                } else {
                                                    workingStatus = t("CompleteTimeEntry");
                                                }
                                            } else if ((timeOut < shiftTimeOut) && timeIn) {
                                                totalWorkingDays++;
                                                totalEarly++; // กลับก่อน
                                                workingStatus = t("CompleteTimeEntry");
                                            } else if (timeIn <= shiftTimeIn && timeOut >= shiftTimeOut) {
                                                //มาตรงเวลา
                                                totalWorkingDays++;
                                                workingStatus = t("CompleteTimeEntry");
                                            } else if (!timeIn || !timeOut) {
                                                if (checkIfEmployeeLeaves) {
                                                    workingStatus = checkIfEmployeeLeaves ? `${checkIfEmployeeLeaves.name}` : "ลางาน";
                                                } else if (timeItem.requestWorkingTime && timeItem.requestWorkingTime.length > 0) {
                                                    totalWorkingDays++;
                                                    workingStatus = t("CompleteTimeEntry");
                                                } else {
                                                    totalAbsent++; // ขาดงาน
                                                    workingStatus = t("IncompleteTimeEntry");
                                                }

                                            }
                                        }
                                    }
                                } else if (timeItem.shiftType.isWorkingDay === 0 || timeItem.isHoliday === true) {
                                    if (timeItem.isFinger === 0) {
                                        workingStatus = t("DayOff");
                                    } else {
                                        if (timeItem.checkInAndcheckOutList) {
                                            const timeIn = timeItem.checkInAndcheckOutList.checkIn;
                                            const timeOut = timeItem.checkInAndcheckOutList.checkOut;

                                            if (timeIn && timeOut) {
                                                workingStatus = t("CompleteTimeEntry");
                                            } else if (!timeIn && !timeOut) {
                                                if (timeItem.requestWorkingTime && timeItem.requestWorkingTime.length > 0) {
                                                    workingStatus = t("CompleteTimeEntry");
                                                } else {
                                                    workingStatus = t("DayOff");
                                                };
                                            } else if (!timeIn || !timeOut) {
                                                // ลืม checkin หรือ checkout
                                                if (timeItem.requestWorkingTime && timeItem.requestWorkingTime.length > 0) {
                                                    workingStatus = t("CompleteTimeEntry");
                                                } else {
                                                    workingStatus = t("IncompleteTimeEntry");
                                                };
                                            };
                                        };
                                    };
                                };
                            };
                        });
                    };

                    acc[key].checkTime.push({
                        date: dateObj.date,
                        workingStatus: workingStatus
                    });
                });

                string_totalWorkingHours = `${totalWorkingHours > 0 ? totalWorkingHours.toFixed(0) : 0} ${t("Unit.Hours")}`;

                let percentageOfWorkingDays = (totalWorkingDays / everyWorkingDay) * 100;

                let othours = Math.floor(totalHoursOT);
                let decimalPart = totalHoursOT - othours;
                let minutes = Math.round(decimalPart * 60);

                string_totalHoursOT = `${othours} ${t("Unit.Hours")} ${minutes} ${t("Unit.Minute")}`;

                function calculateTime(totalHours, percentage) {
                    let hours = totalHours * (percentage / 100);
                    let wholeHours = Math.floor(hours);

                    let wholeLeaveHours = Math.floor(totalLeaveSpecificHour);
                    let minutes = Math.round((hours - wholeHours) * 60);
                    let leaveMinutes = Math.round((totalLeaveSpecificHour - wholeLeaveHours) * 60);

                    if (minutes === 60) {
                        wholeHours += 1;
                        minutes = 0;
                    }
                    if (leaveMinutes === 60) {
                        wholeLeaveHours += 1;
                        leaveMinutes = 0;
                    }

                    let finalHours = wholeHours - wholeLeaveHours;
                    let finalMinutes = minutes - leaveMinutes;

                    if (finalMinutes < 0) {
                        finalHours -= 1;
                        finalMinutes += 60;
                    }

                    return { hours: finalHours, minutes: finalMinutes };
                };

                totalWorkingHoursByEmp = calculateTime((totalWorkingHours > 0 ? totalWorkingHours : 0), (percentageOfWorkingDays > 0 ? percentageOfWorkingDays : 0));
                string_totalWorkingHoursByEmp = `${totalWorkingHoursByEmp.hours} ${t("Unit.Hours")} ${totalWorkingHoursByEmp.minutes > 0 ? totalWorkingHoursByEmp.minutes : ""} ${totalWorkingHoursByEmp.minutes > 0 ? t("Unit.Minute") : ""}`;

                summaries[item.employeeID] = {
                    absent: totalAbsent,
                    late: totalLate,
                    early: totalEarly,
                    percentWorkingDays: percentageOfWorkingDays,
                    workingDays: totalWorkingDays,
                    workingHours: string_totalWorkingHours,
                    workingHoursByEmp: string_totalWorkingHoursByEmp,
                    totalLeaveHours: string_totalLeaveHours,
                    otHourstotal: string_totalHoursOT
                };

                //นับจำนวนการลา แยกออกเป็นแต่ละประเภท
                leaveData && leaveData.forEach((leaveType) => {
                    let totalUsedValue = 0.00;
                    const employeeLeaveData = empLeaveData && empLeaveData.filter(emp => emp.idLeaveType === leaveType.idLeaveType && emp.idEmployees === item.idEmployees);

                    employeeLeaveData && employeeLeaveData.forEach((leave) => {
                        totalUsedValue += parseFloat(leave.used);
                    });

                    acc[key].Num_Leave.push({
                        idLeaveType: leaveType.idLeaveType,
                        num_leave: totalUsedValue
                    });
                });
            });

            return acc;
        }, {});

        Object.values(groupedData).forEach(group => {
            const summary = summaries[group.employeeID];
            const row = [
                group.employeeID,
                group.firstname_TH,
                group.lastname_TH,
                group.positionName,
                group.companyName,
                group.divisionName,
                group.departmentName,
                group.sectionName,
                group.startDate,
                group.endDate,
                summary ? summary.workingDays : "-",
                summary && summary.percentWorkingDays > 0 ? `${summary.percentWorkingDays.toFixed(2)}%` : "-",
                summary ? summary.workingHours : "-",
                summary ? summary.workingHoursByEmp : "-",
                summary ? summary.totalLeaveHours : "-",
                summary ? summary.otHourstotal : "-",
                ...dateRange.map(dateObj => {
                    const checkTimeEntry = group.checkTime.find(check => check.date === dateObj.date);
                    return checkTimeEntry ? checkTimeEntry.workingStatus : "-";
                }),
                summary ? summary.absent : "-",
                summary ? summary.late : "-",
                summary ? summary.early : "-",
                ...leaveData.map(leave => {
                    const checkLeave = group.Num_Leave.find(check => check.idLeaveType === leave.idLeaveType);
                    return checkLeave ? checkLeave.num_leave : 0;
                }),
            ];

            const excelRow = worksheet1.addRow(row);
            const contentStyle = {
                font: { size: 18, name: 'TH SarabunPSK' },
                alignment: { horizontal: "center", vertical: 'middle' },
            };

            colWidths.forEach((col, index) => {
                worksheet1.getColumn(index + 1).width = col.width;
            });

            excelRow.eachCell((cell, cellNumber) => {
                if (cellNumber > 14 && cellNumber <= (dateRange.length + 15)) {
                    if (cell.value == t("CompleteTimeEntry")) {
                        cell.style = headerStyleGreen;
                        cell.border = {
                            top: { style: 'thin', color: { argb: '000000' } },
                            left: { style: 'thin', color: { argb: '000000' } },
                            bottom: { style: 'thin', color: { argb: '000000' } },
                            right: { style: 'thin', color: { argb: '000000' } }
                        };

                    } else if (cell.value == t("IncompleteTimeEntry")) {
                        cell.style = headerStyleRed;
                        cell.border = {
                            top: { style: 'thin', color: { argb: '000000' } },
                            left: { style: 'thin', color: { argb: '000000' } },
                            bottom: { style: 'thin', color: { argb: '000000' } },
                            right: { style: 'thin', color: { argb: '000000' } }
                        };
                    } else {
                        cell.style = contentStyle;
                        cell.border = {
                            top: { style: 'thin', color: { argb: '000000' } },
                            left: { style: 'thin', color: { argb: '000000' } },
                            bottom: { style: 'thin', color: { argb: '000000' } },
                            right: { style: 'thin', color: { argb: '000000' } }
                        };
                    }
                } else {
                    cell.style = contentStyle;
                    cell.border = {
                        top: { style: 'thin', color: { argb: '000000' } },
                        left: { style: 'thin', color: { argb: '000000' } },
                        bottom: { style: 'thin', color: { argb: '000000' } },
                        right: { style: 'thin', color: { argb: '000000' } }
                    };
                }
            });
        });

        workbook.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = `${t("WorkingTimeReport")}.xlsx`;
            a.click();
            URL.revokeObjectURL(url);
        });
    } catch (error) {
        console.error(error);
    };
};