import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  Container,
  Typography,
  styled,
  Card,
  Button,
  Grid,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getOrganizationObjectives } from "../../../../../../actions/okrs";
import { getAffiliateOrganization } from "../../../../../../actions/company";
import Loading from "../../../../shared/loading";
import { Search } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

const StyledRoot = styled(Box)({
  "& .objective-card": {
    padding: "16px",
  },
  "& .MuiFilledInput-root input": {
    padding: "16px 12px 16px 12px",
  },
});

const StyledCard = styled(Card)({
  minHeight: "70px",
  boxShadow: "none",
  transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  borderRadius: 20,
  "& .cardTitle": {
    fontSize: 22,
  },
  "& .MuiCardContent-root": {
    padding: 24,
  },
  "& .cardHeader": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
  },
  "& .searchContainer": {
    marginLeft: "41px",
    marginBottom: "100px",
  },
  "@media (max-width: 600px)": {
    "& .searchContainer": {
      marginBottom: "10px",
    },
  },
});

const StyleHeadBox = styled(Box)({
  "& .box-header": {
    minHeight: "200px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    background:
      "linear-gradient(to right, rgba(235, 148, 192, 0.5), rgba(188, 144, 213, 0.5))",
    "& .text-header": {
      fontSize: "48px",
      fontWeight: "bold",
      marginLeft: "40px",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      marginTop: "80px",
    },
    "& .img-header": {
      width: "33%",
      height: "auto",
      marginBottom: "10px",
      justifyContent: "flex-end",
      alignItems: "flex-end",
      marginRight: "20px",
    },
  },

  "@media (max-width: 600px)": {
    "& .box-header": {
      flexDirection: "column",
      minHeight: "unset",
      justifyContent: "center",
      alignItems: "center",
      "& .text-header": {
        fontSize: "30px",
        marginLeft: "20px",
        marginTop: "20px",
      },
    },
  },
});

const CustomButton = styled(Button)({
  color: "#DB4178",
  border: "2px solid #DB4178",
  backgroundColor: "transparent",
  borderRadius: "7px",
  height: "50px",
  "&:hover": {
    color: "#DB4178",
    border: "2px solid #C53B6C",
    backgroundColor: "transparent",
    borderRadius: "7px",
    height: "50px",
  },
});

const ObjectiveCard = (props) => {
  const { data } = props;
  const { t } = useTranslation();

  return (
    <Card
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "20px",
        boxShadow: "0 3px 6px rgba(0,0,0,0.1)",
        borderRadius: "10px",
        height: "100%",
      }}
    >
      <Box
        style={{
          flexGrow: 1,
        }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <Typography variant="h5" style={{ fontWeight: "bold" }}>
            {data.objectiveName}
          </Typography>
        </Box>
        <Typography fontSize="14px" paddingTop="16px">
          <span style={{ color: "#919eab", paddingRight: "8px" }}>
            {`${t("TheResultingOutcome")}`}
          </span>
          {data.impactName}
        </Typography>
        <Typography fontSize="14px" paddingTop="16px">
          <span style={{ color: "#919eab", paddingRight: "8px" }}>
            {`${t("TheMainResult")}`}
          </span>
          {data.keyResults.length} {`${t("List")}`}
        </Typography>
        <Typography fontSize="14px" paddingTop="16px">
          <span style={{ color: "#919eab", paddingRight: "8px" }}>
            {`${t("Company")}`}
          </span>
          {data.companyName}
        </Typography>
        <Typography fontSize="14px" paddingTop="16px" textAlign="right">
          <span style={{ color: "#919eab", paddingRight: "8px" }}>
            {`${t("Year")}`}
          </span>
          {data.year}, Q{data.quarter}
        </Typography>
      </Box>

      <CustomButton
        startIcon={<Search />}
        component={Link}
        to={`/okrs/organization-progress/objective/${data.idOrganizationObjective}`}
        style={{ marginTop: "auto" }}
      >
        {`${t("Descriptions")}`}
      </CustomButton>
    </Card>
  );
};

const UpdateProgressOrganizationPage = (props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const { result: organizationObjective, isFetching } = useSelector(
    (state) => state.organizationObjective
  );
  const { result: affiliateOrganizationList } = useSelector(
    (state) => state.affiliateOrganization
  );

  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectYear, setSelectYear] = useState(dayjs().get("year"));

  const handleChangeYear = (event) => {
    setSelectYear(event.target.value);
  };

  const handleChangeCompany = (event) => {
    setSelectedCompany(event.target.value);
  };

  useEffect(() => {
    dispatch(getOrganizationObjectives());
    dispatch(getAffiliateOrganization());
  }, [dispatch]);

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg" sx={{ paddingTop: "40px" }}>
        <StyledCard style={{ marginBottom: "40px" }}>
          <StyleHeadBox>
            <Box className="box-header">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography className="text-header">{`${t(
                    "UpdateOrganizationalGoals"
                  )}`}</Typography>
                </Grid>

                <Grid item xs={12} className="searchContainer">
                  <Box display="flex" alignItems="center" gap="16px">
                    <FormControl style={{ width: "300px" }}>
                      <InputLabel>{t("SelectCompany")}</InputLabel>
                      <Select
                        label={t("SelectCompany")}
                        value={selectedCompany}
                        onChange={handleChangeCompany}
                        sx={{
                          borderRadius: "10px",
                          backgroundColor: "#ffffff",
                          width: "100%",
                        }}
                      >
                        <MenuItem value="">
                          <em>{t("AllCompanies")}</em>
                        </MenuItem>
                        {affiliateOrganizationList &&
                          affiliateOrganizationList.map((company) => (
                            <MenuItem
                              key={company.idCompany}
                              value={company.idCompany}
                            >
                              {company.companyName}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>

                    <FormControl style={{ width: "160px" }}>
                      <InputLabel>{t("SelectYear")}</InputLabel>
                      <Select
                        label={t("SelectYear")}
                        value={selectYear}
                        onChange={handleChangeYear}
                        sx={{
                          borderRadius: "10px",
                          backgroundColor: "#ffffff",
                          width: "100%",
                        }}
                      >
                        {[0, 1, 2].map((item) => (
                          <MenuItem
                            key={item}
                            value={dayjs().get("year") - item}
                          >
                            {dayjs()
                              .subtract(item, "year")
                              .format(
                                i18n.resolvedLanguage === "th" ? "BBBB" : "YYYY"
                              )}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
              </Grid>
              <img
                src={`${process.env.PUBLIC_URL}/assets/okrs/admin/update-organizational-goals.png`}
                alt="icon"
                style={{ objectFit: "contain" }}
              />
            </Box>
          </StyleHeadBox>
        </StyledCard>

        {isFetching ? (
          <Fragment>
            <Box display="flex" justifyContent="center" paddingTop="24px">
              <Loading />
            </Box>
          </Fragment>
        ) : organizationObjective && organizationObjective.length > 0 ? (
          <Box
            display="grid"
            gridTemplateColumns="repeat(3, 1fr)"
            gap={3}
            marginTop={4}
          >
            {organizationObjective
              .filter((item) => item.year === selectYear)
              .filter(
                (item) =>
                  selectedCompany === "" ||
                  item.idCompany === Number(selectedCompany)
              )
              .map((objective, index) => (
                <ObjectiveCard data={objective} key={index} />
              ))}
          </Box>
        ) : (
          <Box display="flex" justifyContent="center" paddingTop="24px">
            <Typography>{`${t("NoItems")}`}</Typography>
          </Box>
        )}
      </Container>
    </StyledRoot>
  );
};

export default UpdateProgressOrganizationPage;
