import {
  EMPLOYEEPROFILE_FETCHING,
  EMPLOYEEPROFILE_FAILED,
  EMPLOYEEPROFILE_SUCCESS,
  EMPLOYEE_FETCHING,
  EMPLOYEE_FAILED,
  EMPLOYEE_SUCCESS,
  LEAVEEMPLOYEES_FETCHING,
  LEAVEEMPLOYEES_SUCCESS,
  LEAVEEMPLOYEES_FAILED,
  LEAVEEMPLOYEES_WITHDRAW_FETCHING,
  LEAVEEMPLOYEES_WITHDRAW_SUCCESS,
  LEAVEEMPLOYEES_WITHDRAW_FAILED,
  LEAVE_TYPE_FETCHING,
  LEAVE_TYPE_SUCCESS,
  LEAVE_TYPE_FAILED,
  EMPLOYEE_ADDITION_DEDUCTION_FETCHING,
  EMPLOYEE_ADDITION_DEDUCTION_FAILED,
  EMPLOYEE_ADDITION_DEDUCTION_SUCCESS,
  EMPLOYEE_NOT_SHIFT_FETCHING,
  EMPLOYEE_NOT_SHIFT_SUCCESS,
  EMPLOYEE_NOT_SHIFT_FAILED,
  EMPLOYEE_SHIFT_FETCHING,
  EMPLOYEE_SHIFT_SUCCESS,
  EMPLOYEE_SHIFT_FAILED,
  EMPLOYEES_SHIFTHISTORY_FETCHING,
  EMPLOYEES_SHIFTHISTORY_SUCCESS,
  EMPLOYEES_SHIFTHISTORY_FAILED,
  EMPLOYEE_CHANGESHIFT_FETCHING,
  EMPLOYEE_CHANGESHIFT_SUCCESS,
  EMPLOYEE_CHANGESHIFT_FAILED,
  EMPLOYEE_CHANGEPOSITION_FETCHING,
  EMPLOYEE_CHANGEPOSITION_SUCCESS,
  EMPLOYEE_CHANGEPOSITION_FAILED,
  EMPLOYEE_CHANGE_FETCHING,
  EMPLOYEE_CHANGE_SUCCESS,
  EMPLOYEE_CHANGE_FAILED,
  PROBATION_EMPLOYEES_FETCHING,
  PROBATION_EMPLOYEES_SUCCESS,
  PROBATION_EMPLOYEES_FAILED,
  PROBATION_EMPLOYEES_STATUS_FETCHING,
  PROBATION_EMPLOYEES_STATUS_SUCCESS,
  PROBATION_EMPLOYEES_STATUS_FAILED,
  EMPLOYEES_ROLE_ONE_TWO_FETCHING,
  EMPLOYEES_ROLE_ONE_TWO_SUCCESS,
  EMPLOYEES_ROLE_ONE_TWO_FAILED,
  ESTIMATE_PERFORMANCE_EMPLOYEES_FETCHING,
  ESTIMATE_PERFORMANCE_EMPLOYEES_SUCCESS,
  ESTIMATE_PERFORMANCE_EMPLOYEES_FAILED,
  PROBATION_EMPLOYEES_ID_FETCHING,
  PROBATION_EMPLOYEES_ID_SUCCESS,
  PROBATION_EMPLOYEES_ID_FAILED,
  EXIT_INTERVIEW_EMPLOYEES_SUCCESS,
  EXIT_INTERVIEW_EMPLOYEES_FAILED,
  EXIT_INTERVIEW_EMPLOYEES_FETCHING,
  USERS_FETCHING,
  USERS_FAILED,
  USERS_SUCCESS,
  USERS_VENDOR_FREE_FETCHING,
  USERS_VENDOR_FREE_FAILED,
  USERS_VENDOR_FREE_SUCCESS,
  ESTIMATE_PERFORMANCE_EMPLOYEES_STATUS_SUCCESS,
  ESTIMATE_PERFORMANCE_EMPLOYEES_STATUS_FAILED,
  EXIT_INTERVIEW_EMPLOYEES_STATUS_SUCCESS,
  EXIT_INTERVIEW_EMPLOYEES_STATUS_FAILED,
  CONTRACT_SUCCESS,
  CONTRACT_FAILED,
  EMPLOYEE_SEARCH_FETCHING,
  EMPLOYEE_SEARCH_FAILED,
  EMPLOYEE_SEARCH_SUCCESS,
  EMPLOYEE_SHIFT_CHANGE_HISTORY_FETCHING,
  EMPLOYEE_SHIFT_CHANGE_HISTORY_SUCCESS,
  EMPLOYEE_SHIFT_CHANGE_HISTORY_FAILED,
} from "./types";

import EmployeeService from "../services/employee.service";

export const addEmployeeContractByIdEmp = async (formData) => {
  try {
    const res = await EmployeeService.addEmployeeContractByIdEmp(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const getEmployeeProfile = (idEmployees, filter) => async (dispatch) => {
  try {
    dispatch({
      type: EMPLOYEEPROFILE_FETCHING,
    });
    const res = await EmployeeService.getEmployeeProfile(idEmployees, filter);
    if (res) {
      if (filter) {
        dispatch({
          type: EMPLOYEEPROFILE_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: EMPLOYEEPROFILE_SUCCESS,
          payload: res.data,
        });
      }
    }
  } catch (err) {
    if (filter) {
      dispatch({
        type: EMPLOYEEPROFILE_FAILED,
      });
    } else {
      dispatch({
        type: EMPLOYEEPROFILE_FAILED,
      });
    }

    console.log(err);
  }
};

export const getAllEmployees = (mode, search) => async (dispatch) => {
  try {
    dispatch({
      type: EMPLOYEE_FETCHING,
    });
    const res = await EmployeeService.getAllEmployees(mode, search);
    if (res) {
      dispatch({
        type: EMPLOYEE_SUCCESS,
        payload: res.data,
      });
    }
    return res;
  } catch (err) {
    dispatch({
      type: EMPLOYEE_FAILED,
    });
    // console.log(err);
    return err.response;
  }
};

export const getAllEmployeesByIdCompany = (id) => async (dispatch) => {
  try {
    const res = await EmployeeService.getAllEmployeesByIdCompany(id);
    if (res) {
      dispatch({
        type: EMPLOYEE_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: EMPLOYEE_FAILED,
    });
    console.log(err);
  }
};

export const getAllEmployeesAllRoles = () => async (dispatch) => {
  try {
    const res = await EmployeeService.getAllEmployeesAllRoles();
    if (res) {
      dispatch({
        type: EMPLOYEE_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: EMPLOYEE_FAILED,
    });
    console.log(err);
  }
};

export const getLeaveRequest =
  (filter, search, idCompany) => async (dispatch) => {
    try {
      dispatch({
        type: LEAVEEMPLOYEES_FETCHING,
      });
      const res = await EmployeeService.getLeaveRequest(
        filter,
        search,
        idCompany
      );
      if (res) {
        dispatch({
          type: LEAVEEMPLOYEES_SUCCESS,
          payload: res.data,
        });
      }
    } catch (err) {
      dispatch({
        type: LEAVEEMPLOYEES_FAILED,
      });
      console.log(err);
    }
  };

export const getAllLeaveRequest = (query) => async (dispatch) => {
  try {
    dispatch({
      type: LEAVEEMPLOYEES_FETCHING,
    });
    const res = await EmployeeService.getAllLeaveRequest(query);
    if (res) {
      dispatch({
        type: LEAVEEMPLOYEES_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: LEAVEEMPLOYEES_FAILED,
    });
    console.log(err);
  }
};

export const getEmployeeChangeTime =
  (filter, search, idCompany) => async (dispatch) => {
    try {
      dispatch({
        type: EMPLOYEE_CHANGESHIFT_FETCHING,
      });
      const res = await EmployeeService.getEmployeeChangeTime(
        filter,
        search,
        idCompany
      );
      if (res) {
        dispatch({
          type: EMPLOYEE_CHANGESHIFT_SUCCESS,
          payload: res.data,
        });
      }
    } catch (err) {
      dispatch({
        type: EMPLOYEE_CHANGESHIFT_FAILED,
      });
      console.log(err);
    }
  };

export const getAllEmployeeChangeTime = (query) => async (dispatch) => {
  try {
    dispatch({
      type: EMPLOYEE_CHANGESHIFT_FETCHING,
    });
    const res = await EmployeeService.getAllEmployeeChangeTime(query);
    if (res) {
      dispatch({
        type: EMPLOYEE_CHANGESHIFT_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: EMPLOYEE_CHANGESHIFT_FAILED,
    });
    console.log(err);
  }
};

export const getLeaveRequestAllYear = (search) => async (dispatch) => {
  try {
    dispatch({
      type: LEAVEEMPLOYEES_FETCHING,
    });
    const res = await EmployeeService.getLeaveRequestAllYear(search);
    if (res) {
      dispatch({
        type: LEAVEEMPLOYEES_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: LEAVEEMPLOYEES_FAILED,
    });
    console.log(err);
  }
};

export const getLeaveWithdrawEmployeeYear = (search) => async (dispatch) => {
  try {
    dispatch({
      type: LEAVEEMPLOYEES_WITHDRAW_FETCHING,
    });
    const res = await EmployeeService.getLeaveWithdrawEmployeeYear(search);
    if (res) {
      dispatch({
        type: LEAVEEMPLOYEES_WITHDRAW_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: LEAVEEMPLOYEES_WITHDRAW_FAILED,
    });
    console.log(err);
  }
};

export const getLeaveRequestOnce = (query) => async (dispatch) => {
  try {
    dispatch({
      type: LEAVEEMPLOYEES_FETCHING,
    });
    const res = await EmployeeService.getLeaveRequestOnce(query);
    if (res) {
      dispatch({
        type: LEAVEEMPLOYEES_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: LEAVEEMPLOYEES_FAILED,
    });
    console.log(err);
  }
};

export const getLeaveQuota = () => async (dispatch) => {
  try {
    dispatch({
      type: LEAVE_TYPE_FETCHING,
    });
    const res = await EmployeeService.getLeaveQuota();
    if (res) {
      dispatch({
        type: LEAVE_TYPE_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: LEAVE_TYPE_FAILED,
    });
    console.log(err);
    return err;
  }
};

export const getEmployeeLeaveQuotaByIdEmp =
  (idEmployees) => async (dispatch) => {
    try {
      dispatch({
        type: LEAVE_TYPE_FETCHING,
      });
      const res = await EmployeeService.getEmployeeLeaveQuotaByIdEmp(
        idEmployees
      );
      if (res) {
        dispatch({
          type: LEAVE_TYPE_SUCCESS,
          payload: res.data,
        });
      }
    } catch (err) {
      dispatch({
        type: LEAVE_TYPE_FAILED,
      });
      console.log(err);
      return err;
    }
  };

export const getEmployeesSearchByCompany = (filter) => async (dispatch) => {
  try {
    const res = await EmployeeService.getEmployeesSearchByCompany(filter);
    if (res) {
      dispatch({
        type: EMPLOYEE_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: EMPLOYEE_FAILED,
    });
    console.log(err);
  }
};

export const getEmployeeAdditionDeduction = (idEmp) => async (dispatch) => {
  try {
    dispatch({
      type: EMPLOYEE_ADDITION_DEDUCTION_FETCHING,
    });
    const res = await EmployeeService.getEmployeeAdditionDeduction(idEmp);
    if (res) {
      dispatch({
        type: EMPLOYEE_ADDITION_DEDUCTION_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: EMPLOYEE_ADDITION_DEDUCTION_FAILED,
    });
  }
};

export const updateEmployeeAdditionDeduction =
  (formData) => async (dispatch) => {
    try {
      const res = await EmployeeService.updateEmployeeAdditionDeduction(
        formData
      );
      if (res) {
        return res;
      }
    } catch (error) {
      return error;
    }
  };

export const employeeChangeShift = (data) => async () => {
  try {
    const res = await EmployeeService.employeeChangeShift(data);
    if (res) {
      console.log(res);
      return res;
    }
  } catch (error) {
    return error;
  }
};

export const getEmployeeEmployeeTypeChange =
  (idEmployees) => async (dispatch) => {
    try {
      dispatch({
        type: EMPLOYEE_CHANGE_FETCHING,
      });
      const res = await EmployeeService.getEmployeeEmployeeTypeChange(
        idEmployees
      );
      if (res) {
        dispatch({
          type: EMPLOYEE_CHANGE_SUCCESS,
          payload: res.data,
        });
      }
    } catch (error) {
      dispatch({
        type: EMPLOYEE_CHANGE_FAILED,
      });
    }
  };

export const updateEmployeeEmployeeTypeChange =
  (idEmployees, idEmployeeEmployeeType, formData) => async () => {
    try {
      const res = await EmployeeService.updateEmployeeEmployeeTypeChange(
        idEmployees,
        idEmployeeEmployeeType,
        formData
      );
      if (res) {
        return res;
      }
    } catch (error) {
      if (error.response) {
        return error.response;
      }
    }
  };

export const updateEmployeeEmploymentType = (dataForm) => async () => {
  try {
    const res = await EmployeeService.updateEmployeeEmploymentType(dataForm);
    if (res) {
      console.log(res);
      return res;
    }
  } catch (error) {
    return error;
  }
};

export const addDeleteEmployeeEmploymentType = (dataForm) => async () => {
  try {
    const res = await EmployeeService.addDeleteEmployeeEmploymentType(dataForm);
    if (res) {
      console.log(res);
      return res;
    }
  } catch (error) {
    return error;
  }
};

export const getEmployeePersonnelLevelChange =
  (idEmployees) => async (dispatch) => {
    try {
      dispatch({
        type: EMPLOYEE_CHANGE_FETCHING,
      });
      const res = await EmployeeService.getEmployeePersonnelLevelChange(
        idEmployees
      );
      if (res) {
        dispatch({
          type: EMPLOYEE_CHANGE_SUCCESS,
          payload: res.data,
        });
      }
    } catch (error) {
      dispatch({
        type: EMPLOYEE_CHANGE_FAILED,
      });
    }
  };

export const updateEmployeePersonnelLevelChange =
  (idEmployees, idEmployeePersonnelLevel, formData) => async () => {
    try {
      const res = await EmployeeService.updateEmployeePersonnelLevelChange(
        idEmployees,
        idEmployeePersonnelLevel,
        formData
      );
      if (res) {
        return res;
      }
    } catch (error) {
      if (error.response) {
        return error.response;
      }
    }
  };

export const addDeleteEmployeePersonnelLevel = (dataForm) => async () => {
  try {
    const res = await EmployeeService.addDeleteEmployeePersonnelLevel(dataForm);
    if (res) {
      console.log(res);
      return res;
    }
  } catch (error) {
    return error;
  }
};

export const updateEmployeePersonnelLevel = (dataForm) => async () => {
  try {
    const res = await EmployeeService.updateEmployeePersonnelLevel(dataForm);
    if (res) {
      console.log(res);
      return res;
    }
  } catch (error) {
    return error;
  }
};

export const getEmployeePositionChange = (idEmployees) => async (dispatch) => {
  try {
    dispatch({
      type: EMPLOYEE_CHANGE_FETCHING,
    });
    const res = await EmployeeService.getEmployeePositionChange(idEmployees);
    if (res) {
      dispatch({
        type: EMPLOYEE_CHANGE_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: EMPLOYEE_CHANGE_FAILED,
    });
  }
};

export const updateEmployeePositionChange =
  (idEmployees, idEmployeePosition, formData) => async () => {
    try {
      const res = await EmployeeService.updateEmployeePositionChange(
        idEmployees,
        idEmployeePosition,
        formData
      );
      if (res) {
        return res;
      }
    } catch (error) {
      if (error.response) {
        return error.response;
      }
    }
  };

export const updateEmployeePosition = (dataForm) => async () => {
  try {
    const res = await EmployeeService.updateEmployeePosition(dataForm);
    if (res) {
      console.log(res);
      return res;
    }
  } catch (error) {
    return error;
  }
};

export const addDeleteEmployeePosition = (dataForm) => async () => {
  try {
    const res = await EmployeeService.addDeleteEmployeePosition(dataForm);
    if (res) {
      console.log(res);
      return res;
    }
  } catch (error) {
    return error;
  }
};

export const getAllEmployeesNotInShift = (idShiftGroup) => async (dispatch) => {
  try {
    dispatch({
      type: EMPLOYEE_NOT_SHIFT_FETCHING,
    });
    const res = await EmployeeService.getAllEmployeesNotInShift(idShiftGroup);
    if (res) {
      dispatch({
        type: EMPLOYEE_NOT_SHIFT_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: EMPLOYEE_NOT_SHIFT_FAILED,
    });
  }
};

export const getAllEmployeesShift =
  (idShiftGroup, searchDate) => async (dispatch) => {
    try {
      dispatch({
        type: EMPLOYEE_SHIFT_FETCHING,
      });
      const res = await EmployeeService.getAllEmployeesShift(
        idShiftGroup,
        searchDate
      );
      if (res) {
        dispatch({
          type: EMPLOYEE_SHIFT_SUCCESS,
          payload: res.data,
        });
      }
    } catch (error) {
      dispatch({
        type: EMPLOYEE_SHIFT_FAILED,
      });
    }
  };

export const getEmployeeShiftHistory = (idShiftGroup) => async (dispatch) => {
  try {
    dispatch({
      type: EMPLOYEES_SHIFTHISTORY_FETCHING,
    });
    const res = await EmployeeService.getEmployeeShiftHistory(idShiftGroup);
    if (res) {
      dispatch({
        type: EMPLOYEES_SHIFTHISTORY_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: EMPLOYEES_SHIFTHISTORY_FAILED,
    });
  }
};

export const getAllEmployeesByShiftGroup =
  (idShiftGroup) => async (dispatch) => {
    try {
      dispatch({
        type: EMPLOYEE_SHIFT_FETCHING,
      });
      const res = await EmployeeService.getAllEmployeesByShiftGroup(
        idShiftGroup
      );
      if (res) {
        dispatch({
          type: EMPLOYEE_SHIFT_SUCCESS,
          payload: res.data,
        });
      }
    } catch (error) {
      dispatch({
        type: EMPLOYEE_SHIFT_FAILED,
      });
    }
  };

export const updateEmployeeShiftHistory = (data) => async () => {
  try {
    const res = await EmployeeService.updateEmployeeShiftHistory(data);
    if (res) {
      return res;
    }
  } catch (error) {
    return error;
  }
};

export const employeeChangeTime = (data) => async () => {
  try {
    const res = await EmployeeService.employeeChangeTime(data);
    if (res) {
      console.log(res);
      return res;
    }
  } catch (error) {
    return error;
  }
};

export const employeeChangeTimeApprove = (data) => async () => {
  try {
    const res = await EmployeeService.employeeChangeTimeApprove(data);
    if (res) {
      console.log(res);
      return res;
    }
  } catch (error) {
    return error;
  }
};

export const employeeChangeTimeWithdraw = (data) => async () => {
  try {
    const res = await EmployeeService.employeeChangeTimeWithdraw(data);
    if (res) {
      console.log(res);
      return res;
    }
  } catch (error) {
    return error;
  }
};

export const addProfileImage = (formData) => async () => {
  try {
    const res = await EmployeeService.addProfileImage(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    return error;
  }
};

export const addProfileImageOfEmployees = (formData) => async () => {
  try {
    const res = await EmployeeService.addProfileImageOfEmployees(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    return error;
  }
};

export const getEmployeeDataWritePDF =
  (type, year, idDepartment, idEmployee) => async () => {
    try {
      const res = await EmployeeService.getEmployeeDataWritePDF(
        type,
        year,
        idDepartment,
        idEmployee
      );
      if (res) {
        return res;
      }
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.name) ||
        error.name ||
        error.toString();
      return "Error";
    }
  };

export const getTimeschedule = (start) => async () => {
  try {
    const res = await EmployeeService.getTimeschedule(start);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const getTimescheduleByEmployees =
  (filter, idEmployees) => async (dispatch) => {
    try {
      dispatch({
        type: EMPLOYEE_SHIFT_FETCHING,
      });
      const res = await EmployeeService.getTimescheduleByEmployees(
        filter,
        idEmployees
      );
      if (res) {
        dispatch({
          type: EMPLOYEE_SHIFT_SUCCESS,
          payload: res.data,
        });
      }
    } catch (error) {
      dispatch({
        type: EMPLOYEE_SHIFT_FAILED,
      });
      return "Error";
    }
  };

export const uploadExcelTimeAttendance = (formData) => async () => {
  try {
    const res = await EmployeeService.uploadExcelTimeAttendance(formData);
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error.response);
    if (error.response) {
      return error.response.data;
    }
  }
};

export const uploadExcelEmployees = (formData) => async () => {
  try {
    const res = await EmployeeService.uploadExcelEmployees(formData);
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error.response);
    if (error.response) {
      return error.response.data;
    }
  }
};

export const uploadExcelEmployeeFreelance = (formData) => async () => {
  try {
    const res = await EmployeeService.uploadExcelEmployeeFreelance(formData);
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error.response);
    if (error.response) {
      return error.response.data;
    }
  }
};

export const uploadExcelManager2UserManager = (formData) => async () => {
  try {
    const res = await EmployeeService.uploadExcelManager2UserManager(formData);
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error.response);
    if (error.response) {
      return error.response.data;
    }
  }
};

export const getEmployeeRegistrationMetadata = (query) => async () => {
  try {
    const res = await EmployeeService.getEmployeeRegistrationMetadata(query);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const addEmployees = (formData) => async () => {
  try {
    const res = await EmployeeService.addEmployees(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const getEmployeeSalaryChange = (idEmployees) => async (dispatch) => {
  try {
    dispatch({
      type: EMPLOYEE_CHANGE_FETCHING,
    });
    const res = await EmployeeService.getEmployeeSalaryChange(idEmployees);
    if (res) {
      dispatch({
        type: EMPLOYEE_CHANGE_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: EMPLOYEE_CHANGE_FAILED,
    });
  }
};

export const updateEmployeeSalaryChange =
  (idEmployees, idEmployeeSalary, formData) => async () => {
    try {
      const res = await EmployeeService.updateEmployeeSalaryChange(
        idEmployees,
        idEmployeeSalary,
        formData
      );
      if (res) {
        return res;
      }
    } catch (error) {
      if (error.response) {
        return error.response;
      }
    }
  };

export const updateEmployeeSalary = (idEmployees, formData) => async () => {
  try {
    const res = await EmployeeService.updateEmployeeSalary(
      idEmployees,
      formData
    );
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const addDeleteEmployeeSalary = (dataForm) => async () => {
  try {
    const res = await EmployeeService.addDeleteEmployeeSalary(dataForm);
    if (res) {
      console.log(res);
      return res;
    }
  } catch (error) {
    return error;
  }
};

export const getEmployeeExcelExport = (query) => async () => {
  try {
    const res = await EmployeeService.getEmployeeExcelExport(query);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const getEmployeeExcelDiligenceAllowanceExport = (query) => async () => {
  try {
    const res = await EmployeeService.getEmployeeExcelDiligenceAllowanceExport(
      query
    );
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const deleteEmployeeAddition =
  (idEmp, idEmployeeAddition) => async (dispatch) => {
    try {
      const res = await EmployeeService.deleteEmployeeAddition(
        idEmp,
        idEmployeeAddition
      );
      if (res) {
        return res;
      }
    } catch (error) {
      return error;
    }
  };

export const deleteEmployeeDeduction =
  (idEmp, idEmployeeDeduction) => async (dispatch) => {
    try {
      const res = await EmployeeService.deleteEmployeeDeduction(
        idEmp,
        idEmployeeDeduction
      );
      if (res) {
        return res;
      }
    } catch (error) {
      return error;
    }
  };

export const getProbationEmployees = () => async (dispatch) => {
  try {
    const res = await EmployeeService.getProbationEmployees();
    if (res) {
      dispatch({
        type: PROBATION_EMPLOYEES_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: PROBATION_EMPLOYEES_FAILED,
    });
    console.log(err);
  }
};

export const getProbationEmployeesStatus = (query) => async (dispatch) => {
  try {
    dispatch({ type: PROBATION_EMPLOYEES_STATUS_FETCHING });
    const res = await EmployeeService.getProbationEmployeesStatus(query);
    if (res) {
      dispatch({
        type: PROBATION_EMPLOYEES_STATUS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: PROBATION_EMPLOYEES_STATUS_FAILED,
    });
  }
};

export const getEmployeesRoleOneTwo = (query) => async (dispatch) => {
  try {
    const res = await EmployeeService.getEmployeesRoleOneTwo(query);
    if (res) {
      dispatch({
        type: EMPLOYEES_ROLE_ONE_TWO_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: EMPLOYEES_ROLE_ONE_TWO_FAILED,
    });
    console.log(err);
  }
};

export const getEstimatePerformanceEmployees = () => async (dispatch) => {
  try {
    const res = await EmployeeService.getEstimatePerformanceEmployees();
    if (res) {
      dispatch({
        type: ESTIMATE_PERFORMANCE_EMPLOYEES_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: ESTIMATE_PERFORMANCE_EMPLOYEES_FAILED,
    });
    console.log(err);
  }
};

export const getProbationEmployeesByID = (idEmployees) => async (dispatch) => {
  try {
    dispatch({
      type: PROBATION_EMPLOYEES_ID_FETCHING,
    });
    const res = await EmployeeService.getProbationEmployeesByID(idEmployees);
    if (res) {
      dispatch({
        type: PROBATION_EMPLOYEES_ID_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: PROBATION_EMPLOYEES_ID_FAILED,
    });
  }
};

export const getExitInterviewEmployees = (query) => async (dispatch) => {
  try {
    dispatch({
      type: EXIT_INTERVIEW_EMPLOYEES_FETCHING,
    });
    const res = await EmployeeService.getExitInterviewEmployees(query);
    if (res) {
      dispatch({
        type: EXIT_INTERVIEW_EMPLOYEES_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: EXIT_INTERVIEW_EMPLOYEES_FAILED,
    });
  }
};

export const employeeExitInterviewApprove = (data) => async () => {
  try {
    const res = await EmployeeService.employeeExitInterviewApprove(data);
    if (res) {
      console.log(res);
      return res;
    }
  } catch (error) {
    return error;
  }
};

export const getAllFreeUsersByIdVendor = () => async (dispatch) => {
  try {
    dispatch({
      type: USERS_VENDOR_FREE_FETCHING,
    });
    const res = await EmployeeService.getAllFreeUsersByIdVendor();
    if (res) {
      dispatch({
        type: USERS_VENDOR_FREE_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: USERS_VENDOR_FREE_FAILED,
    });
  }
};

export const getAllUsersByIdVendor = () => async (dispatch) => {
  try {
    dispatch({
      type: USERS_FETCHING,
    });
    const res = await EmployeeService.getAllUsersByIdVendor();
    if (res) {
      dispatch({
        type: USERS_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (error) {
    dispatch({
      type: USERS_FAILED,
    });
    return error.response;
  }
};

export const updateVendorManage = (formData) => async () => {
  try {
    const res = await EmployeeService.updateVendorManage(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const getEstimatePerformanceEmployeesStatus = () => async (dispatch) => {
  try {
    const res = await EmployeeService.getEstimatePerformanceEmployeesStatus();
    if (res) {
      dispatch({
        type: ESTIMATE_PERFORMANCE_EMPLOYEES_STATUS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: ESTIMATE_PERFORMANCE_EMPLOYEES_STATUS_FAILED,
    });
    console.log(err);
  }
};

export const getExitInterviewEmployeesStatus = (query) => async (dispatch) => {
  try {
    const res = await EmployeeService.getExitInterviewEmployeesStatus(query);
    if (res) {
      dispatch({
        type: EXIT_INTERVIEW_EMPLOYEES_STATUS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: EXIT_INTERVIEW_EMPLOYEES_STATUS_FAILED,
    });
    console.log(err);
  }
};

export const getEmployeeShiftChange = (idEmployees) => async (dispatch) => {
  try {
    dispatch({
      type: EMPLOYEE_CHANGE_FETCHING,
    });
    const res = await EmployeeService.getEmployeeShiftChange(idEmployees);
    if (res) {
      dispatch({
        type: EMPLOYEE_CHANGE_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: EMPLOYEE_CHANGE_FAILED,
    });
  }
};

export const addEmployeeInformationChange = (formData) => async () => {
  try {
    const res = await EmployeeService.addEmployeeInformationChange(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    if (error.response) {
      return error.response;
    }
  }
};

export const getEmployeeNetwork = (idEmployees) => async (dispatch) => {
  try {
    const res = await EmployeeService.getEmployeeNetwork(idEmployees);
    if (res) {
      dispatch({
        type: EMPLOYEEPROFILE_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: EMPLOYEEPROFILE_FAILED,
    });
    console.log(err);
  }
};

export const clearEmployeeNetwork = () => async (dispatch) => {
  dispatch({
    type: EMPLOYEEPROFILE_SUCCESS,
    payload: null,
  });
};

export const putOverView = (id, formData) => async () => {
  try {
    const res = await EmployeeService.putOverView(id, formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return message;
  }
};

export const deleteInterest = (id) => async () => {
  try {
    const res = await EmployeeService.deleteInterest(id);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return message;
  }
};

export const deleteSkill = (id) => async () => {
  try {
    const res = await EmployeeService.deleteSkill(id);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return message;
  }
};

export const postInterest = (id, formData) => async () => {
  try {
    const res = await EmployeeService.postInterest(id, formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return message;
  }
};

export const postSkill = (id, formData) => async () => {
  try {
    const res = await EmployeeService.postSkill(id, formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return message;
  }
};

export const getTimescheduleHolidayUsed = (query) => async (dispatch) => {
  try {
    const res = await EmployeeService.getTimescheduleHolidayUsed(query);
    if (res) {
      return res;
    }
  } catch (error) {
    if (error.response) {
      return error.response;
    }
  }
};

export const dumpExcelNicknameEmployees = (formData) => async () => {
  try {
    const res = await EmployeeService.dumpExcelNicknameEmployees(formData);
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error.response);
    if (error.response) {
      return error.response.data;
    }
  }
};

export const dumpExcelGenderEmployees = (formData) => async () => {
  try {
    const res = await EmployeeService.dumpExcelGenderEmployees(formData);
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error.response);
    if (error.response) {
      return error.response.data;
    }
  }
};

export const dumpExcelNationalityEmployees = (formData) => async () => {
  try {
    const res = await EmployeeService.dumpExcelNationalityEmployees(formData);
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error.response);
    if (error.response) {
      return error.response.data;
    }
  }
};

export const dumpExcelStatusEmployees = (formData) => async () => {
  try {
    const res = await EmployeeService.dumpExcelStatusEmployees(formData);
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error.response);
    if (error.response) {
      return error.response.data;
    }
  }
};

export const dumpExcelPassportEmployees = (formData) => async () => {
  try {
    const res = await EmployeeService.dumpExcelPassportEmployees(formData);
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error.response);
    if (error.response) {
      return error.response.data;
    }
  }
};

export const dumpExcelWorkPermitEmployees = (formData) => async () => {
  try {
    const res = await EmployeeService.dumpExcelWorkPermitEmployees(formData);
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error.response);
    if (error.response) {
      return error.response.data;
    }
  }
};

export const dumpExcelBirthdayEmployees = (formData) => async () => {
  try {
    const res = await EmployeeService.dumpExcelBirthdayEmployees(formData);
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error.response);
    if (error.response) {
      return error.response.data;
    }
  }
};

export const dumpExcelAddressEmployees = (formData) => async () => {
  try {
    const res = await EmployeeService.dumpExcelAddressEmployees(formData);
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error.response);
    if (error.response) {
      return error.response.data;
    }
  }
};

export const dumpExcelTelephoneEmployees = (formData) => async () => {
  try {
    const res = await EmployeeService.dumpExcelTelephoneEmployees(formData);
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error.response);
    if (error.response) {
      return error.response.data;
    }
  }
};

export const dumpExcelEmailEmployees = (formData) => async () => {
  try {
    const res = await EmployeeService.dumpExcelEmailEmployees(formData);
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error.response);
    if (error.response) {
      return error.response.data;
    }
  }
};

export const dumpExcelEmergencyContactEmployees = (formData) => async () => {
  try {
    const res = await EmployeeService.dumpExcelEmergencyContactEmployees(
      formData
    );
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error.response);
    if (error.response) {
      return error.response.data;
    }
  }
};

export const dumpExcelTimeEmployees = (formData) => async () => {
  try {
    const res = await EmployeeService.dumpExcelTimeEmployees(formData);
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error.response);
    if (error.response) {
      return error.response.data;
    }
  }
};

export const dumpExcelManagerLV1Employees = (formData) => async () => {
  try {
    const res = await EmployeeService.dumpExcelManagerLV1Employees(formData);
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error.response);
    if (error.response) {
      return error.response.data;
    }
  }
};

export const dumpExcelManagerLV2Employees = (formData) => async () => {
  try {
    const res = await EmployeeService.dumpExcelManagerLV2Employees(formData);
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error.response);
    if (error.response) {
      return error.response.data;
    }
  }
};

export const dumpExcelContractTermainatoinEmployees =
  (formData) => async () => {
    try {
      const res = await EmployeeService.dumpExcelContractTermainatoinEmployees(
        formData
      );
      if (res) {
        return res.data;
      }
    } catch (error) {
      console.log(error.response);
      if (error.response) {
        return error.response.data;
      }
    }
  };

export const dumpExcelResignEmployees = (formData) => async () => {
  try {
    const res = await EmployeeService.dumpExcelResignEmployees(formData);
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error.response);
    if (error.response) {
      return error.response.data;
    }
  }
};

export const dumpExcelEducation1Employees = (formData) => async () => {
  try {
    const res = await EmployeeService.dumpExcelEducation1Employees(formData);
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error.response);
    if (error.response) {
      return error.response.data;
    }
  }
};

export const dumpExcelEducation2Employees = (formData) => async () => {
  try {
    const res = await EmployeeService.dumpExcelEducation2Employees(formData);
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error.response);
    if (error.response) {
      return error.response.data;
    }
  }
};

export const dumpExcelEducation3Employees = (formData) => async () => {
  try {
    const res = await EmployeeService.dumpExcelEducation3Employees(formData);
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error.response);
    if (error.response) {
      return error.response.data;
    }
  }
};

export const dumpExcelCostEmployees = (formData) => async () => {
  try {
    const res = await EmployeeService.dumpExcelCostEmployees(formData);
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error.response);
    if (error.response) {
      return error.response.data;
    }
  }
};

export const dumpExcelBookBankEmployees = (formData) => async () => {
  try {
    const res = await EmployeeService.dumpExcelBookBankEmployees(formData);
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error.response);
    if (error.response) {
      return error.response.data;
    }
  }
};

export const dumpExcelReligionEmployees = (formData) => async () => {
  try {
    const res = await EmployeeService.dumpExcelReligionEmployees(formData);
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error.response);
    if (error.response) {
      return error.response.data;
    }
  }
};

export const dumpExcelEmployees = (formData, query) => async () => {
  try {
    const res = await EmployeeService.dumpExcelEmployees(formData, query);
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error.response);
    if (error.response) {
      return error.response.data;
    }
  }
};

export const getEmployeesTaxDetails = async (
  selectedMonth,
  selectedYear,
  idPayrollType,
  selectedEmployee,
  type,
  idCompany
) => {
  try {
    const res = await EmployeeService.getEmployeesDetails(
      selectedMonth,
      selectedYear,
      idPayrollType,
      selectedEmployee,
      type,
      idCompany
    );
    if (res) {
      return res;
    }
  } catch (error) {
    console.error("Error in getEmployeesDetails:", error);
  }
};

export const getEmployeesDetailsTaxDeduction = async (
  selectedYear,
  idCompany
) => {
  try {
    const res = await EmployeeService.getAllEmployeesTaxDeduction(
      selectedYear,
      idCompany
    );
    if (res) {
      return res;
    }
  } catch (error) {
    console.error("Error in getEmployeesDetailsTaxDeduction:", error);
  }
};

export const deleteEmployeeContract = async (id, idEmployees) => {
  try {
    const res = await EmployeeService.deleteEmployeeContract(id, idEmployees);
    if (res) {
      return res;
    }
  } catch (error) {
    console.error("Error message:", error);
  }
};

export const getEmployeeContractByEmployee = async (id) => {
  try {
    const res = await EmployeeService.getEmployeeContractByEmployee(id);
    if (res) {
      // dispatch({
      //   type: CONTRACT_SUCCESS,
      //   payload: res.data,
      // });

      return res;
    }
  } catch (error) {
    // dispatch({
    //   type: CONTRACT_FAILED,
    // });
    console.error(error);
  }
};

export const getPaymentType = () => async () => {
  try {
    const res = await EmployeeService.getPaymentType();
    if (res) {
      return res;
    }
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getEmployeeSearch = (query) => async (dispatch) => {
  try {
    dispatch({
      type: EMPLOYEE_SEARCH_SUCCESS,
    });
    const res = await EmployeeService.getEmployeeSearch(query);
    if (res) {
      dispatch({
        type: EMPLOYEE_SEARCH_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (error) {
    dispatch({
      type: EMPLOYEE_SEARCH_FAILED,
    });
    console.error(error);
    return error.response;
  }
};

export const getEmployeeShiftChangeHistory =
  (idEmployees) => async (dispatch) => {
    try {
      dispatch({
        type: EMPLOYEE_SHIFT_CHANGE_HISTORY_FETCHING,
      });
      const res = await EmployeeService.getEmployeeShiftChangeHistory(
        idEmployees
      );
      if (res) {
        dispatch({
          type: EMPLOYEE_SHIFT_CHANGE_HISTORY_SUCCESS,
          payload: res.data,
        });
      }
    } catch (error) {
      dispatch({
        type: EMPLOYEE_SHIFT_CHANGE_HISTORY_FAILED,
      });
    }
  };

export const patchEmployeeShiftChangeHistoryStandard =
  (idEmployees, idEmployeeShift, formData) => async () => {
    try {
      const res = await EmployeeService.patchEmployeeShiftChangeHistoryStandard(
        idEmployees,
        idEmployeeShift,
        formData
      );
      if (res) {
        return res;
      }
    } catch (error) {
      return error.response;
    }
  };

export const deleteEmployeeShiftChangeHistoryStandard =
  (idEmployees, idEmployeeShift) => async () => {
    try {
      const res =
        await EmployeeService.deleteEmployeeShiftChangeHistoryStandard(
          idEmployees,
          idEmployeeShift
        );
      if (res) {
        return res;
      }
    } catch (error) {
      return error.response;
    }
  };

export const patchEmployeeShiftChangeHistoryDaily =
  (idEmployees, idEmployeeShiftDaily, formData) => async () => {
    try {
      const res = await EmployeeService.patchEmployeeShiftChangeHistoryDaily(
        idEmployees,
        idEmployeeShiftDaily,
        formData
      );
      if (res) {
        return res;
      }
    } catch (error) {
      return error.response;
    }
  };

export const deleteEmployeeShiftChangeHistoryDaily =
  (idEmployees, idEmployeeShiftDaily) => async () => {
    try {
      const res = await EmployeeService.deleteEmployeeShiftChangeHistoryDaily(
        idEmployees,
        idEmployeeShiftDaily
      );
      if (res) {
        return res;
      }
    } catch (error) {
      return error.response;
    }
  };

export const getEmployeeTaxRemittanceReport = async (query) => {
  try {
    const res = await EmployeeService.getSummaryTaxGroupedByEmployee(query);
    if (res) {
      return res;
    }
  } catch (error) {
    console.error(error);
    return error.response;
  }
};

export const getPFvalueAllYearByEmp = async (year, idCompany) => {
  try {
    const res = await EmployeeService.getPFvalueAllYearByEmp(year, idCompany);
    if (res) {
      return res;
    }
  } catch (error) {
    console.error(error);
    return error.response;
  }
};

export const getEmployeeProfileList = async (idCompany, idEmp, idEmp2) => {
  try {
    const res = EmployeeService.getEmployeeProfileList(
      idCompany,
      idEmp,
      idEmp2
    );
    if (res) {
      return res;
    }
  } catch (error) {
    console.error(error);
    return error.response;
  }
};

export const getEmployeePotential = async (idEmp) => {
  try {
    const res = EmployeeService.getEmployeePotential(idEmp);
    if (res) {
      return res;
    }
  } catch (error) {
    console.error(error);
  }
};

export const addEmployeePersonalityTest = async (query) => {
  try {
    const res = EmployeeService.addEmployeePersonalityTest(query);
    if (res) {
      return res;
    }
  } catch (error) {
    console.error(error);
  }
};

export const getEmployeePersonalityTest = async () => {
  try {
    const res = EmployeeService.getEmployeePersonalityTest();
    if (res) {
      return res;
    }
  } catch (error) {
    console.error(error);
  }
};

export const getEmployeeTalent = async (idEmp) => {
  try {
    const res = EmployeeService.getEmployeeTalent(idEmp);
    if (res) {
      return res;
    }
  } catch (error) {
    console.error(error);
  }
};

export const getTalentList = async () => {
  try {
    const res = EmployeeService.getTalentList();
    if (res) {
      return res;
    }
  } catch (error) {
    console.error(error);
  }
};

export const addEmployeeTalent = async (formData) => {
  try {
    const res = EmployeeService.addEmployeeTalent(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    console.error(error);
  }
};

export const updateEmployeeTalent = async (formData) => {
  try {
    const res = EmployeeService.updateEmployeeTalent(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    console.error(error);
  }
};

export const getPotentialList = async () => {
  try {
    const res = EmployeeService.getPotentialList();
    if (res) {
      return res;
    }
  } catch (error) {
    console.error(error);
  }
};

export const addEmployeePotential = async (formData) => {
  try {
    const res = EmployeeService.addEmployeePotential(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    console.error(error);
  }
};

export const updateEmployeePotential = async (
  idEmployeePotential,
  formData
) => {
  try {
    const res = EmployeeService.updateEmployeePotential(
      idEmployeePotential,
      formData
    );
    if (res) {
      return res;
    }
  } catch (error) {
    console.error(error);
  }
};

export const getAllEmployeeTalentByCompany = async (idCompany, year) => {
  try {
    const res = EmployeeService.getAllEmployeeTalentByCompany(idCompany, year);
    if (res) {
      return res;
    }
  } catch (error) {
    console.error(error);
  }
};

export const getAllEmployeeTalentByCompanyAndTalent = async (
  idTalent,
  idCompany,
  year
) => {
  try {
    const res = EmployeeService.getAllEmployeeTalentByCompanyAndTalent(
      idTalent,
      idCompany,
      year
    );
    if (res) {
      return res;
    }
  } catch (error) {
    console.error(error);
  }
};

export const getPerformanceTalentAndPotentialByEmp = async (idEmp) => {
  try {
    const res = EmployeeService.getPerformanceTalentAndPotentialByEmp(idEmp);
    if (res) {
      return res;
    }
  } catch (error) {
    console.error(error);
  }
};

export const getWagesPerYearByEmployees = async (query) => {
  try {
    const res = EmployeeService.getWagesPerYearByEmployees(query);
    if (res) {
      return res;
    }
  } catch (error) {
    console.error(error);
  }
};

export const addSignatureImageOfEmployees = (formData) => async () => {
  try {
    const res = await EmployeeService.addSignatureImageOfEmployees(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    return error;
  }
};

export const getSignatureImageOfEmployees = async () => {
  try {
    const res = await EmployeeService.getSignatureImageOfEmployees();
    if (res) {
      return res;
    }
  } catch (error) {
    return error;
  }
};

export const deleteSignatureImageOfEmployees = (query) => async () => {
  try {
    const res = await EmployeeService.deleteSignatureImageOfEmployees(query);
    if (res) {
      return res;
    }
  } catch (error) {
    return error;
  }
};
