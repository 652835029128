import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  Container,
  IconButton,
  MenuItem,
  Typography,
  styled,
  Button,
  Slider,
  LinearProgress,
  CircularProgress,
} from "@mui/material";
import Edit from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import CardStyle from "../../../../../shared/general/Card";
import { AccessTimeRounded } from "@mui/icons-material";
import TextFieldTheme from "../../../../../shared/general/TextFieldTheme";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import {
  addTeamKeyResultProgress,
  updateTeamKeyResultProgress,
  getAllTeamKeyResultProgress,
  updateTeamKeyResult,
} from "../../../../../../../actions/okrs";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { styled as muiStyled } from "@mui/material/styles";
import { useDropzone } from "react-dropzone";
import AddGraphicIcon from "../../../../../assets/add.png";
const StyledRoot = styled(Box)({});

const PrettoSlider = muiStyled(Slider)({
  color: "rgba(219, 65, 120, 1)",
  height: 8,
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    "&:hover": {
      boxShadow: "0 0 0 8px rgba(219, 65, 120, 0.16)",
    },
    "&:focus, &.Mui-active": {
      boxShadow: "0 0 0 14px rgba(219, 65, 120, 0.16)",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: "rgba(219, 65, 120, 1)",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&:before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
});

const ResponsiveButtonBlue = styled(Button)(({ theme }) => ({
  backgroundColor: "rgba(219, 65, 120, 1)",
  width: "10%",
  height: "50px",
  fontSize: "15px",
  fontWeight: "bold",
  borderRadius: "7px",
  "&:hover": {
    backgroundColor: "#C53B6C",
  },
  [theme.breakpoints.down("sm")]: {
    width: "10%",
    fontSize: "14px",
    height: "40px",
  },
}));

const StyledImportFile = styled("div")({
  maxWidth: 450,
  margin: "auto",
  marginBottom: 24,
  marginTop: 24,
  "& .dropzone-upload-file": {
    textAlign: "center",
    backgroundColor: "#F9F9FB",
    border: "1px solid #D0D0D0",
    borderStyle: "dashed",
    borderRadius: 8,
    height: 200,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    "& .inner-dropzone": {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      "& img": {
        marginBottom: 16,
      },
    },
  },
});

const FileSelected = ({ fileSelected, handleRemoveFile }) => (
  <Box display="flex" alignItems="center" marginTop={2}>
    <Typography variant="body2">{fileSelected.name}</Typography>
    <IconButton onClick={handleRemoveFile}>
      <ClearIcon />
    </IconButton>
  </Box>
);

const UpdateProgressTeamKeyResult = (props) => {
  const { match } = props;
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();

  const [TeamKeyResult, setTeamKeyResult] = useState(null);
  const [progressPercent, setProgressPercent] = useState(0);

  const useHookForm = useForm({
    defaultValues: {
      message: "",
    },
  });

  const [fileSelected, setFileSelected] = useState(null);
  const [formFileSelected, setFormFileSelected] = useState(null);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "*",
    onDrop: (acceptedFiles) => {
      const formData = new FormData();
      acceptedFiles.forEach((file) => {
        formData.append("files", file);
      });
      setFormFileSelected(formData);
      setFileSelected(acceptedFiles[0]);
    },
    maxFiles: 1,
  });

  const handleRemoveFile = () => {
    setFileSelected(null);
    setFormFileSelected(null);
  };

  const getValueStatus = (status) => {
    switch (status) {
      case null:
        return "";
      case 1:
        return "1";
      case 0:
        return "0";
      default:
        return "";
    }
  };

  const onSubmit = async (formData) => {
    const dataToSend = { ...formData, progressPercent };

    if (formFileSelected) {
      formFileSelected.append("message", formData.message);
      formFileSelected.append("progressPercent", progressPercent);

      const response = await dispatch(
        addTeamKeyResultProgress(
          match.params.idTeamKeyResult,
          formFileSelected,
          true
        )
      );

      if (response) {
        if (response.status === 200) {
          setTeamKeyResult((prev) => ({
            ...prev,
            teamKeyResultProgress: [
              {
                idTeamKeyResultProgress: response.data.idTeamKeyResultProgress,
                message: response.data.message,
                createDate: response.data.createDate,
                progressPercent: response.data.progressPercent,
                attachment: response.data.attachment,
              },
              ...prev.teamKeyResultProgress,
            ],
            progressPercent: response.data.progressPercent,
          }));
          useHookForm.setValue("message", "");
          setProgressPercent(response.data.progressPercent || 0);
          handleRemoveFile();
        }
      }
    } else {
      // If no file is attached
      const response = await dispatch(
        addTeamKeyResultProgress(match.params.idTeamKeyResult, dataToSend)
      );

      if (response) {
        if (response.status === 200) {
          setTeamKeyResult((prev) => ({
            ...prev,
            teamKeyResultProgress: [
              {
                idTeamKeyResultProgress: response.data.idTeamKeyResultProgress,
                message: response.data.message,
                createDate: response.data.createDate,
                progressPercent: response.data.progressPercent,
              },
              ...prev.teamKeyResultProgress,
            ],
            progressPercent: response.data.progressPercent,
          }));
          useHookForm.setValue("message", "");
          setProgressPercent(response.data.progressPercent || 0);
        }
      }
    }
  };

  const onChangeStatus = async (status) => {
    let formData = {
      isAchieve: status,
      achieveDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
    };

    if (formData.isAchieve === "") {
      formData.isAchieve = null;
    } else {
      formData.isAchieve = Number(formData.isAchieve);
    }

    const response = await dispatch(
      updateTeamKeyResult(match.params.idTeamKeyResult, formData)
    );

    if (response.status === 200) {
      setTeamKeyResult((prev) => ({
        ...prev,
        isAchieve: formData.isAchieve,
        achieveDate: formData.achieveDate,
      }));
    }
  };

  const handleUpdateKeyResultProgress = async (
    idTeamKeyResultProgress,
    formData
  ) => {
    const responseUpdate = await dispatch(
      updateTeamKeyResultProgress(idTeamKeyResultProgress, formData)
    );
    if (responseUpdate) {
      const res = await dispatch(
        getAllTeamKeyResultProgress(match.params.idTeamKeyResult)
      );
      if (res.status === 200) {
        setTeamKeyResult(res.data);
      }
    }
  };

  useEffect(() => {
    let isMounted = true;
    dispatch(getAllTeamKeyResultProgress(match.params.idTeamKeyResult)).then(
      (res) => {
        if (isMounted) {
          if (res.status === 200) {
            setTeamKeyResult(res.data);
            setProgressPercent(res.data.progressPercent || 0);
          }
        }
      }
    );

    return () => {
      isMounted = false;
    };
  }, [dispatch, match.params.idTeamKeyResult]);

  return (
    <StyledRoot className="page">
      <Box padding="24px 0">
        <Container maxWidth="lg">
          {TeamKeyResult ? (
            <Fragment>
              <CardStyle>
                <Box padding="24px">
                  <Box display="flex" alignItems="flex-start">
                    <Typography flexGrow="1" fontSize="18px" fontWeight="500">
                      {TeamKeyResult.keyResultName}
                    </Typography>

                    {/* <TextFieldTheme
                      value={getValueStatus(TeamKeyResult.isAchieve)}
                      select
                      onChange={(event) => {
                        onChangeStatus(event.target.value);
                      }}
                      SelectProps={{
                        displayEmpty: true,
                      }}
                      style={{
                        maxWidth: "200px",
                      }}
                    >
                      <MenuItem value="">{`${t("InProgress")}`}</MenuItem>
                      <MenuItem value="1">{`${t("Successfully")}`}</MenuItem>
                      <MenuItem value="0">{`${t("Unsuccessful")}`}</MenuItem>
                    </TextFieldTheme> */}
                  </Box>
                  <Box display="flex" alignItems="center" marginTop="8px">
                    <AccessTimeRounded
                      style={{ marginRight: "8px", fontSize: "16px" }}
                    />
                    <Typography fontSize="14px" lineHeight="1">
                      {dayjs(TeamKeyResult.startDate).format("D MMM BBBB")} -{" "}
                      {dayjs(TeamKeyResult.endDate).format("D MMM BBBB")}
                    </Typography>
                  </Box>
                  {/* Display current progress */}
                  <Box marginTop="16px">
                    <Typography variant="body2" color="text.secondary">
                      {`${t("Progress")}: ${TeamKeyResult.progressPercent}%`}
                    </Typography>
                    <LinearProgress
                      variant="determinate"
                      value={TeamKeyResult.progressPercent}
                      sx={{
                        height: 10,
                        borderRadius: 5,
                        backgroundColor: "rgba(219, 65, 120, 0.1)",
                        "& .MuiLinearProgress-bar": {
                          backgroundColor: "rgba(219, 65, 120, 1)",
                        },
                      }}
                    />
                  </Box>
                </Box>
              </CardStyle>

              <Box marginTop="24px">
                <CardStyle>
                  <Box padding="24px">
                    <form onSubmit={useHookForm.handleSubmit(onSubmit)}>
                      <Controller
                        name="message"
                        control={useHookForm.control}
                        render={({ field }) => (
                          <TextFieldTheme
                            {...field}
                            maxRows={5}
                            multiline
                            inputProps={{
                              placeholder: `${t("WriteMessage")}`,
                            }}
                          />
                        )}
                      />
                      {/* Slider for progressPercent */}
                      <Box marginTop="16px">
                        <Typography>
                          {t("ProgressStatus")}: {progressPercent}%
                        </Typography>
                        <PrettoSlider
                          value={progressPercent}
                          onChange={(e, newValue) =>
                            setProgressPercent(newValue)
                          }
                          valueLabelDisplay="auto"
                          min={TeamKeyResult.progressPercent || 0}
                          max={100}
                          step={1}
                          aria-labelledby="progress-slider"
                        />
                      </Box>
                      {/* File Upload Component */}
                      <StyledImportFile>
                        <div
                          {...getRootProps({
                            className: "dropzone-upload-file",
                          })}
                        >
                          <input {...getInputProps()} />
                          <div className="inner-dropzone">
                            <img
                              alt="AddIcon"
                              src={AddGraphicIcon}
                              width="80"
                            />
                            <Typography
                              style={{
                                marginTop: 8,
                                backgroundColor: "transparent",
                              }}
                              variant="body2"
                              color="text.secondary"
                            >
                              {t("DragAndDropOrClickToSelectFile")}
                            </Typography>
                          </div>
                        </div>
                      </StyledImportFile>
                      {fileSelected && (
                        <FileSelected
                          fileSelected={fileSelected}
                          handleRemoveFile={handleRemoveFile}
                        />
                      )}
                      <Box
                        display="flex"
                        justifyContent="flex-end"
                        marginTop="16px"
                      >
                        <ResponsiveButtonBlue
                          variant="contained"
                          type="submit"
                          disabled={useHookForm.formState.isSubmitting}
                        >
                          {useHookForm.formState.isSubmitting ? (
                            <CircularProgress size="21px" color="inherit" />
                          ) : (
                            `${t("Update")}`
                          )}
                        </ResponsiveButtonBlue>
                      </Box>
                    </form>
                  </Box>
                </CardStyle>
              </Box>

              <Box marginTop="24px">
                {TeamKeyResult.teamKeyResultProgress.map((progress, index) => (
                  <KeyResultProgress
                    key={index}
                    data={progress}
                    handleUpdateKeyResultProgress={
                      handleUpdateKeyResultProgress
                    }
                  />
                ))}
              </Box>
            </Fragment>
          ) : (
            <CircularProgress />
          )}
        </Container>
      </Box>
    </StyledRoot>
  );
};

const StyledKeyResultProgressRoot = styled(Box)({
  marginBottom: "16px",
  "&:last-child": {
    marginBottom: 0,
  },
});

const KeyResultProgress = (props) => {
  const { data, handleUpdateKeyResultProgress } = props;
  const [mode, setMode] = useState(false);
  const [edit, setEdit] = useState("");

  const handleChangeMode = () => {
    setEdit(data.message);
    setMode((prev) => !prev);
  };

  const handleUpdate = () => {
    setMode(false);
    handleUpdateKeyResultProgress(data.idTeamKeyResultProgress, {
      message: edit,
    });
  };

  return (
    <StyledKeyResultProgressRoot>
      <CardStyle>
        <Box padding="24px">
          {mode ? (
            <TextFieldTheme
              value={edit}
              onChange={(e) => setEdit(e.target.value)}
            />
          ) : (
            <>
              <Typography whiteSpace="pre-line">{data.message}</Typography>
              {data.attachment && (
                <Box marginTop={2}>
                  <a
                    href={data.attachment.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {data.attachment.filename}
                  </a>
                </Box>
              )}
            </>
          )}
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems={"center"}
            gap={1}
          >
            {mode ? (
              <>
                <IconButton onClick={handleUpdate} color="success">
                  <DoneIcon />
                </IconButton>
                <IconButton onClick={handleChangeMode} color="error">
                  <ClearIcon />
                </IconButton>
              </>
            ) : (
              <IconButton onClick={handleChangeMode} color="primary">
                <Edit />
              </IconButton>
            )}
            <Typography fontSize="12px" color="text.third">
              {dayjs(data.updateDate).format("D MMM BBBB HH.mm")}
            </Typography>
          </Box>
        </Box>
      </CardStyle>
    </StyledKeyResultProgressRoot>
  );
};

export default UpdateProgressTeamKeyResult;
