import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  Grid,
  Avatar,
  styled,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const StyledRoot = styled(Box)({});

const StyledCard = styled(Card)({
  height: 300,
  maxWidth: 350,
  minWidth: 250,
  padding: "8px",
  borderRadius: "10px",
});

const StyledCardContent = styled(CardContent)({
  display: "flex",
  flexDirection: "column",
  "& .text-name": {
    fontSize: "20px",
    fontWeight: "bold",
    color: "black",
    textAlign: "center",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  "& .text-secondary": {
    color: "gray",
    marginTop: "5px",
    display: "flex",
    alignItems: "flex-start",
  },
  "& .text-content": {
    color: "black",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    minWidth: "50px",
    flex: "1",
  },
});

const CustomButton = styled(Button)({
  color: "#DB4178",
  border: "2px solid #DB4178",
  backgroundColor: "transparent",
  borderRadius: "7px",
  height: "35px",
  marginTop: "15px",
  "&:hover": {
    color: "#DB4178",
    border: "2px solid #C53B6C",
    backgroundColor: "transparent",
    borderRadius: "7px",
    height: "35px",
  },
});

const PlanningPanel = (props) => {
  const { data, selectedQuarter } = props;
  const { t, i18n } = useTranslation();

  const [mode] = useState("list");

  const renderCards = () => {
    return data
      .filter((item) => {
        if (selectedQuarter === "ALL") {
          return true;
        }

        return item.quarter === selectedQuarter;
      })
      .map((row, index) => (
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          key={index}
          style={{ justifyContent: "center" }}
        >
          <StyledCard>
            <StyledCardContent>
              <Avatar
                alt={`${row.firstname_TH} ${row.lastname_TH}`}
                src={row.avatar}
                style={{
                  width: 100,
                  height: 100,
                  marginBottom: "10px",
                  alignSelf: "center",
                }}
              />
              <Typography className="text-name">
                {`${row.firstname_TH} ${row.lastname_TH}`}
              </Typography>
              <Typography
                variant="body2"
                className="text-secondary"
                style={{ marginTop: "10px" }}
              >
                Objective:
                <span className="text-content">{row.objectiveName}</span>
              </Typography>
              <Typography variant="body2" className="text-secondary">
                Quarter: <span className="text-content">Q{row.quarter}</span>
              </Typography>
              <Typography variant="body2" className="text-secondary">
                Number of Key Results:
                <span className="text-content">{row.keyResults.length}</span>
              </Typography>
              <CustomButton
                component={Link}
                to={`/okrs/individual-evaluation/${row.idIndividualObjective}`}
              >
                {t("Descriptions")}
              </CustomButton>
            </StyledCardContent>
          </StyledCard>
        </Grid>
      ));
  };

  return (
    <StyledRoot>
      <Box>
        {mode === "list" && (
          <Grid container spacing={2}>
            {renderCards()}
          </Grid>
        )}
      </Box>
    </StyledRoot>
  );
};

export default PlanningPanel;
