import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
  Fragment,
} from "react";
import {
  Avatar,
  Button,
  Chip,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  styled,
  TextField,
  Box,
  Grid,
  FormControl,
  InputAdornment,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DownloadIcon from "@mui/icons-material/Download";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch } from "react-redux";
import DataGrid, { Column, Pager } from "devextreme-react/data-grid";
import { utils, writeFile } from "xlsx";
import {
  addGradeOKR,
  getCarlibrateOrganization,
} from "../../../../../actions/okrs";
//Translator TH-EN
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiGrid-container": {
      marginBottom: 0,
    },
    "& .MuiCardContent-root": {
      padding: 0,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 16,
    },
    "& .MuiOutlinedInput-input": {
      padding: "16px 14px",
    },
    "& .MuiTableCell-root": {
      paddingTop: "5px",
      paddingBottom: "5px",
    },
    "& .MuiPaper-elevation1": {
      boxShadow: "none",
    },
  },
}));

const StyledWrapDataGrid = styled("div")({
  padding: "0 24px",
  '& .column-info[role="columnheader"]': {
    backgroundColor: "#283593",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-total-addition[role="columnheader"]': {
    backgroundColor: "#009688",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-info[role="gridcell"]': {
    verticalAlign: "middle !important",
  },
  '& .column-total-addition[role="gridcell"]': {
    verticalAlign: "middle !important",
  },
});

const StyledBoxSearch = styled(Box)({
  marginBottom: 16,
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    borderRadius: 4, // ปรับค่าเพื่อควบคุมความมนของขอบ
  },
});

function MenuGrade({ idEmployees, fechData }) {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeGrade = (value) => {
    const formData = { idEmployees, grade: value };
    dispatch(addGradeOKR(formData)).then((res) => {
      if (res.status === 201) {
        handleClose();
        fechData();
      }
    });
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        size="small"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleChangeGrade("A")}>{"A"}</MenuItem>
        <MenuItem onClick={() => handleChangeGrade("B")}>{"B"}</MenuItem>
        <MenuItem onClick={() => handleChangeGrade("C")}>{"C"}</MenuItem>
        <MenuItem onClick={() => handleChangeGrade("D")}>{"D"}</MenuItem>
      </Menu>
    </div>
  );
}

function CompanyCalibrate() {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const [calibrateData, setCalibrateData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // ใช้ react-hook-form สำหรับจัดการฟอร์มฟิลเตอร์
  const useHookForm = useForm({
    defaultValues: {
      fullName: "",
      department: "",
      position: "",
    },
    mode: "all",
  });

  // กรองข้อมูลตามค่าการค้นหา
  const filteredData = useMemo(() => {
    return calibrateData.filter((item) => {
      const fullName = `${item.firstname_TH || ""} ${
        item.lastname_TH || ""
      }`.toLowerCase();
      const department = (item.departmentName || "").toLowerCase();
      const position = (item.positionName || "").toLowerCase();

      const fullNameFilter = useHookForm.getValues("fullName").toLowerCase();
      const departmentFilter = useHookForm
        .getValues("department")
        .toLowerCase();
      const positionFilter = useHookForm.getValues("position").toLowerCase();

      return (
        fullName.includes(fullNameFilter) &&
        department.includes(departmentFilter) &&
        position.includes(positionFilter)
      );
    });
  }, [
    calibrateData,
    useHookForm.watch("fullName"),
    useHookForm.watch("department"),
    useHookForm.watch("position"),
  ]);

  const exportFile = useCallback(() => {
    const dataExport = filteredData.map((item) => ({
      idEmployees: item.idEmployees,
      firstname: item.firstname_TH,
      lastname: item.lastname_TH,
      position: item.positionName,
      department: item.departmentName,
      q1: item.quarter1,
      q2: item.quarter2,
      q3: item.quarter3,
      q4: item.quarter4,
      average: item.average,
      grade: item.grade,
    }));

    const ws = utils.json_to_sheet(dataExport);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Data");
    writeFile(wb, "Calibrate.xlsx");
  }, [filteredData]);

  const fechData = () => {
    dispatch(getCarlibrateOrganization()).then((res) => {
      if (res.status === 200) {
        setCalibrateData(res.data);
      }
    });
  };

  useEffect(() => {
    let isMounted = true;
    dispatch(getCarlibrateOrganization()).then((res) => {
      if (isMounted) {
        if (res.status === 200) {
          setCalibrateData(res.data);
        }
      }
    });

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <>
      <StyledWrapDataGrid>
        <Typography fontWeight={700} color={"#4f65df"} fontSize={"1.5rem"}>
          {`${t("CompanyPerformanceSummary")}`}
        </Typography>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: 8,
          }}
        >
          <Button
            variant="contained"
            size="small"
            sx={{ backgroundColor: "#4f65df" }}
            disableElevation
            startIcon={<DownloadIcon />}
            onClick={exportFile}
          >
            Excel
          </Button>
        </div>

        {/* ฟิลด์ค้นหา */}
        <form onSubmit={useHookForm.handleSubmit(() => {})}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <StyledBoxSearch>
                <Typography className="label" color="text.third">
                  {t("FullName")}
                </Typography>
                <Controller
                  name="fullName"
                  control={useHookForm.control}
                  render={({ field }) => (
                    <StyledTextField
                      {...field}
                      variant="outlined"
                      size="small"
                      fullWidth
                      placeholder={`${t("Search")} ${t("FullName")}`}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </StyledBoxSearch>
            </Grid>
            <Grid item xs={12} md={4}>
              <StyledBoxSearch>
                <Typography className="label" color="text.third">
                  {t("Department")}
                </Typography>
                <Controller
                  name="department"
                  control={useHookForm.control}
                  render={({ field }) => (
                    <StyledTextField
                      {...field}
                      variant="outlined"
                      size="small"
                      fullWidth
                      placeholder={`${t("Search")} ${t("Department")}`}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </StyledBoxSearch>
            </Grid>
            <Grid item xs={12} md={4}>
              <StyledBoxSearch>
                <Typography className="label" color="text.third">
                  {t("Position")}
                </Typography>
                <Controller
                  name="position"
                  control={useHookForm.control}
                  render={({ field }) => (
                    <StyledTextField
                      {...field}
                      variant="outlined"
                      size="small"
                      fullWidth
                      placeholder={`${t("Search")} ${t("Position")}`}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </StyledBoxSearch>
            </Grid>
          </Grid>
        </form>

        <DataGrid
          dataSource={filteredData}
          showRowLines={true}
          showColumnLines={false}
        >
          <Column
            dataField="idEmployees"
            caption="ID employee"
            visible={false}
          />
          <Column
            dataField="fullName"
            caption={t("FullName")}
            cssClass="column-info"
            minWidth={240}
            allowSorting={false}
            allowFiltering={false}
            cellRender={(cellData) => (
              <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
                <Avatar src={cellData.data.imageURL} />
                {cellData.data.firstname_TH + " " + cellData.data.lastname_TH}
              </div>
            )}
          />
          <Column
            dataField="positionName"
            caption={t("Position")}
            minWidth={160}
            cssClass="column-info"
            allowFiltering={false}
            allowSorting={false}
          />
          <Column
            dataField="departmentName"
            caption={t("Department")}
            minWidth={160}
            cssClass="column-info"
            allowFiltering={false}
            allowSorting={false}
          />
          <Column
            dataField="quarter1"
            caption="Q1"
            dataType={"number"}
            cssClass="column-info"
            allowFiltering={false}
            allowSorting={false}
          />
          <Column
            dataField="quarter2"
            caption="Q2"
            dataType={"number"}
            cssClass="column-info"
            allowFiltering={false}
            allowSorting={false}
          />
          <Column
            dataField="quarter3"
            caption="Q3"
            dataType={"number"}
            cssClass="column-info"
            allowFiltering={false}
            allowSorting={false}
          />
          <Column
            dataField="quarter4"
            caption="Q4"
            dataType={"number"}
            cssClass="column-info"
            allowFiltering={false}
            allowSorting={false}
          />
          <Column
            dataField="average"
            caption={t("Average")}
            dataType={"number"}
            cssClass="column-info"
            allowFiltering={false}
            sortOrder="desc"
          />
          <Column
            dataField="grade"
            caption={t("Grade")}
            cssClass="column-total-addition"
            allowFiltering={false}
            cellRender={(cellData) => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Chip
                  size="small"
                  label={cellData.value}
                  sx={{
                    backgroundColor: "#4f65df",
                    color: "#ffffff",
                    flexGrow: 1,
                  }}
                />
                <MenuGrade
                  idEmployees={cellData.data.idEmployees}
                  fechData={fechData}
                />
              </div>
            )}
          />
          <Pager
            allowedPageSizes={[10, 25, 50, 100]}
            showPageSizeSelector={true}
          />
        </DataGrid>
      </StyledWrapDataGrid>
    </>
  );
}

export default CompanyCalibrate;
