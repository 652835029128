import React, { useState } from "react";
import {
  Card,
  Box,
  Typography,
  Avatar,
  IconButton,
  Tooltip,
  Chip,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import GroupsIcon from "@mui/icons-material/Groups";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/system";
import DialogEditObjective from "./DialogEditObjective";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";

const StyledCard = styled(Card)(({ theme }) => ({
  position: "relative",
  padding: theme.spacing(2),
  margin: theme.spacing(1, 0),
  boxShadow: "0 3px 6px rgba(0,0,0,0.1)",
  borderRadius: "10px",
  minHeight: "160px",
}));

const IconButtonGroup = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  "& .MuiIconButton-root": {
    marginBottom: theme.spacing(1),
  },
}));

const ObjectiveCard = ({ data, refreshObjectives }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const isManager = userProfile && userProfile.roles.includes("ROLE_MANAGER");

  const hasCrossTeam = data.keyResults.some(
    (kr) => kr.crossTeam && kr.crossTeam.length > 0
  );

  const [openEditDialog, setOpenEditDialog] = useState(false);

  const handleOpenEditDialog = () => {
    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  };

  const deleteTeamObjective = () => {
    console.log("Delete objective:", data.idTeamObjective);
  };

  const isSuccess = data.isSuccess;
  const statusText = isSuccess === 1 ? t("ObjectiveSuccess") : t("InProgress");
  const statusColor = isSuccess === 1 ? "success" : "warning";

  let earliestStartDate = null;
  let latestEndDate = null;

  if (data.keyResults && data.keyResults.length > 0) {
    data.keyResults.forEach((kr) => {
      const krStartDate = dayjs(kr.startDate);
      const krEndDate = dayjs(kr.endDate);

      if (!earliestStartDate || krStartDate.isBefore(earliestStartDate)) {
        earliestStartDate = krStartDate;
      }
      if (!latestEndDate || krEndDate.isAfter(latestEndDate)) {
        latestEndDate = krEndDate;
      }
    });
  }

  const startDateFormatted = earliestStartDate
    ? earliestStartDate.format("DD/MM/YYYY")
    : "-";
  const endDateFormatted = latestEndDate
    ? latestEndDate.format("DD/MM/YYYY")
    : "-";

  return (
    <StyledCard>
      <Box display="flex" alignItems="center">
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          marginRight="16px"
        >
          <Avatar
            style={{
              backgroundColor: "#F8D7DA",
              color: "#DB4178",
              width: 100,
              height: 100,
              fontSize: "14px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="caption"
              style={{ lineHeight: 1, fontSize: "16px", color: "#DB4178" }}
            >
              {t("Quarter")}
            </Typography>
            <Typography
              variant="h4"
              style={{
                lineHeight: 1,
                fontSize: "36px",
                fontWeight: "bold",
                color: "#DB4178",
              }}
            >
              {data.quarter}
            </Typography>
          </Avatar>
        </Box>

        <Box flexGrow={1}>
          <Typography
            variant="h6"
            style={{
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
            }}
          >
            {data.objectiveName}

            {hasCrossTeam && (
              <GroupsIcon
                color="primary"
                style={{ marginLeft: "8px" }}
                fontSize="small"
              />
            )}
          </Typography>
          {data.departmentName && (
            <Typography variant="body2" color="textSecondary">
              {data.departmentName}
            </Typography>
          )}

          <Typography variant="body2" style={{ marginTop: "8px" }}>
            {t("NumberKeyResults")}: <strong>{data.keyResults.length}</strong>{" "}
            {t("Unit.Items")}
          </Typography>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          marginLeft="16px"
          marginRight="16px"
        >
          <Typography variant="body2" style={{ fontWeight: "bold" }}>
            {t("Date")}
          </Typography>
          <Typography variant="body2">
            {startDateFormatted} - {endDateFormatted}
          </Typography>
        </Box>

        <Box display="flex" alignItems="center" marginRight="16px">
          <Chip
            label={statusText}
            color={statusColor}
            variant="outlined"
            style={{ fontWeight: "bold" }}
          />
        </Box>

        {isManager && (
          <IconButtonGroup>
            <Tooltip title={t("ViewDetails")}>
              <IconButton
                component={Link}
                to={`/okrs/team-planning/information/${data.idTeamObjective}`}
              >
                <SearchIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title={t("EditObjective")}>
              <IconButton onClick={handleOpenEditDialog}>
                <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title={t("DeleteObjective")}>
              <IconButton onClick={deleteTeamObjective}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </IconButtonGroup>
        )}
      </Box>

      {openEditDialog && (
        <DialogEditObjective
          open={openEditDialog}
          handleClose={handleCloseEditDialog}
          data={data}
          onUpdated={() => {
            handleCloseEditDialog();
            if (refreshObjectives) {
              refreshObjectives();
            }
          }}
        />
      )}
    </StyledCard>
  );
};

export default ObjectiveCard;
