import dayjs from 'dayjs';
import ExcelJS from 'exceljs';

export const FamilyXlsx = (selectedEmployee, t, data) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(t("ReportMoneyPaidOutsideThePayrollSystem"));

    const headerValues = [
        t("Title"),
        t("FirstName"),
        t("LastName"),
        t("PersonalID"),
        t("BirthDate"),
        t("Relationship"),
        t("MaritalStatus"),
        t("EducationDegree"),
        t("UniversitySchoolCollege"),
        t("Faculty"),
        t("Major"),
        t("GPA"),
        t("fromYear"),
        t("endYear")
    ];

    const headerRow = worksheet.addRow(headerValues);
    headerRow.height = 50;

    const headerStyle1 = {
        font: { bold: true, size: 18, name: 'TH SarabunPSK', color: { argb: 'FFFFFF' } },
        alignment: { horizontal: "center", vertical: 'middle' },
        fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '002060' }
        },
    }

    headerRow.eachCell((cell) => {
        cell.style = headerStyle1;
        cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } }
        };
    });

    const colWidths = [
        { key: "title", width: 30 },
        { key: "firstname", width: 30 },
        { key: "lastname", width: 30 },
        { key: "personalID", width: 30 },
        { key: "birthday", width: 30 },
        { key: "relationship", width: 30 },
        { key: "maritalStatus", width: 30 },
        { key: "degree", width: 30 },
        { key: "UniversitySchoolCollege", width: 40 },
        { key: "faculty", width: 30 },
        { key: "major", width: 30 },
        { key: "gpa", width: 30 },
        { key: "year", width: 30 },
        { key: "endYear", width: 30 },
    ];

    colWidths.forEach((col, index) => {
        worksheet.getColumn(index + 1).width = col.width;
    });

    data && data.forEach((item) => {
        const row = [
            item.title ? t(`${item.title}`) : "-",
            item.firstname_TH ? item.firstname_TH : "-",
            item.lastname_TH ? item.lastname_TH : "-",
            item.personalID ? item.personalID : "-",
            item.birthday ? dayjs(item.birthday).format("DD MMMM YYYY") : "-",
            item.relationship ? t(`${item.relationship}`) : "-",
            item.maritalStatus ? t(`${item.maritalStatus}`) : "-",
            item.degree ? item.degree : "-",
            item.university ? item.university : "-",
            item.faculty ? item.faculty : "-",
            item.major ? item.faculty : "-",
            item.gpa ? item.gpa.toFixed(2) : "-",
            item.fromYear ? item.fromYear : "-",
            item.endYear ? item.endYear : "-",
        ];

        const excelRow = worksheet.addRow(row);

        const contentStyle = {
            font: { size: 18, name: 'TH SarabunPSK' },
            alignment: { horizontal: "center", vertical: 'middle' },
        };

        excelRow.eachCell((cell) => {
            cell.style = contentStyle;
            cell.border = {
                top: { style: 'thin', color: { argb: '000000' } },
                left: { style: 'thin', color: { argb: '000000' } },
                bottom: { style: 'thin', color: { argb: '000000' } },
                right: { style: 'thin', color: { argb: '000000' } }
            };
        });
    });

    workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${t("FamilyInfo")}-${(`${selectedEmployee ? selectedEmployee.firstname_TH + "-" + selectedEmployee.lastname_TH : ""}`)}.xlsx`;
        a.click();
        URL.revokeObjectURL(url);
    });
};
