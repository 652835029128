import React, { Fragment, useState } from "react";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Newemployee from "./Newemployee";
import Resign from "./resign";
import Contract from "./contract";
import Dailyplanner from "./dailyplanner";
import IndividualPanel from "./IndividualPanel";
import User from "./userinfo";
import Probation from "./probation";
import Tax from "./tax";
import Provident from "./provident";
import Approve from "./approvelist";
import ReportAdminPage from "../report";

import { Typography, Container, Avatar, Card, Box, Grid } from "@mui/material";

import MyDocument from "../../assets/-_Branding & Communication.png";
import { useSelector } from "react-redux";

//Translator TH-EN
import { useTranslation } from "react-i18next";
import SummaryOT from "./totalOT-export";
import SelectYearPayrollReport from "./selectYear-payroll";
import PayrollListMonth from "./payroll-list-month";
import SalaryAdjust from "./salaryAdjust";
import LeaveList from "./leaveList";
import DocumentRequest from "./document-request";
import WorkingTime from "./workingTime";
import TimeAttendance from "./timeAttendance";
import EmployeeTaxRemittance from "./employeeTaxRemittance";

import DrawerExportExcelLeaveReport from "./drawerExportExcelLeaveReport";
import DrawerEmployeeInformation from "./employeeInfo/drawerEmployeeInfo";
import DrawerRequestList from "./request-list/drawerRequestList";
import SummaryTimeStampDepartment from "./summary-timeStamp-department";
import PayrollCostCenter from "./payroll-costcenter";
import PayrollByFunction from "./payroll-function";
import MoneyPaidOutsideThePayroll from "./MoneyPaidOutsidePayroll";
import CompensatoryReport from "./compensate";
import FamilyReport from "./family";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
});
const StyledCard = styled(Card)({
  height: "100%",
  position: "relative",
  boxShadow: "none",
  borderRadius: 16,
  border: "1px solid #919eab3d",
  border: "none",
  boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  transition:
    "color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,transform 0.15s ease-in-out",
  display: "flex",
  cursor: "pointer",
  "&:hover": {
    transform: "translateY(-5px)",
  },
  "&.disabled": {
    filter: "grayscale(100%)",
    pointerEvents: "none",
    cursor: "default",
    "&:hover": {
      cursor: "default",
      transform: "translateY(0px)",
    },
  },
  "& .inner": {
    userSelect: "none",
    flexGrow: 1,
    padding: 16,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    boxSizing: "border-box",
    "& div": {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      alignItems: "center",
    },
    "& .MuiTypography-subtitle": {
      fontSize: 20,
      lineHeight: 1.2,
      textAlign: "center",
    },
    "& .MuiTypography-caption": {
      lineHeight: 1.2,
      fontSize: 14,
    },
    "& .MuiAvatar-root": {
      pointerEvents: "none",
      width: 100,
      height: 100,
      marginBottom: 16,
    },
  },
  "& .premium": {
    position: "absolute",
    right: 20,
  },
});

// const StyledWrapCardAdmin = styled("div")({
//   display: "grid",
//   // gridTemplateRows: "1fr 1fr 1fr 1fr 1fr 1fr 1fr auto",
//   gridTemplateColumns: "repeat(4, 1fr)",
//   gridAutoRows: "auto",
//   minWidth: 350,
//   columnGap: 24,
//   rowGap: 24,
// });

const AllReport = () => {
  const history = useHistory();
  const [view, setView] = useState();
  const [open, setOpen] = useState();
  const { t, i18n } = useTranslation();

  const [drawerExportRequestListConfig, setDrawerExportRequestListConfig] =
    useState({
      isOpen: false,
    });

  const [drawerExportExcelLeaveConfig, setDrawerExportExcelLeaveConfig] =
    useState({
      isOpen: false,
    });

  const [drawerEmployeeInformationConfig, setDrawerEmployeeInformationConfig] =
    useState({
      isOpen: false,
    });

  const { result: userProfile } = useSelector((state) => state.userProfile);

  const taxList = [
    // {
    //   id: 1,
    //   label: `${t("SummaryOfLeaveAndLate")}`,
    //   path: "employees-report/leave-absent-late",
    //   src: MyDocument,
    // },
    {
      id: 2,
      label: `${t("SummaryOfProbation")}`,
      src: MyDocument,
      path: "employees-report/probation",
    },
    {
      id: 3,
      label: `${t("SummaryContractExpire")}`,
      src: MyDocument,
      path: "employees-report/contractTermination",
    },
    {
      id: 4,
      label: `${t("SummaryOfNewEmployees")}`,
      src: MyDocument,
      path: "employees-report/newEmployees",
    },
    {
      id: 5,
      label: `${t("SummaryOfResigned")}`,
      src: MyDocument,
      path: "employees-report/resign",
    },
    { id: 6, label: `${t("EmployeeInformation")}`, src: MyDocument },
    { id: 7, label: `${t("WorkingTimeReport")}`, src: MyDocument },
    // {
    //   id: 8,
    //   label: `${t("AbsentLateReport")}`,
    //   path: "employees-report/late-absent",
    //   src: MyDocument,
    // },
    {
      id: 9,
      label: `${t("SalarySlip")}`,
      path: "/employees-report/salary",
      src: MyDocument,
    },
    { id: 10, label: `${t("LeaveReport")}`, src: MyDocument },
    { id: 11, label: `${t("TimeStampReport")}`, src: MyDocument },
    { id: 12, label: `${t("RequestReport")}`, src: MyDocument },
    {
      id: 13,
      label: `${t("SummaryOTandShift")}`,
      path: "employees-report/summary-ot-shift",
      src: MyDocument,
    },
    { id: 14, label: `${t("SalaryAdjustmentReport")}`, src: MyDocument },
    {
      id: 15,
      label: `${t("SummaryDiligenceAllowance")}`,
      path: "employees-report/diligence-allowance",
      src: MyDocument,
    },
    { id: 16, label: `${t("ProvidentFundReport")}`, src: MyDocument },
    { id: 17, label: `${t("TaxDeductionReport")}`, src: MyDocument },
    {
      id: 18,
      label: `${t("EmployeeTaxRemittanceReport")}`,
      src: MyDocument,
    },
    // {
    //   id: 18,
    //   label: `${t("CostElementReport")}`,
    //   path: "employees-report/cost-element",
    //   src: MyDocument,
    // },
    {
      id: 19,
      label: `${t("PayrollReport")}`,
      path: "employees-report/payroll",
      src: MyDocument,
    },
    {
      id: 20,
      label: t("SummaryOT"),
      src: MyDocument,
    },
    {
      id: 21,
      label: `${t("YearlyPayrollReport")}`,
      src: MyDocument,
    },
    {
      id: 22,
      label: "สรุปรายการ Payroll รายเดือน",
      src: MyDocument,
    },
    {
      id: 23,
      label: `${t("DocumentReport")}`,
      src: MyDocument,
    },
    {
      id: 24,
      label: `${t("SummaryLeaveList")}`,
      src: MyDocument,
    },
    {
      id: 25,
      label: `${t("SystemService")}`,
      path: "employees-report/dashboard-employee",
      src: MyDocument,
    },
  ];

  const MenuList = {
    workingtime: [
      {
        id: "WorkingTimeReport",
        label: `${t("WorkingTimeReport")}`,
        src: MyDocument,
        number: "R001"
        // disabled: true
      },
      {
        id: "TimeStampReport",
        label: `${t("TimeStampReport")}`,
        src: MyDocument,
        number: "R002"
      },
      {
        id: "SummaryTimeStampDepartment",
        label: `${t("SummaryTimeStampDepartment")}`,
        src: MyDocument,
        number: "R003"
      },
    ],
    overtime: [
      {
        id: "SummaryOTandShift",
        label: `${t("SummaryOTandShift")}`,
        path: "employees-report/summary-ot-shift",
        src: MyDocument,
        number: "R004"
      },
      // {
      //   id: "SummaryOT",
      //   label: t("SummaryOT"),
      //   src: MyDocument,
      // },
      {
        id: "SummaryOT2",
        label: t("SummaryOT"),
        path: "employees-report/summary-ot-hours-wages",
        src: MyDocument,
        number: "R005"
      },
      {
        id: "OtMonitoring",
        label: "OT Monitoring",
        path: "admin/otmonitoring",
        src: MyDocument,
        number: "R006"
      },
      {
        id: "OtMonitoringMonth",
        label: `${t("MonthlyOvertimeReport")}`,
        path: "admin/monthly-overtime-report",
        src: MyDocument,
        number: "R007"
      },
    ],
    leave: [
      {
        id: "SummaryOfLeaveAndLate",
        label: `${t("SummaryOfLeaveAndAbsences")}`,
        path: "employees-report/leave-absent-late",
        src: MyDocument,
        number: "R008"
      },
      {
        id: "LeaveReport",
        label: `${t("LeaveReport")}`,
        src: MyDocument,
        number: "R009"
      },
      {
        id: "SummaryLeaveList",
        label: `${t("SummaryLeaveList")}`,
        src: MyDocument,
        number: "R010"
      },
      {
        id: "quotaLeaveList",
        label: `${t("QuotaOnLeave")}`,
        path: "employees-report/quota-leave-list",
        src: MyDocument,
        number: "R011"
      },
      {
        id: "AccumulationOfCompensatoryTimeReport",
        label: `${t("AccumulationOfCompensatoryTimeReport")}`,
        src: MyDocument,
        number: "R012"
      },
    ],
    payroll: [
      {
        id: "PayrollReport",
        label: `${t("PayrollReport")}`,
        path: "employees-report/payroll",
        src: MyDocument,
        number: "R013"
      },
      {
        id: "YearlyPayrollReport",
        label: `${t("YearlyPayrollReport")}`,
        src: MyDocument,
        number: "R014"
      },
      {
        id: "MonthlyPayrollReport",
        label: `${t("MonthlyPayrollReport")}`,
        src: MyDocument,
        number: "R015"
      },
      {
        id: "SalaryAdjustmentReport",
        label: `${t("SalaryAdjustmentReport")}`,
        src: MyDocument,
        number: "R016"
      },
      {
        id: "SummaryDiligenceAllowance",
        label: `${t("SummaryDiligenceAllowance")}`,
        path: "/employees-report/diligence-allowance",
        src: MyDocument,
        number: "R017"
      },
      {
        id: "SummaryAdditionSpecial",
        label: "สรุปเงินได้พิเศษ",
        path: "/employees-report/addition-special",
        src: MyDocument,
        number: "R018"
      },
      {
        id: "SalarySlip",
        label: `${t("SalarySlip")}`,
        path: "/employees-report/salary",
        src: MyDocument,
        number: "R019"
      },
      {
        id: 16,
        label: `${t("ProvidentFundReport")}`,
        path: "/employees-report/provident-fund",
        src: MyDocument,
        number: "R020"
      },
      {
        id: 17,
        label: `${t("TaxDeductionReport")}`,
        src: MyDocument,
        number: "R021"
      },
      {
        id: 18,
        label: `${t("EmployeeTaxRemittanceReport")}`,
        path: "employees-report/employees-tax",
        src: MyDocument,
        number: "R022"
      },
      {
        id: 19,
        label: `${t("PayrollReportByCostCenter")}`,
        src: MyDocument,
        number: "R023"
      },
      {
        id: 20,
        label: `${t("PayrollReportByFunction")}`,
        src: MyDocument,
        number: "R024"
      },
      {
        id: "Tax",
        label: `${t("Tax")}`,
        path: "/employees-report/personal-tax",
        src: MyDocument,
        number: "R025"
      },
      {
        id: "outsidePayroll",
        label: `${t("ReportMoneyPaidOutsideThePayrollSystem")}`,
        src: MyDocument,
        number: "R026"
      },
    ],
    employee: [
      {
        id: "SummaryOfNewEmployees",
        label: `${t("SummaryOfNewEmployees")}`,
        src: MyDocument,
        path: "employees-report/newEmployees",
        number: "R027"
      },
      {
        id: "SummaryOfResigned",
        label: `${t("SummaryOfResigned")}`,
        src: MyDocument,
        path: "employees-report/resign",
        number: "R028"
      },
      {
        id: "SummaryContractExpire",
        label: `${t("SummaryContractExpire")}`,
        src: MyDocument,
        path: "employees-report/contractTermination",
        number: "R029"
      },
      {
        id: "SummaryOfProbation",
        label: `${t("SummaryOfProbation")}`,
        src: MyDocument,
        path: "employees-report/probation",
        number: "R030"
      },
      {
        id: "EmployeeInformation",
        label: `${t("EmployeeInformation")}`,
        src: MyDocument,
        number: "R031"
      },
      {
        id: "EmployeeUpdatedLevel",
        label: `${t("EmployeeUpdatedLevel")}`,
        src: MyDocument,
        path: "employees-report/leveling-report",
        number: "R032"
      },
      {
        id: "EmployeeUpdatedEmployment",
        label: `${t("EmployeeUpdatedEmployment")}`,
        src: MyDocument,
        path: "employees-report/employment-report",
        number: "R033"
      },
      {
        id: "EmployeeTransferPosition",
        label: `${t("EmployeeTransferPosition")}`,
        src: MyDocument,
        path: "employees-report/transfer-report",
        number: "R034"
      },
    ],
    etc: [
      {
        id: "RequestReport",
        label: `${t("RequestReport")}`,
        src: MyDocument,
        number: "R035"
      },
      {
        id: "DocumentReport",
        label: `${t("DocumentReport")}`,
        src: MyDocument,
        disabled: true,
        number: "R036"
      },
      {
        id: "FamilyInfo",
        label: `${t("FamilyInfo")}`,
        src: MyDocument,
        number: "R037"
      },
    ],
    service: [
      {
        id: "ServiceCharge",
        label: `${t("SystemService")}`,
        path: "employees-report/dashboard-employee",
        src: MyDocument,
        number: "R038"
        // disabled: true,
      },
    ],
  };

  const ManagerMenuList = {
    workingtime: [
      {
        id: "WorkingTimeReport",
        label: `${t("WorkingTimeReport")}`,
        src: MyDocument,
        // disabled: true
        number: "R001"
      },
      {
        id: "TimeStampReport",
        label: `${t("TimeStampReport")}`,
        src: MyDocument,
        number: "R002"
      },
    ],
    overtime: [
      {
        id: "SummaryOTandShift",
        label: `${t("SummaryOTandShift")}`,
        path: "employees-report/summary-ot-shift",
        src: MyDocument,
        number: "R003"
      },
      {
        id: "OtMonitoring",
        label: "OT Monitoring",
        path: "manager/otmonitoring",
        src: MyDocument,
        number: "R004"
      },
      {
        id: "OtMonitoringMonth",
        label: `${t("MonthlyOvertimeReport")}`,
        path: "manager/monthly-overtime-report",
        src: MyDocument,
        number: "R005"
      },
    ],
    leave: [
      {
        id: "SummaryOfLeaveAndLate",
        label: `${t("SummaryOfLeaveAndAbsences")}`,
        path: "employees-report/leave-absent-late",
        src: MyDocument,
        number: "R006"
      },
      {
        id: "quotaLeaveList",
        label: `${t("QuotaOnLeave")}`,
        path: "employees-report/quota-leave-list",
        src: MyDocument,
        number: "R007"
      },
    ],
    // etc: [
    //   {
    //     id: "FamilyInfo",
    //     label: `${t("FamilyInfo")}`,
    //     src: MyDocument,
    //     number: "R037"
    //   },
    // ],
  };

  const handleChangeView = (newView) => {
    if (!newView) return;

    switch (newView) {
      case 10: {
        setDrawerExportExcelLeaveConfig((prev) => ({
          ...prev,
          isOpen: true,
        }));
        return;
      }
      case 12: {
        setDrawerExportRequestListConfig((prev) => ({
          ...prev,
          isOpen: true,
        }));
        return;
      }
      default: {
        setView(newView);
        setOpen(true);
      }
    }
    // if (newView === 10) {
    //   setDrawerExportExcelLeaveConfig((prev) => ({
    //     ...prev,
    //     isOpen: true,
    //   }));
    //   return;
    // }

    // if (newView) {
    //   setView(newView);
    //   setOpen(true);
    // }
  };

  const handleClose = () => {
    setOpen(false);
    setView(null);
  };

  const handleClickMenu = (menu) => {
    if (!menu.id) return;

    if (menu.path) {
      history.push(menu.path);
      return;
    }

    switch (menu.id) {
      case "LeaveReport": {
        setDrawerExportExcelLeaveConfig((prev) => ({
          ...prev,
          isOpen: true,
        }));
        break;
      }
      case "RequestReport": {
        setDrawerExportRequestListConfig((prev) => ({
          ...prev,
          isOpen: true,
        }));
        break;
      }

      case "EmployeeInformation": {
        setDrawerEmployeeInformationConfig((prev) => ({
          ...prev,
          isOpen: true,
        }));
        break;
      }

      default: {
        setView(menu.id);
        setOpen(true);
        break;
      }
    }
  };

  return (
    <StyledRoot className="page">
      <Container
        maxWidth="lg"
        style={{ paddingTop: "24px", paddingBottom: "24px" }}
      >
        <Box marginBottom="24px">
          <Typography variant="h4">{`${t("AppMenu.Report")}`}</Typography>
        </Box>

        {userProfile && userProfile.roles.includes("ROLE_MANAGER") ? (
          <Grid container spacing={4}>
            <Grid item xs={12} container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h5">
                  <i
                    className="fa-solid fa-briefcase"
                    style={{ marginRight: "16px" }}
                  ></i>
                  {t("WorkingTime")}
                </Typography>
              </Grid>
              {ManagerMenuList.workingtime.map((item) => (
                <Grid key={item.id} item xs={12} sm={6} md={4} lg={3}>
                  <StyledCard
                    className={item.disabled ? "disabled" : undefined}
                    onClick={() => {
                      handleClickMenu(item);
                    }}
                  >
                    <Box className="inner">
                      <Avatar variant="rounded" src={item.src} />
                      <Box>
                        <Typography variant="subtitle" gutterBottom>
                          {item.label}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="subtitle" gutterBottom>
                          {item.number}
                        </Typography>
                      </Box>
                    </Box>
                  </StyledCard>
                </Grid>
              ))}
            </Grid>

            <Grid item xs={12} container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h5">
                  <i
                    className="fa-solid fa-briefcase"
                    style={{ marginRight: "16px" }}
                  ></i>
                  {t("Overtime")}
                </Typography>
              </Grid>
              {ManagerMenuList.overtime.map((item) => (
                <Grid key={item.id} item xs={12} sm={6} md={4} lg={3}>
                  <StyledCard
                    className={item.disabled ? "disabled" : undefined}
                    onClick={() => {
                      handleClickMenu(item);
                    }}
                  >
                    <Box className="inner">
                      <Avatar variant="rounded" src={item.src} />
                      <Box>
                        <Typography variant="subtitle" gutterBottom>
                          {item.label}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="subtitle" gutterBottom>
                          {item.number}
                        </Typography>
                      </Box>
                    </Box>
                  </StyledCard>
                </Grid>
              ))}
            </Grid>

            <Grid item xs={12} container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h5">
                  <i
                    className="fa-solid fa-umbrella-beach"
                    style={{ marginRight: "16px" }}
                  ></i>
                  {t("Leave")}
                </Typography>
              </Grid>
              {ManagerMenuList.leave.map((item) => (
                <Grid key={item.id} item xs={12} sm={6} md={4} lg={3}>
                  <StyledCard
                    className={item.disabled ? "disabled" : undefined}
                    onClick={() => {
                      handleClickMenu(item);
                    }}
                  >
                    <Box className="inner">
                      <Avatar variant="rounded" src={item.src} />
                      <Box>
                        <Typography variant="subtitle" gutterBottom>
                          {item.label}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="subtitle" gutterBottom>
                          {item.number}
                        </Typography>
                      </Box>
                    </Box>
                  </StyledCard>
                </Grid>
              ))}
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={4}>
            <Grid item xs={12} container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h5">
                  <i
                    className="fa-solid fa-briefcase"
                    style={{ marginRight: "16px" }}
                  ></i>
                  {t("WorkingTime")}
                </Typography>
              </Grid>
              {MenuList.workingtime.map((item) => (
                <Grid key={item.id} item xs={12} sm={6} md={4} lg={3}>
                  <StyledCard
                    className={item.disabled ? "disabled" : undefined}
                    onClick={() => {
                      handleClickMenu(item);
                    }}
                  >
                    <Box className="inner">
                      <Avatar variant="rounded" src={item.src} />
                      <Box>
                        <Typography variant="subtitle" gutterBottom>
                          {item.label}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="subtitle" gutterBottom>
                          {item.number}
                        </Typography>
                      </Box>
                    </Box>
                  </StyledCard>
                </Grid>
              ))}
            </Grid>

            <Grid item xs={12} container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h5">
                  <i
                    className="fa-solid fa-business-time"
                    style={{ marginRight: "16px" }}
                  ></i>
                  {t("Overtime")}
                </Typography>
              </Grid>
              {MenuList.overtime.map((item) => (
                <Grid key={item.id} item xs={12} sm={6} md={4} lg={3}>
                  <StyledCard
                    className={item.disabled ? "disabled" : undefined}
                    onClick={() => {
                      handleClickMenu(item);
                    }}
                  >
                    <Box className="inner">
                      <Avatar variant="rounded" src={item.src} />
                      <Box>
                        <Typography variant="subtitle" gutterBottom>
                          {item.label}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="subtitle" gutterBottom>
                          {item.number}
                        </Typography>
                      </Box>
                    </Box>
                  </StyledCard>
                </Grid>
              ))}
            </Grid>

            <Grid item xs={12} container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h5">
                  <i
                    className="fa-solid fa-umbrella-beach"
                    style={{ marginRight: "16px" }}
                  ></i>
                  {t("Leave")}
                </Typography>
              </Grid>
              {MenuList.leave.map((item) => (
                <Grid key={item.id} item xs={12} sm={6} md={4} lg={3}>
                  <StyledCard
                    className={item.disabled ? "disabled" : undefined}
                    onClick={() => {
                      handleClickMenu(item);
                    }}
                  >
                    <Box className="inner">
                      <Avatar variant="rounded" src={item.src} />
                      <Box>
                        <Typography variant="subtitle" gutterBottom>
                          {item.label}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="subtitle" gutterBottom>
                          {item.number}
                        </Typography>
                      </Box>
                    </Box>
                  </StyledCard>
                </Grid>
              ))}
            </Grid>

            {userProfile && userProfile.isPayroll && (
              <Grid item xs={12} container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h5">
                    <i
                      className="fa-solid fa-hand-holding-circle-dollar"
                      style={{ marginRight: "16px" }}
                    ></i>
                    {t("PayrollAndTax")}
                  </Typography>
                </Grid>
                {MenuList.payroll.map((item) => (
                  <Grid key={item.id} item xs={12} sm={6} md={4} lg={3}>
                    <StyledCard
                      className={item.disabled ? "disabled" : undefined}
                      onClick={() => {
                        handleClickMenu(item);
                      }}
                    >
                      <Box className="inner">
                        <Avatar variant="rounded" src={item.src} />
                        <Box>
                          <Typography variant="subtitle" gutterBottom>
                            {item.label}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="subtitle" gutterBottom>
                            {item.number}
                          </Typography>
                        </Box>
                      </Box>
                    </StyledCard>
                  </Grid>
                ))}
              </Grid>
            )}

            <Grid item xs={12} container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h5">
                  <i
                    className="fa-solid fa-circle-user"
                    style={{ marginRight: "16px" }}
                  ></i>
                  {t("Employment")}
                </Typography>
              </Grid>
              {MenuList.employee.map((item) => (
                <Grid key={item.id} item xs={12} sm={6} md={4} lg={3}>
                  <StyledCard
                    className={item.disabled ? "disabled" : undefined}
                    onClick={() => {
                      handleClickMenu(item);
                    }}
                  >
                    <Box className="inner">
                      <Avatar variant="rounded" src={item.src} />
                      <Box>
                        <Typography variant="subtitle" gutterBottom>
                          {item.label}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="subtitle" gutterBottom>
                          {item.number}
                        </Typography>
                      </Box>
                    </Box>
                  </StyledCard>
                </Grid>
              ))}
            </Grid>

            <Grid item xs={12} container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h5">
                  <i
                    className="fa-solid fa-memo"
                    style={{ marginRight: "16px" }}
                  ></i>
                  {t("Other")}
                </Typography>
              </Grid>
              {MenuList.etc.map((item) => (
                <Grid key={item.id} item xs={12} sm={6} md={4} lg={3}>
                  <StyledCard
                    className={item.disabled ? "disabled" : undefined}
                    onClick={() => {
                      handleClickMenu(item);
                    }}
                  >
                    <Box className="inner">
                      <Avatar variant="rounded" src={item.src} />
                      <Box>
                        <Typography variant="subtitle" gutterBottom>
                          {item.label}
                        </Typography>
                        <Box>
                          <Typography variant="subtitle" gutterBottom>
                            {item.number}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </StyledCard>
                </Grid>
              ))}
            </Grid>

            <Grid item xs={12} container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h5">
                  <i
                    className="fa-solid fa-circle-dollar"
                    style={{ marginRight: "16px" }}
                  ></i>
                  {t("ServiceFee")}
                </Typography>
              </Grid>
              {MenuList.service.map((item) => (
                <Grid key={item.id} item xs={12} sm={6} md={4} lg={3}>
                  <StyledCard
                    className={item.disabled ? "disabled" : undefined}
                    onClick={() => {
                      handleClickMenu(item);
                    }}
                  >
                    <Box className="inner">
                      <Avatar variant="rounded" src={item.src} />
                      <Box>
                        <Typography variant="subtitle" gutterBottom>
                          {item.label}
                        </Typography>
                        <Box>
                          <Typography variant="subtitle" gutterBottom>
                            {item.number}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </StyledCard>
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
      </Container>

      {/* {view === 2 && <Probation open={open} handleClose={handleClose} />} */}
      {view === 3 && <Contract open={open} handleClose={handleClose} />}
      {view === 4 && <Newemployee open={open} handleClose={handleClose} />}
      {view === 5 && <Resign open={open} handleClose={handleClose} />}
      {view === 6 && <User open={open} handleClose={handleClose} />}
      {view === 7 && <IndividualPanel open={open} handleClose={handleClose} />}
      {view === 11 && <Dailyplanner open={open} handleClose={handleClose} />}
      {/* {view === 12 && <Approve open={open} handleClose={handleClose} />} */}
      {/* {view === 13 && <SummaryOTShift open={open} handleClose={handleClose} />}  */}
      {view === 14 && <SalaryAdjust open={open} handleClose={handleClose} />}
      {/* {view === 16 && <Provident open={open} handleClose={handleClose} />} */}
      {view === 17 && <Tax open={open} handleClose={handleClose} />}
      {view === 19 && (
        <PayrollCostCenter open={open} handleClose={handleClose} />
      )}
      {view === 20 && (
        <PayrollByFunction open={open} handleClose={handleClose} />
      )}
      {/* {view === 18 && <EmployeeTaxRemittance open={open} handleClose={handleClose} />} */}
      {/* {view === 20 && <SummaryOT open={open} handleClose={handleClose} />} */}
      {view === 21 && (
        <SelectYearPayrollReport open={open} handleClose={handleClose} />
      )}
      {view === 22 && (
        <PayrollListMonth open={open} handleClose={handleClose} />
      )}
      {view === 23 && <DocumentRequest open={open} handleClose={handleClose} />}
      {view === 24 && <LeaveList open={open} handleClose={handleClose} />}

      {view === "SummaryOT" && (
        <SummaryOT open={open} handleClose={handleClose} />
      )}
      {view === "SummaryLeaveList" && (
        <LeaveList open={open} handleClose={handleClose} />
      )}
      {view === "YearlyPayrollReport" && (
        <SelectYearPayrollReport open={open} handleClose={handleClose} />
      )}
      {view === "MonthlyPayrollReport" && (
        <PayrollListMonth open={open} handleClose={handleClose} />
      )}
      {view === "SalaryAdjustmentReport" && (
        <SalaryAdjust open={open} handleClose={handleClose} />
      )}
      {view === "EmployeeInformation" && (
        <User open={open} handleClose={handleClose} />
      )}
      {view === "DocumentReport" && (
        <DocumentRequest open={open} handleClose={handleClose} />
      )}
      {view === "WorkingTimeReport" && (
        <WorkingTime open={open} handleClose={handleClose} />
      )}
      {view === "TimeStampReport" && (
        <TimeAttendance open={open} handleClose={handleClose} />
      )}
      {view === "SummaryTimeStampDepartment" && (
        <SummaryTimeStampDepartment open={open} handleClose={handleClose} />
      )}
      {view === "outsidePayroll" && (
        <MoneyPaidOutsideThePayroll open={open} handleClose={handleClose} />
      )}
      {view === "AccumulationOfCompensatoryTimeReport" && (
        <CompensatoryReport open={open} handleClose={handleClose} />
      )}
      {view === "FamilyInfo" && (
        <FamilyReport open={open} handleClose={handleClose} />
      )}
      {/* {view === "RequestReport" && <User open={open} handleClose={handleClose} />} */}

      <DrawerRequestList
        drawerConfig={drawerExportRequestListConfig}
        onClose={() => {
          setDrawerExportRequestListConfig((prev) => ({
            ...prev,
            isOpen: false,
          }));
        }}
      />

      <DrawerExportExcelLeaveReport
        drawerConfig={drawerExportExcelLeaveConfig}
        onClose={() => {
          setDrawerExportExcelLeaveConfig((prev) => ({
            ...prev,
            isOpen: false,
          }));
        }}
      />

      <DrawerEmployeeInformation
        drawerConfig={drawerEmployeeInformationConfig}
        onClose={() => {
          setDrawerEmployeeInformationConfig((prev) => ({
            ...prev,
            isOpen: false,
          }));
        }}
      />
    </StyledRoot>
  );
};

export default AllReport;
