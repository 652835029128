import ExcelJS from 'exceljs';
import dayjs from 'dayjs';

export const DetailedTimeStamp = (t, data, leaveData) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet1 = workbook.addWorksheet(t("TimeStampReport"));

    const leaveTypeNames = leaveData.map(leaveType => leaveType.name);

    const headerValues = [
        t("EmployeeID"),
        t("FirstName"),
        t("LastName"),
        t("Position"),
        t("Company"),
        t("Division"),
        t("Department"),
        t("Section"),
        t("Date"),
        t("ShiftName"),
        t("WorkingTime"),
        `${t("TimeIn")} - ${t("TimeOut")} (First in - Last out)`,

        `${t("Time")}${t("WorkingTimeRequestForReason")} 1`,
        `${t("Description")}${t("WorkingTimeRequestForReason")} 1`,
        `${t("AdditionalReasonForTimeApproval")} 1`,
        `${t("Status")}${t("WorkingTimeRequestForReason")} 1`,
        `${t("DateTimeTimeApprovalGranted")} 1`,

        `${t("Time")}${t("WorkingTimeRequestForReason")} 2`,
        `${t("Description")}${t("WorkingTimeRequestForReason")} 2`,
        `${t("AdditionalReasonForTimeApproval")} 2`,
        `${t("Status")}${t("WorkingTimeRequestForReason")} 2`,
        `${t("DateTimeTimeApprovalGranted")} 1`,

        `${t("RequestTime")} OT 1`,
        `${t("RequestReason")} OT 1`,
        `${t("AdditionalRequestReason")} OT 1`,
        `${t("RequestApprovalStatus")} OT 1`,
        `${t("Date")}_${t("Time")} ${t("ApproveRequest")} OT 1 ${t("Date")} 1`,
        `${t("Date")}_${t("Time")} ${t("ApproveRequest")} OT 1 ${t("Date")} 2`,

        `${t("RequestTime")} OT 2`,
        `${t("RequestReason")} OT 2`,
        `${t("AdditionalRequestReason")} OT 2`,
        `${t("RequestApprovalStatus")} OT 2`,
        `${t("Date")}_${t("Time")} ${t("ApproveRequest")} OT 2 ${t("Date")} 1`,
        `${t("Date")}_${t("Time")} ${t("ApproveRequest")} OT 2 ${t("Date")} 2`,

        t("WorkingHoursOnWorkingDay"),
        `${t("WorkingHoursOnWorkingDay")} (${t("RecordAttendance")})`,
        t("TotalOvertimeHours"),

        `OT 1 (${t("Unit.Hours")})`,
        `OT 1.5 (${t("Unit.Hours")})`,
        `OT 2 (${t("Unit.Hours")})`,
        `OT 3 (${t("Unit.Hours")})`,
        t("ShiftPayMorning"),
        t("ShiftPayAfternoon"),
        t("ShiftPayNight"),
        t("ShiftPayTotal"),
        t("Absent"),
        `${t("Late")}/${t("LeaveEarly")}`,
        ...leaveTypeNames
    ];

    const headerRow = worksheet1.addRow(headerValues);
    headerRow.height = 50;

    const headerStyle1 = {
        font: { bold: true, size: 18, name: 'TH SarabunPSK', color: { argb: 'FFFFFF' } },
        alignment: { horizontal: "center", vertical: 'middle' },
        fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '002060' }
        },
    }

    headerRow.eachCell((cell) => {
        cell.style = headerStyle1;
        cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } }
        };
    });

    let leaveTypeColWidths = [];
    leaveData.forEach(leaveType => {
        leaveTypeColWidths.push({
            key: `leaveType_${leaveType.idLeaveType}`,
            width: leaveType.name.length * 2
        });
    });

    const colWidths = [
        { key: "employeeID", width: 30 },
        { key: "firstname", width: 30 },
        { key: "lastname", width: 30 },
        { key: "position", width: 30 },
        { key: "company", width: 30 },
        { key: "division", width: 30 },
        { key: "department", width: 30 },
        { key: "section", width: 30 },
        { key: "date", width: 30 },
        { key: "shiftName", width: 40 },
        { key: "shiftType", width: 30 },
        { key: "timeIntimeOut", width: 40 },

        { key: "requestTimeApproval", width: 40 },
        { key: "requestTimeReason", width: 40 },
        { key: "additionalReason", width: 40 },
        { key: "requestStatus", width: 40 },
        { key: "ApprovalRequestTimeDate", width: 40 },

        { key: "requestTimeApproval2", width: 40 },
        { key: "requestTimeReason2", width: 40 },
        { key: "additionalReason2", width: 40 },
        { key: "requestStatus2", width: 40 },
        { key: "ApprovalRequestTimeDate2", width: 40 },

        { key: "requestOTApproval", width: 40 },
        { key: "requestOTReason", width: 40 },
        { key: "additionalOTReason", width: 40 },
        { key: "requestStatus", width: 40 },
        { key: "ApprovalRequestOTDate1Level1", width: 40 },
        { key: "ApprovalRequestOTDate1Level2", width: 40 },

        { key: "requestOTApproval2", width: 40 },
        { key: "requestOTReason2", width: 40 },
        { key: "additionalOTReason2", width: 40 },
        { key: "requestStatus2", width: 40 },
        { key: "ApprovalRequestOTDate2Level1", width: 40 },
        { key: "ApprovalRequestOTDate2Level2", width: 40 },

        { key: "totalWorkingHours", width: 40 },
        { key: "totalWorkingHoursFromTimeStamp", width: 40 },
        { key: "totalOtHours", width: 40 },

        { key: "otOneHours", width: 20 },
        { key: "otOneFiveHours", width: 20 },
        { key: "otTwoHours", width: 20 },
        { key: "otThreeHours", width: 20 },
        { key: "shiftMoning", width: 30 },
        { key: "shiftEvening", width: 30 },
        { key: "shiftNight", width: 30 },
        { key: "totalShift", width: 20 },
        { key: "absent", width: 20 },
        { key: "late", width: 20 },
        ...leaveTypeColWidths
    ];

    function formatNumber(number) {
        if (Number.isInteger(number)) {
            return number.toString();
        } else {
            return parseFloat(number.toFixed(2));
        }
    }

    let row = [];

    data && data.map((x) => x.schedule &&
        x.schedule.map((item) => {
            const shiftName = item.shiftGroup && item.shiftGroup.shiftGroupName ? item.shiftGroup.shiftGroupName.toLowerCase().includes("day")
                ? item.shiftGroup.shiftGroupName
                : (item.shiftGroup.shiftGroupName === item.shift.shiftName) ?
                    item.shiftGroup.shiftGroupName
                    : item.shiftGroup.shiftGroupName + " " + item.shift.shiftName
                : item.shiftGroup && item.shiftGroup.shiftGroupName

            const formattedShiftName = shiftName || "-";

            const timeIn = item.checkInAndcheckOutList && item.checkInAndcheckOutList.checkIn ? item.checkInAndcheckOutList.checkIn :
                (item.requestWorkingTime && item.requestWorkingTime[0] && item.requestWorkingTime[0].start ? item.requestWorkingTime[0].start :
                    item.requestWorkingTime && item.requestWorkingTime[1] && item.requestWorkingTime[1].start ? item.requestWorkingTime[1].start : t("NoTimeIn"));

            const timeOut = item.checkInAndcheckOutList && item.checkInAndcheckOutList.checkOut ? item.checkInAndcheckOutList.checkOut :
                (item.requestWorkingTime && item.requestWorkingTime[0] && item.requestWorkingTime[0].end ? item.requestWorkingTime[0].end :
                    item.requestWorkingTime && item.requestWorkingTime[1] && item.requestWorkingTime[1].end ? item.requestWorkingTime[1].end : t("NoTimeOut"));

            const timeInTimeout = timeIn + "-" + timeOut;
            const showShiftType = item.shiftType && item.shiftType.isWorkingDay === 0 ? item.shiftType.shiftTypeName : `${item.shiftType && item.shiftType.timeIn} - ${item.shiftType && item.shiftType.timeOut}`;

            function convertTimeInOut(timeIn, timeOut) {
                const [shiftTimeInHours, shiftTimeInMinutes] = timeIn.split(":").map(Number);
                const [shiftTimeOutHours, shiftTimeOutMinutes] = timeOut.split(":").map(Number);

                // Convert the times to minutes
                const shiftTimeInTotalMinutes = shiftTimeInHours * 60 + shiftTimeInMinutes;
                const shiftTimeOutTotalMinutes = shiftTimeOutHours * 60 + shiftTimeOutMinutes;

                // Calculate the difference in minutes
                let timeDifferenceInMinutes = shiftTimeOutTotalMinutes - shiftTimeInTotalMinutes;

                // If the difference is negative, it means the time period crosses midnight
                if (timeDifferenceInMinutes < 0) {
                    timeDifferenceInMinutes += 24 * 60;  // Add 24 hours worth of minutes
                }

                // Calculate hours and minutes
                const hours = Math.floor(timeDifferenceInMinutes / 60);
                const minutes = timeDifferenceInMinutes % 60;


                let stringHour;

                stringHour = `${hours || 0} ${t("Unit.Hours")} ${minutes || 0} ${t("Unit.Minute")}`

                return stringHour;
            };

            const hoursFromTimeStamp = convertTimeInOut(timeIn, timeOut);
            // const nornalWorkingTimeHours = convertTimeInOut((item.shiftType && item.shiftType.timeIn) || "", (item.shiftType && item.shiftType.timeOut) || "");
            const normalWorkingTimeHours = `${(((item.shiftType && item.shiftType.isWorkingDay === 1) && !item.isHoliday) && (item.shiftGroup && item.shiftGroup.workingTimeToday > 0) ? `${item.shiftGroup.workingTimeToday.toFixed(0)} ${t("Unit.Hours")}` : "-")} `;
            const totalOtHours = ((item.otOneHours ? parseFloat(item.otOneHours) : 0) + (item.otOneFiveHours ? parseFloat(item.otOneFiveHours) : 0) + (item.otTwoHours ? parseFloat(item.otTwoHours) : 0) + (item.otThreeHours ? parseFloat(item.otThreeHours) : 0));

            row = [
                item.employeeID ? item.employeeID : "-",
                item.firstname_TH ? item.firstname_TH : "-",
                item.lastname_TH ? item.lastname_TH : "-",
                item.positionName ? item.positionName : "-",
                item.companyName ? item.companyName : "-",
                item.divisionName ? item.divisionName : "-",
                item.departmentName ? item.departmentName : "-",
                item.sectionName ? item.sectionName : "-",
                item.date ? dayjs(item.date).format("YYYY-MM-DD") : "-",
                formattedShiftName,
                showShiftType,
                timeInTimeout,

                (item.requestWorkingTime && item.requestWorkingTime.length > 0) ? `${item.requestWorkingTime[0].startDate + " - " + item.requestWorkingTime[0].endDate}` : "-",
                (item.requestWorkingTime && item.requestWorkingTime.length > 0) && item.requestWorkingTime[0].ReasonName ? item.requestWorkingTime[0].ReasonName : "-",
                (item.requestWorkingTime && item.requestWorkingTime.length > 0) && item.requestWorkingTime[0].otherReason ? item.requestWorkingTime[0].otherReason : "-",
                (item.requestWorkingTime && item.requestWorkingTime.length > 0) && item.requestWorkingTime[0].isManagerLV1Approve ? item.requestWorkingTime[0].isManagerLV1Approve === 1 ? t("Approved") : t("NotApproved") : (item.requestWorkingTime && item.requestWorkingTime.length > 0) && item.requestWorkingTime[0].startDate ? t("PendingApproval") : "-",
                (item.requestWorkingTime && item.requestWorkingTime.length > 0) && item.requestWorkingTime[0].managerLV1ApproveDate ? item.requestWorkingTime[0].managerLV1ApproveDate : "-",

                (item.requestWorkingTime && item.requestWorkingTime.length > 1) ? `${item.requestWorkingTime[1].startDate + " - " + item.requestWorkingTime[1].endDate}` : "-",
                (item.requestWorkingTime && item.requestWorkingTime.length > 1) && item.requestWorkingTime[1].ReasonName ? item.requestWorkingTime[1].ReasonName : "-",
                (item.requestWorkingTime && item.requestWorkingTime.length > 1) && item.requestWorkingTime[1].otherReason ? item.requestWorkingTime[1].otherReason : "-",
                (item.requestWorkingTime && item.requestWorkingTime.length > 1) && item.requestWorkingTime[1].isManagerLV1Approve ? item.requestWorkingTime[1].isManagerLV1Approve === 1 ? t("Approved") : t("NotApproved") : (item.requestWorkingTime && item.requestWorkingTime.length > 1) && item.requestWorkingTime[0].startDate ? t("PendingApproval") : "-",
                (item.requestWorkingTime && item.requestWorkingTime.length > 1) && item.requestWorkingTime[1] && item.requestWorkingTime[1].managerLV1ApproveDate ? item.requestWorkingTime[1].managerLV1ApproveDate : "-",

                (item.requestOverTime && item.requestOverTime.length > 0) ? `${item.requestOverTime[0].startDate + " - " + item.requestOverTime[0].endDate}` : "-",
                (item.requestOverTime && item.requestOverTime.length > 0) && item.requestOverTime[0].ReasonName ? item.requestOverTime[0].ReasonName : "-",
                (item.requestOverTime && item.requestOverTime.length > 0) && item.requestOverTime[0].otherReason ? item.requestOverTime[0].otherReason : "-",
                (item.requestOverTime && item.requestOverTime.length > 0) && item.requestOverTime[0].isManagerLV1Approve ? item.requestOverTime[0].isManagerLV1Approve === 1 ? t("Approved") : t("NotApproved") : (item.requestOverTime && item.requestOverTime.length > 0) && item.requestOverTime[0].startDate ? t("PendingApproval") : "-",
                (item.requestOverTime && item.requestOverTime.length > 0) && item.requestOverTime[0].managerLV1ApproveDate ? item.requestOverTime[0].managerLV1ApproveDate : "-",
                (item.requestOverTime && item.requestOverTime.length > 0) && item.requestOverTime[0].managerLV2ApproveDate ? item.requestOverTime[0].managerLV2ApproveDate : "-",

                (item.requestOverTime && item.requestOverTime.length > 1) ? `${item.requestOverTime[1].startDate + " - " + item.requestOverTime[1].endDate}` : "-",
                (item.requestOverTime && item.requestOverTime.length > 1) && item.requestOverTime[1].ReasonName ? item.requestOverTime[1].ReasonName : "-",
                (item.requestOverTime && item.requestOverTime.length > 1) && item.requestOverTime[1].otherReason ? item.requestOverTime[1].otherReason : "-",
                (item.requestOverTime && item.requestOverTime.length > 1) && item.requestOverTime[1].isManagerLV1Approve ? item.requestOverTime[1].isManagerLV1Approve === 1 ? t("Approved") : t("NotApproved") : (item.requestOverTime && item.requestOverTime.length > 1) && item.requestOverTime[1].startDate ? t("PendingApproval") : "-",
                (item.requestOverTime && item.requestOverTime.length > 1) && item.requestOverTime[1].managerLV1ApproveDate ? item.requestOverTime[1].managerLV1ApproveDate : "-",
                (item.requestOverTime && item.requestOverTime.length > 1) && item.requestOverTime[1].managerLV2ApproveDate ? item.requestOverTime[1].managerLV2ApproveDate : "-",

                normalWorkingTimeHours,
                hoursFromTimeStamp,
                totalOtHours ? totalOtHours.toFixed(2) : 0,

                item.otOneHours ? formatNumber(item.otOneHours) : "-",
                item.otOneFiveHours ? formatNumber(item.otOneFiveHours) : "-",
                item.otTwoHours ? formatNumber(item.otTwoHours) : "-",
                item.otThreeHours ? formatNumber(item.otThreeHours) : "-",
                item.morningShiftFee ? formatNumber(item.morningShiftFee) : "-",
                item.afternoonShiftFee ? formatNumber(item.afternoonShiftFee) : "-",
                item.nightShiftFee ? formatNumber(item.nightShiftFee) : "-",
                item.shiftFeeTotal ? formatNumber(item.shiftFeeTotal) : "-",
                item.checkedAbsent ? item.checkedAbsent : 0,
                "-",
            ];

            leaveData.forEach((leaveType) => {
                let usedValue = 0.00;
                // Filter empLeaveData for the specific employee
                const leaveEmpData = item.employeeLeaveData;
                if (leaveEmpData) {
                    const leaveStartDate = dayjs(leaveEmpData.start);
                    const leaveEndDate = dayjs(leaveEmpData.end);
                    const currentDate = dayjs(item.date);

                    // Generate list of dates from start to end
                    const dateList = [];
                    let tempDate = leaveStartDate;

                    while (tempDate.isBefore(leaveEndDate) || tempDate.isSame(leaveEndDate, 'day')) {
                        dateList.push(tempDate.format('YYYY-MM-DD'));
                        tempDate = tempDate.add(1, 'day');
                    }

                    if (leaveEmpData.idLeaveType === leaveType.idLeaveType) {
                        // Check if the current date is within the date list
                        if (dateList.includes(currentDate.format('YYYY-MM-DD'))) {
                            usedValue = parseFloat(leaveEmpData.used);
                        }
                    }
                }
                row.push(usedValue);
            });

            const excelRow = worksheet1.addRow(row);
            const contentStyle = {
                font: { size: 18, name: 'TH SarabunPSK' },
                alignment: {
                    vertical: 'middle',
                    horizontal: 'center'
                }
            };

            colWidths.map((col, index) => {
                worksheet1.getColumn(index + 1).width = col.width;
            });

            excelRow.eachCell((cell) => {
                cell.style = contentStyle;
                cell.border = {
                    top: { style: 'thin', color: { argb: '000000' } },
                    left: { style: 'thin', color: { argb: '000000' } },
                    bottom: { style: 'thin', color: { argb: '000000' } },
                    right: { style: 'thin', color: { argb: '000000' } }
                };
            });
        }));

    workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${t("TimeStampReport")}.xlsx`;
        a.click();
        URL.revokeObjectURL(url);
    });
}

