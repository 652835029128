import React from "react";
import { Box, Container, Grid, Typography, styled, Paper } from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const StyledRoot = styled(Box)({
  backgroundColor: "#f5f5f5 !important",
  paddingBottom: 50,
});

const StyledClickablePaper = styled(Paper)({
  width: "auto",
  height: "30vh",
  borderRadius: "20px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  transition: "transform 0.2s ease-in-out",
  "&:hover": {
    transform: "scale(1.1)",
  },
});

const OKRsMenu = () => {
  const { t, i18n } = useTranslation();

  const menuItems = [
    {
      label: `${t("OrganizationalGoals")}`,
      path: "/okrs/organization-planning",
      icon: `${process.env.PUBLIC_URL}/assets/okrs/admin/organizational-goals.png`,
    },
    {
      label: `${t("UpdateOrganizationalGoals")}`,
      path: "/okrs/organization-progress",
      icon: `${process.env.PUBLIC_URL}/assets/okrs/admin/update-organizational-goals.png`,
    },
    {
      label: `${t("OrganizationalGoalsOverview")}`,
      path: "/okrs/dashboard/organization",
      icon: `${process.env.PUBLIC_URL}/assets/okrs/admin/organizational-goals-overview.png`,
    },
    {
      label: `${t("ProgressStatus")}`,
      path: "/okrs/status",
      icon: `${process.env.PUBLIC_URL}/assets/okrs/admin/progress-status.png`,
    },
    {
      label: t("TeamProgress"),
      path: "/okrs/admin-team-status",
      icon: `${process.env.PUBLIC_URL}/assets/okrs/admin/progress-status.png`,
    },
    {
      label: t("OKRStatusList"),
      path: "/okrs/status-plan-approve",
      icon: `${process.env.PUBLIC_URL}/assets/okrs/admin/plan-progress-status.png`,
    },
    // {
    //   label: `${t("OrganizationalGoalEvaluation")}`,
    //   path: "/okrs/organization-evaluation",
    //   icon: `${process.env.PUBLIC_URL}/assets/okrs/admin/individual-goal-evaluation.png`,
    // },
    {
      label: `${t("SummarizeScoresandGrades")}`,
      path: "/okrs/calibrate",
      icon: `${process.env.PUBLIC_URL}/assets/okrs/admin/summarize-scores-grades.png`,
    },
    // {
    //   label: `${t("OrganizationalGoalEvaluation")}`,
    //   path: "/orgvaluationlist",
    //   icon: `${process.env.PUBLIC_URL}/assets/okrs/admin/organizational-goal-evaluation.svg`,
    // },
    {
      label: t("OKRScoreboard"),
      path: "/okrs/scoreboard",
      icon: `${process.env.PUBLIC_URL}/assets/okrs/admin/summarize-scores-grades.png`,
    },
  ];

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <Grid container spacing={2} style={{ marginTop: 10 }}>
          {menuItems.map((item, index) => (
            <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
              <Link to={item.path} style={{ textDecoration: "none" }}>
                <StyledClickablePaper>
                  <img
                    src={item.icon}
                    alt="icon"
                    style={{
                      width: "50%",
                      height: "auto",
                      marginBottom: "10px",
                    }}
                  />
                  <Typography variant="body1" align="center">
                    {item.label}
                  </Typography>
                </StyledClickablePaper>
              </Link>
            </Grid>
          ))}
        </Grid>
      </Container>
    </StyledRoot>
  );
};

export default OKRsMenu;
