import React, { useEffect, useState, Fragment } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";

import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";

import TableEmployee from "./tableEmployee";
import { Grid } from "@mui/material";

import loading from "../../../../assets/social-media.gif";

import AlertResponse from "../../../../shared/general/AlertResponse";

import Utils from "../../../../../../utils";

import {
  getPayRunsId,
  getPayrunsDetailById,
  updateClosePayroll,
  deletePayroll,
  getPayrunByMonthAndCompanyToCompare,
} from "../../../../../../actions/payruns";

//Translator TH-EN
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
  paddingBottom: 24,
});

const StyledCardContent = styled(CardContent)({
  height: "100%",
  padding: 24,
  // display: "flex",
  // justifyContent: "center",
  // alignItems: "center",
  "& .wrap-header-filter": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 24,
    "& .wrap-filter": {
      display: "flex",
      "& .btn-filter": {
        marginRight: 8,
      },
      "& .MuiOutlinedInput-root": {
        borderRadius: 8,
      },
    },
  },
  "& .wrap-manage": {
    marginBottom: 24,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .wrap-manage-group": {
      display: "flex",
      alignItems: "center",
      "& .btn-mamage-group-company": {
        marginRight: 8,
      },
    },
    "& .wrap-edit-selected": {},
  },
  "& .wrap-panel": {
    height: 490,
    overflow: "auto",
    display: "flex",
  },
});

const StyledLoading = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "inherit",
});

const PayRunsEditPage = (props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  let { idPayrun } = useParams();
  let history = useHistory();
  const { result: payRunDetail } = useSelector((state) => state.payRunDetail);
  const { result: payRunDetailEmployees } = useSelector(
    (state) => state.payRunDetailEmployees
  );
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);

  useEffect(() => {
    console.log(props);
    dispatch(getPayRunsId(idPayrun));
    dispatch(getPayrunsDetailById(idPayrun));
  }, []);

  const handleGetAllDetail = () => {
    dispatch(getPayRunsId(idPayrun));
    dispatch(getPayrunsDetailById(idPayrun));
  };

  const handleClosePayroll = async () => {
    if (payRunDetail) {
      const result = await dispatch(updateClosePayroll(payRunDetail.idPayrun));
      if (result) {
        console.log("result: ", result);
        handleOpenAlert();
        if (result.status === 200) {
          handleChangeAlertType("success");
          dispatch(getPayRunsId(idPayrun));
          dispatch(getPayrunsDetailById(idPayrun));
        } else {
          handleChangeAlertType("error");
        }
      }
    }
  };

  const handleDeletePayroll = async () => {
    console.log("payRunDetail: ", payRunDetail)

    if (payRunDetail) {
      let dataForm = { ...payRunDetail }
      dataForm.isDelete = true
      console.log("payRunDetail: ", payRunDetail)
      console.log("dataForm: ", dataForm)
      const result = await dispatch(deletePayroll(dataForm));
      if (result) {
        if (result.status === 200) {
          handleChangeAlertType("success");
          handleOpenAlert();
          history.goBack()
        } else {
          handleChangeAlertType("error");
          handleOpenAlert();
        }
      }
    }

    // if (payRunDetail) {
    //   const result = await dispatch(deletePayroll(payRunDetail.idPayrun));
    //   if (result) {
    //     handleOpenAlert();
    //     if (result.status === 200) {
    //       handleChangeAlertType("success");
    //       history.goBack();
    //     } else {
    //       handleChangeAlertType("error");
    //     }
    //   }
    // }
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  return (
    <StyledRoot className={`page`}>
      <Container maxWidth="lg">
        {payRunDetail && (
          <Fragment>
            <div style={{ marginBottom: 16 }}>
              <Typography variant="h4" gutterBottom>
                {payRunDetail.payrunName}{" "}
                <Typography component={"span"} color={"text.secondary"}>
                  {payRunDetail.isDraft === 1
                    ? `${t("Draft")}`
                    : `(${t("CloseAccountingPeriod")} : ${payRunDetail.closePayrollDateText
                    })`}
                </Typography>
              </Typography>
              <div>
                {payRunDetail.isDraft === 3 && (
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Typography variant="body1" component={"span"}>
                        <Typography
                          variant="body1"
                          component={"span"}
                          color={"text.secondary"}
                        >
                          {`${t("Salary")}`}:&nbsp;
                        </Typography>
                        {Utils.numberWithCommas(payRunDetail.salary)}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="body1" component={"span"}>
                        <Typography
                          variant="body1"
                          component={"span"}
                          color={"text.secondary"}
                        >
                          {`${t("OTTotal")}`}:&nbsp;
                        </Typography>
                        {payRunDetail.ot
                          ? Utils.numberWithCommas(payRunDetail.ot)
                          : 0}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="body1" component={"span"}>
                        <Typography
                          variant="body1"
                          component={"span"}
                          color={"text.secondary"}
                        >
                          {`${t("Addition")}`}:&nbsp;
                        </Typography>
                        {Utils.numberWithCommas(
                          payRunDetail.additionsTaxable
                            ? payRunDetail.additionsTaxable
                            : 0 + payRunDetail.additionsNonTaxable
                              ? payRunDetail.additionsNonTaxable
                              : 0
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="body1" component={"span"}>
                        <Typography
                          variant="body1"
                          component={"span"}
                          color={"text.secondary"}
                        >
                          {`${t("Deduction")}`}:&nbsp;
                        </Typography>
                        {payRunDetail.totalDeduction
                          ? Utils.numberWithCommas(payRunDetail.totalDeduction)
                          : 0}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="body1" component={"span"}>
                        <Typography
                          variant="body1"
                          component={"span"}
                          color={"text.secondary"}
                        >
                          {`${t("TotalAmount")}`}:&nbsp;
                        </Typography>
                        {payRunDetail.net
                          ? Utils.numberWithCommas(payRunDetail.net)
                          : 0}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="body1" component={"span"}>
                        <Typography
                          variant="body1"
                          component={"span"}
                          color={"text.secondary"}
                        >
                          {`${t("EmpTotal")}`}:&nbsp;
                        </Typography>
                        {payRunDetail.net ? payRunDetail.numberEmployee : 0}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </div>
            </div>
            {payRunDetailEmployees ? (
              <TableEmployee
                handleClosePayroll={handleClosePayroll}
                handleGetAllDetail={handleGetAllDetail}
                handleDeletePayroll={handleDeletePayroll}
              // lastPayrun={lastPayrun}
              />
            ) : (
              <StyledLoading>
                <img width="120" alt="loading" src={loading} />
              </StyledLoading>
            )}

            {/* <DialogGroup
          manageBy={manageBy}
          open={open}
          handleClose={handleCloseDialogGroup}
        /> */}
          </Fragment>
        )}
      </Container>
      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
    </StyledRoot>
  );
};

export default PayRunsEditPage;

