import React, { useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Divider,
  LinearProgress,
} from "@mui/material";
import CardStyle from "../../../../../shared/general/Card";
import EditIcon from "@mui/icons-material/Edit";
import AssessmentIcon from "@mui/icons-material/Assessment";
import DoneIcon from "@mui/icons-material/Done";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import ChipStatus from "../../../../../shared/pages/okrs/components/ChipStatus";
import { AccessTimeRounded } from "@mui/icons-material";
import DetailKeyResult from "./dialogDetail";
import { useTranslation } from "react-i18next";
import EvaluationTeamKeyResult from "./evaluationKeyResult";
import RatingChip from "../../Organization/Information/ratingChip";
import StatusSuccess from "../../Organization/Information/statusSuccess";
import GroupWorkIcon from "@mui/icons-material/Groups";

const KeyResult = ({ data }) => {
  const { t, i18n } = useTranslation();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const isAdmin = userProfile && userProfile.roles.includes("ROLE_ADMIN");

  const [openDialog, setOpenDialog] = useState(false);
  const [openEvaluation, setOpenEvaluation] = useState(false);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleClickOpenEvaluation = () => {
    setOpenEvaluation(true);
  };

  const handleCloseEvaluation = () => {
    setOpenEvaluation(false);
  };

  const getStatusAchieveApprove = (status) => {
    if (status === 1) {
      return 5;
    } else if (status === 0) {
      return 6;
    } else {
      return 4;
    }
  };

  return (
    <>
      <CardStyle style={{ height: "100%" }}>
        <Box padding="24px">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Box display="flex" alignItems="center">
              <Typography fontSize="20px">{data.keyResultName}</Typography>

              {data.crossTeam && data.crossTeam.length > 0 && (
                <GroupWorkIcon
                  color="primary"
                  fontSize="small"
                  style={{ marginLeft: "8px" }}
                />
              )}
            </Box>
            <Box display="flex" alignItems="center" gap="8px">
              {isAdmin && getStatusAchieveApprove(data.isAchieve) === 4 && (
                <IconButton onClick={handleClickOpen}>
                  <EditIcon />
                </IconButton>
              )}
              {isAdmin && dayjs().isAfter(dayjs(data.endDate)) && (
                <IconButton onClick={handleClickOpenEvaluation}>
                  <AssessmentIcon />
                </IconButton>
              )}
              {data.isSuccess !== null && (
                <StatusSuccess status={data.isSuccess} />
              )}
            </Box>
          </Box>

          <Box display="flex" justifyContent="space-between" marginTop="8px">
            <Box display="flex" flexDirection="column" gap="8px">
              <Box display="flex" alignItems="center">
                <AccessTimeRounded
                  fontSize="14px"
                  style={{ marginRight: "8px", color: "#919eab" }}
                />
                <Typography
                  fontSize="14px"
                  lineHeight="1"
                  color="text.third"
                >{`${dayjs(data.startDate).format("D MMM")} - ${dayjs(
                  data.endDate
                ).format("D MMM BB")}`}</Typography>
              </Box>
              <ChipStatus
                status={getStatusAchieveApprove(data.isAchieve)}
                size="large"
              />
            </Box>
            {data.rating !== null && (
              <div style={{ height: "60px" }}>
                <RatingChip rating={data.rating} />
              </div>
            )}
          </Box>

          <Box marginTop="16px">
            <Typography fontSize="14px">
              <span style={{ color: "#919eab", paddingRight: "8px" }}>
                {t("Strategy")}
              </span>
              {data.tacticName}
            </Typography>

            <Typography fontSize="14px" paddingTop="16px">
              <span style={{ color: "#919eab", paddingRight: "8px" }}>
                {t("MeasurementData")}
              </span>
              {data.measureEvidence}
            </Typography>

            <Typography fontSize="14px" paddingTop="16px">
              <span style={{ color: "#919eab", paddingRight: "8px" }}>
                {t("ObjectiveMeasurementData")}
              </span>
              {data.commitQuality}
            </Typography>

            <Typography fontSize="14px" paddingTop="16px">
              <span style={{ color: "#919eab", paddingRight: "8px" }}>
                {t("TypeMeasurementData")}
              </span>
              {data.measureDataType === 1 ? t("Number") : t("Explanation")}
            </Typography>

            {data.progressPercent !== undefined && (
              <Box marginTop="16px">
                <Typography fontSize="14px" marginBottom="8px">
                  <span style={{ color: "#919eab", paddingRight: "8px" }}>
                    {t("ProgressStatus")}
                  </span>
                  {data.progressPercent}%
                </Typography>
                <LinearProgress
                  variant="determinate"
                  value={data.progressPercent}
                />
              </Box>
            )}

            {data.description && (
              <Typography
                fontSize="14px"
                paddingTop="16px"
                whiteSpace="pre-line"
              >
                <span style={{ color: "#919eab", paddingRight: "8px" }}>
                  {t("Descriptions")}
                </span>
                {data.description}
              </Typography>
            )}

            {data.feedback && (
              <Typography
                fontSize="14px"
                paddingTop="16px"
                whiteSpace="pre-line"
              >
                <span style={{ color: "#919eab", paddingRight: "8px" }}>
                  {t("Feedback")}
                </span>
                {data.feedback}
              </Typography>
            )}

            {data.crossTeam && data.crossTeam.length > 0 && (
              <>
                <Divider sx={{ my: 2 }} />
                <Box display="flex" alignItems="center">
                  <GroupWorkIcon color="primary" sx={{ mr: 1 }} />
                  <Typography fontSize="14px">
                    <span style={{ color: "#919eab", paddingRight: "8px" }}>
                      {t("Collaboration")}
                    </span>
                    {data.crossTeam.map((team, index) => (
                      <React.Fragment key={team.idDepartment}>
                        {team.departmentName || t("UnnamedDepartment")}
                        {index < data.crossTeam.length - 1 && ", "}
                      </React.Fragment>
                    ))}
                  </Typography>
                </Box>
              </>
            )}
          </Box>
        </Box>
      </CardStyle>

      {openDialog && (
        <DetailKeyResult
          open={openDialog}
          handleClose={handleClose}
          data={data}
          getStatusAchieveApprove={getStatusAchieveApprove}
        />
      )}

      {openEvaluation && (
        <EvaluationTeamKeyResult
          open={openEvaluation}
          handleClose={handleCloseEvaluation}
          data={data}
        />
      )}
    </>
  );
};

export default KeyResult;
