import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Typography,
  Avatar,
  IconButton,
  Grid,
  Box,
  Autocomplete,
  Paper,
} from "@mui/material";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import { styled } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import DialogName from "./DialogName";
import {
  getOnBoardingList,
  getOnBoardingReviewer,
  removeOnBoardingReviewer,
} from "../../../../../actions/onBoarding";
import DialogReview from "./DialogReview";
import { useTranslation } from "react-i18next";
import RemoveIcon from "@mui/icons-material/Remove";
import { getAffiliateOrganization } from "../../../../../actions/company";

const StyledBoxSearch = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(2),
  marginRight: theme.spacing(2),
}));

const StyledWrapBranch = styled("div")(({ theme }) => ({
  marginBottom: 36,
  "& .wrap-branch-top": {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginBottom: 30,
  },
  "& .accordion-root": {
    borderRadius: "8px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    marginBottom: "16px",
    "& .MuiAccordionSummary-root": {
      backgroundColor: theme.palette.background.default,
      padding: "16px 24px",
      borderRadius: "8px",
    },
    "& .MuiAccordionDetails-root": {
      backgroundColor: "#fff",
      padding: "16px 24px",
      borderRadius: "8px",
      borderTop: `1px solid ${theme.palette.divider}`,
    },
  },
  "& .avatar-container": {
    display: "flex",
    alignItems: "center",
    marginBottom: "12px",
    position: "relative",
    "& .avatar": {
      width: 40,
      height: 40,
      marginRight: 16,
      border: `2px solid ${theme.palette.grey[300]}`,
    },
    "& .remove-icon": {
      position: "absolute",
      top: "-5px",
      left: "-5px",
      backgroundColor: "rgba(255, 0, 0, 0.7)",
      color: "#fff",
      zIndex: 1,
      padding: 2,
      borderRadius: "50%",
    },
  },
  "& .MuiTypography-root": {
    fontSize: "1rem",
  },
  "& .MuiButton-root": {
    marginTop: "8px",
  },
}));

const OnBoardingList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { result: onBoardingReviewer } = useSelector(
    (state) => state.onBoardingReviewer
  );
  const { result: onBoardingList } = useSelector(
    (state) => state.onBoardingList
  );
  const { result: affiliateOrganizationList } = useSelector(
    (state) => state.affiliateOrganization
  );
  const [openDialog, setOpenDialog] = useState(false);
  const [formData, setFormData] = useState(null);
  const [typeDialog, setTypeDialog] = useState("Add");
  const [reload, setReload] = useState();
  const [isRemoveMode, setIsRemoveMode] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);

  useEffect(() => {
    dispatch(getOnBoardingList());
    dispatch(getOnBoardingReviewer());
    dispatch(getAffiliateOrganization());
  }, [reload]);

  var filteredOnBoardingList =
    !selectedCompany || selectedCompany === "all"
      ? onBoardingList
      : onBoardingList.filter(function (item) {
          return item.idCompany === selectedCompany.idCompany;
        });

  var sortedOnBoardingList =
    filteredOnBoardingList &&
    filteredOnBoardingList.slice().sort(function (a, b) {
      if (a.assignmentType === "all" && b.assignmentType !== "all") return -1;
      if (a.assignmentType !== "all" && b.assignmentType === "all") return 1;
      return 0;
    });

  const handleCompanyChange = (event, newValue) => {
    setSelectedCompany(newValue);
  };

  const handleOpenDialog = (type, formData) => {
    setTypeDialog(type);
    setOpenDialog(true);
    if (formData) {
      setFormData(formData);
    } else if (type === "AddReview") {
      setFormData({
        idOnBoardingList: formData.idOnBoardingList,
        itemName: formData.name,
      });
    }
  };

  const handleClose = () => {
    setOpenDialog(false);
    setReload((prev) => !prev);
    setFormData(null);
  };

  const handleRemoveInspector = (reviewer) => {
    dispatch(removeOnBoardingReviewer(reviewer.idOnBoardingReviewer));
    window.location.reload();
  };

  return (
    <StyledWrapBranch>
      <Grid container spacing={2} alignItems={"center"}>
        <Grid item xs={12} sm={6} md={4}>
          <StyledBoxSearch>
            <Typography
              className="label"
              color="text.third"
              marginBottom={"10px"}
            >
              {t("Company")}
            </Typography>
            <Autocomplete
              fullWidth
              options={affiliateOrganizationList || []}
              getOptionLabel={(option) => option.companyName}
              value={selectedCompany}
              onChange={handleCompanyChange}
              renderInput={(params) => (
                <TextFieldTheme
                  {...params}
                  variant="outlined"
                  placeholder={t("All")}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "8px",
                      height: "56px",
                    },
                  }}
                />
              )}
            />
          </StyledBoxSearch>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ButtonBlue
            style={{ marginRight: 8 }}
            size="small"
            variant="contained"
            onClick={() => handleOpenDialog("AddList")}
          >
            {t("AddItem")}
          </ButtonBlue>
        </Grid>
      </Grid>

      {sortedOnBoardingList && sortedOnBoardingList.length > 0 ? (
        sortedOnBoardingList.map((row, index) => (
          <Accordion
            key={index}
            className="accordion-root"
            sx={{
              boxShadow: "none",
              "&:before": {
                display: "none",
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography sx={{ width: "90%", flexShrink: 0 }}>
                {`${row.name}  ภายใน ${row.day} วัน`}
              </Typography>
              <Typography sx={{ color: "text.secondary" }}>
                {t("Inspector")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                borderTop: "2px solid #e0e0e0",
                marginTop: "-2px",
              }}
            >
              {onBoardingReviewer &&
              onBoardingReviewer.filter(
                (review) => review.idOnBoardingList === row.idOnBoardingList
              ).length > 0 ? (
                <Grid container spacing={2}>
                  {onBoardingReviewer.map((review, idx) =>
                    review.idOnBoardingList === row.idOnBoardingList ? (
                      <Grid item xs={12} sm={4} key={idx}>
                        <div className="avatar-container">
                          {isRemoveMode && (
                            <IconButton
                              onClick={() => handleRemoveInspector(review)}
                              className="remove-icon"
                            >
                              <RemoveIcon style={{ fontSize: 14 }} />
                            </IconButton>
                          )}
                          <Avatar
                            alt={review.firstname_TH}
                            src={
                              review.imageProfile
                                ? `data:image/jpeg;base64,${review.imageProfile}`
                                : ""
                            }
                            className="avatar"
                          />
                          <Typography>
                            {review.firstname_TH} {review.lastname_TH}
                            {review.departmentName &&
                              ` (${review.departmentName})`}
                          </Typography>
                        </div>
                      </Grid>
                    ) : null
                  )}
                </Grid>
              ) : (
                <Typography>{t("NoInspector")}</Typography>
              )}
              <div style={{ display: "flex", gap: "16px", marginTop: "16px" }}>
                <ButtonBlue
                  size="small"
                  variant="contained"
                  onClick={() => handleOpenDialog("AddReview", row)}
                >
                  {t("AddInspector")}
                </ButtonBlue>
                <ButtonBlue
                  size="small"
                  variant="contained"
                  onClick={() => setIsRemoveMode(!isRemoveMode)}
                >
                  {isRemoveMode ? t("Cancel") : t("RemoveInspector")}
                </ButtonBlue>
              </div>
            </AccordionDetails>
          </Accordion>
        ))
      ) : (
        <Typography>{t("NoItems")}</Typography>
      )}
      {openDialog && typeDialog !== "AddReview" && (
        <DialogName
          mode={typeDialog}
          open={openDialog}
          data={formData}
          handleClose={handleClose}
        />
      )}
      {openDialog && typeDialog === "AddReview" && (
        <DialogReview
          open={openDialog}
          handleClose={handleClose}
          data={formData}
        />
      )}
    </StyledWrapBranch>
  );
};

export default OnBoardingList;
