import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/material/styles";
import SelectAffiliateCompany from "../../shared/general/SelectAffiliateCompany";
import {
  Grid,
  Typography,
  TextField,
  Box,
  Popper,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { getDepartment } from "../../../../actions/company";
import ButtonBlue from "../../shared/general/ButtonBlue";
import CardStyle from "../../shared/general/Card";
import DialogType from "./dialogType";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import SettingsIcon from '@mui/icons-material/Settings';
import { makeStyles } from '@mui/styles';
import Swal from 'sweetalert2';

//Translator TH-EN
import { useTranslation } from "react-i18next";

import DialogImport from "./dialogImports";
import {
  getAllDocuments,
  getAllCompanyDocumentGroupName,
  getAllCompanyDocumentType,
  getallCompanyDocuments,
  getUnionDocumentGroupAndTypeName,
  deleteCompanyDocumentGroup,
  deleteCompanyDocumentInfo,
} from "../../../../actions/document";
import {
  getAffiliateOrganization,
} from "../../../../actions/company";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import EditCategoryDialog from "./alertDialog";
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import LoadingIcon from '../../assets/social-media.gif';

const useStyles = makeStyles((theme) => ({
  iconButton: {
    transition: 'transform 0.3s ease',
    color: '#000000',
    '&:hover': {
      transform: 'scale(1.0)',
      color: '#3f51b5',
    },
  },
  cancelButton: {
    color: 'red',
    border: '1px solid red',
    borderRadius: '5px',
    padding: '10px 20px',
    fontSize: '16px',
    fontWeight: 'bold',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    '&:hover': {
      backgroundColor: 'rgba(255, 0, 0, 0.1)',
    },
  },
  confirmButton: {
    color: 'green',
    border: '1px solid green',
    borderRadius: '5px',
    padding: '10px 20px',
    fontSize: '16px',
    fontWeight: 'bold',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    '&:hover': {
      backgroundColor: 'rgba(0, 255, 0, 0.1)',
    },
  },
  dialogBackground: {
    '& .MuiDialog-paper': {
      backgroundColor: '##BEBEBE',
      borderRadius: '10px',
    },
    '& .css-ut4szi': {
      fontSize: '24px',
      width: '90vw',
      height: '90vh',
    },
    '& .MuiDialog-container': {
      '& .MuiPaper-root': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: 'none',
        border: '1px solid black',
        borderRadius: '10px',
        background: 'linear-gradient(to bottom, #FFFFFF, #F0F8FF)',
      },
    },
    '& .MuiBackdrop-root': {
      backdropFilter: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      maxWidth: '40vw',
      maxHeight: '60vh',
      borderRadius: '10px',
    },
    '& .MuiBackdrop-root.MuiModal-backdrop': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      maxWidth: '0vw',
      maxHeight: '0vh',
      borderRadius: '10px',
    },
  },
  textContainer: {
    display: 'flex',
    alignItems: 'center',
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '5px',
    padding: '10px 8px',
    fontSize: '0.88rem',
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  fileDownloadOpen: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px',
    color: 'green',
  },
  fileDownloadOff: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#FF0033',
    padding: '10px',
  },
  fileDownloadOpenicon: {
    color: 'green',
  },
  fileDownloadOfficon: {
    color: 'red',
  },
}));

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
  paddingBottom: 48,
  "& .wrap-check, .wrap-leave": {
    marginTop: 36,
  },
  "& .wrap-check-in, .wrap-check-out, .leave": {
    borderRadius: 16,
    "& .head": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: 24,
    },
  },
  "& .leave": {
    "& .filter": {
      padding: 24,
      display: "flex",
      justifyContent: "space-between",
    },
  },
});

const StyledWrapFilter = styled("div")({
  width: "100%",
  display: "flex",
  alignItems: "center",
  marginBottom: 36,
  ["@media (min-width: 0px)"]: {
    flexDirection: "column",
  },
  ["@media (min-width: 900px)"]: {
    flexDirection: "row",
  },
  "& .search-name, .search-date": {
    width: "100%",
  },
  "& .wrap-search-action": {
    marginTop: 50,
    display: "flex",
    justifyContent: "flex-start",
    "& .btn-export": {
      marginLeft: 8,
    },
  },
});

const StyledBoxSearch = styled(Box)({
  marginTop: 22,
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      "&:hover": {
        backgroundColor: "#f6f7f8",
        margin: "0 8px",
        borderRadius: 8,
        paddingLeft: 8,
      },
    },
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiFilledInput-root": {
    backgroundColor: "#FFFFFF",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    border: "1px solid #B0B0B0",
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
      border: "1px solid #919eab",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
})

const companyDocumentPage = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { result: allCompanyDocumentType } = useSelector((state) => state.allCompanyDocumentType);

  const { result: companyDocumentGroup } = useSelector((state) => state.companyDocumentGroup);

  const { result: allUnionDocumentGroupTypeName } = useSelector((state) => state.allUnionDocumentGroupTypeName);

  const [selectedDocumentType, setSelectedDocumentType] = useState(null);
  const [selectedDocumentGroup, setSelectedDocumentGroup] = useState(null);

  const { result: allCompanyDocument } = useSelector((state) => state.allCompanyDocuments);

  const { result: affiliateOrganizationList } = useSelector((state) => state.affiliateOrganization);
  const { result: userProfile } = useSelector((state) => state.userProfile);



  const { t, i18n } = useTranslation();
  const classes = useStyles();

  const [reload, setreload] = useState();
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState(null);
  const [doc, setdoc] = useState(null);
  const [typeDialog, setTypeDialog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(false);


  useEffect(() => {
    dispatch(getDepartment());
    dispatch(getAffiliateOrganization());
  }, []);

  const [selectedCompany, setSelectedCompany] = useState(null);

  useEffect(() => {
    if (
      userProfile &&
      affiliateOrganizationList &&
      affiliateOrganizationList.length > 0
    ) {
      let ownCompany = affiliateOrganizationList.find(
        (x) => x.idCompany === userProfile.idCompany
      );
      if (!ownCompany) {
        ownCompany = affiliateOrganizationList[0];
      }
      setSelectedCompany(ownCompany);
    }
  }, [userProfile, affiliateOrganizationList]);

  const onChangeCompany = (newValue) => {
    setSelectedCompany(newValue);
  };

  const handleDialog = (type, formData, d) => {
    setTypeDialog(type);
    setOpen(true);
    if (formData) {
      setFormData(formData);
    }
    if (d) {
      setdoc(d);
    }
  };

  const handleClose = () => {
    setTypeDialog(null);
    setOpen(false);
    if (reload === 1) setreload(2);
    else setreload(1);
    setFormData(null);
  };

  useEffect(() => {
    dispatch(getAllCompanyDocumentGroupName());
    dispatch(getAllCompanyDocumentType());
    dispatch(getUnionDocumentGroupAndTypeName());
    dispatch(getallCompanyDocuments());
    dispatch(getAllDocuments());
  }, [reload]);


  const [filteredCompanyDocumentGroup, setFilteredCompanyDocumentGroup] = useState([]);
  const [filteredCompanyDocumentType, setFilteredCompanyDocumentType] = useState([]);
  console.log('filteredCompanyDocumentType', filteredCompanyDocumentType);
  const [filteredUnionDocumentGroupTypeName, setFilteredUnionDocumentGroupTypeName] = useState([]);
  const [filteredCompanyDocuments, setFilteredCompanyDocuments] = useState([]);
  console.log('filteredCompanyDocuments', filteredCompanyDocuments);

  useEffect(() => {
    if (selectedCompany) {
      const newFilteredCompanyDocumentGroup = Array.isArray(companyDocumentGroup)
        ? companyDocumentGroup.filter(group => group.idCompany === selectedCompany.idCompany)
        : [];
      setFilteredCompanyDocumentGroup(newFilteredCompanyDocumentGroup);

      const newFilteredCompanyDocumentType = Array.isArray(allCompanyDocumentType)
        ? allCompanyDocumentType.filter(type => type.idCompany === selectedCompany.idCompany)
        : [];
      setFilteredCompanyDocumentType(newFilteredCompanyDocumentType);

      const newFilteredUnionDocumentGroupTypeName = Array.isArray(allUnionDocumentGroupTypeName)
        ? allUnionDocumentGroupTypeName.filter(union => union.idCompany === selectedCompany.idCompany)
        : [];
      setFilteredUnionDocumentGroupTypeName(newFilteredUnionDocumentGroupTypeName);

      const newFilteredCompanyDocuments = Array.isArray(allCompanyDocument)
        ? allCompanyDocument.filter(doc => doc.idCompany === selectedCompany.idCompany)
        : [];
      setFilteredCompanyDocuments(newFilteredCompanyDocuments);
    }
  }, [selectedCompany, companyDocumentGroup, allCompanyDocumentType, allUnionDocumentGroupTypeName, allCompanyDocument]);

  const mergedDocumentTypeGroups = (filteredUnionDocumentGroupTypeName || []).reduce((acc, { documentGroupName, documentTypeName }) => {
    if (!acc[documentGroupName]) {
      acc[documentGroupName] = { documentGroupName, documentTypeNames: [documentTypeName] };
    } else {
      acc[documentGroupName].documentTypeNames.push(documentTypeName);
    }
    return acc;
  }, {});

  const mergedDocumentGroups = Object.values(mergedDocumentTypeGroups).map((item) => ({
    documentGroupName: item.documentGroupName,
    documentTypeNames: item.documentTypeNames,
  }));

  const setdata = () => {
    if (filteredCompanyDocumentType)
      if (filteredCompanyDocumentType.length > 0 && selectedDocumentType) {
        let temp = filteredCompanyDocumentType.filter((row) => {
          if (selectedDocumentType) {
            return row.documentTypeName === selectedDocumentType;
          } else {
            return row;
          }
        });
        if (filteredCompanyDocumentType.length > 0 && selectedDocumentGroup) {
          const filteredItems = filteredCompanyDocumentType.filter(
            (item) => item.documentGroupName === selectedDocumentGroup
          );
          return filteredItems;
        } else {
          return [...temp];
        }
      } else if (filteredCompanyDocumentType.length > 0 && selectedDocumentGroup) {
        return [...filteredCompanyDocumentType].filter(
          (item) => item.documentGroupName === selectedDocumentGroup
        );

      } else return [...filteredCompanyDocumentType];
  };


  const handleConfirmDelete = async (type, formData) => {
    setOpenDialog(false);

    try {
      let result;

      setLoading2(true);
      switch (type) {
        case 'deleteCategory':
          result = await dispatch(deleteCompanyDocumentGroup(formData));
          break;
        case 'deleteCompanyDocInfo':
          try {
            result = await dispatch(deleteCompanyDocumentInfo(formData));
            if (result) {
              setLoading2(false);
            }
          } catch (error) {
            console.error('Error during deleteCompanyDocInfo dispatch:', error);
          }
          break;
        default:
          console.log('Unknown delete type');
          throw new Error('Unknown delete type');
      }
      if (result) {
        Swal.fire({
          icon: 'success',
          title: type === 'deleteCategory' ? 'ลบหมวดหมู่เอกสารสำเร็จ!!' : 'ลบข้อมูลสำเร็จ!!',
          showConfirmButton: false,
          timer: 1500,
        });
        handleFileClose();
      } else {
        Swal.fire({
          icon: 'error',
          title: type === 'deleteCategory' ? 'ไม่สามารถลบหมวดหมู่เอกสารได้!!' : 'ไม่สามารถลบข้อมูลได้!!',
          text: 'กรุณาลองใหม่อีกครั้ง',
          confirmButtonText: 'OK',
        });
      }
    } catch (error) {
      console.error('Error deleting document:', error);
      Swal.fire({
        icon: 'error',
        title: type === 'deleteCategory' ? 'เกิดข้อผิดพลาดในการลบหมวดหมู่เอกสาร!!' : 'เกิดข้อผิดพลาดในการลบข้อมูล!!',
        text: 'กรุณาลองใหม่อีกครั้ง',
        confirmButtonText: 'OK',
      });
    } finally {
      handleCloseDialog();
    }
  };

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [dialogType, setDialogType] = useState(null);

  const handleDialog2 = (type, formData) => {
    setTypeDialog(type);
    setOpenDialog(true);
    setOpen(true);
    if (formData) {
      setFormData(formData);
    }
  };

  const handleClose1 = () => {
    setTypeDialog(null);
    setOpen(false);
  }

  const ReloadData = () => {
    if (reload === 1) setreload(2);
    else setreload(1);
  }

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleFileClose = () => {
    if (reload === 1) setreload(2);
    else setreload(1);
  };

  const handleClickOpen = (row, dialogType) => {
    console.log('rowxxx: dialogType: ', dialogType);
    console.log('rowxxx: ', row);
    setSelectedRow(row);
    console.log('setSelectedRow: ', setSelectedRow);
    if (dialogType === 'import') {
      setTypeDialog("import")
      setFormData(row)
      setOpenDialog(true);
    } else if (dialogType === 'edit') {
      setTypeDialog("edit")
      setFormData(row)
      setOpenDialog(true);
    }
  };

  const columnHeaders = [
    { key: "DocName", label: 'DocName' },
    { key: "Category", label: 'Category' },
    { key: "Descriptions", label: 'Descriptions' },
    { key: "LastUpdate", label: 'Lastupdate' },
    { key: "File", label: 'File' },
    { key: "Action", label: 'Action' },
  ];

  return (
    <div>
      <StyledRoot>
        <Grid item xs={12} sm={6} md={3} sx={{ marginBottom: 1 }}>
          <Typography
            className="label"
            color="text.third"
            style={{ marginBottom: 7, marginLeft: 3 }}
          >
            {t("Company")}
          </Typography>
          <SelectAffiliateCompany
            options={affiliateOrganizationList}
            value={selectedCompany}
            onChange={(_, value) => {
              onChangeCompany(value);
            }}
          />
        </Grid>
        <StyledWrapFilter>
          <Grid
            container
            spacing={2}
            columns={12}
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item xs={12} sm={6} md={3}>
              <StyledBoxSearch>
                <Typography className="label" color="text.third">
                  {t("SearchForDocumentType")}
                </Typography>
                <div className="search-name">
                  {filteredCompanyDocumentType && (
                    <StyledAutocomplete
                      options={filteredCompanyDocumentType}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          setSelectedDocumentType(newValue.documentTypeName);
                          setSelectedDocumentGroup(null);
                        } else {
                          setSelectedDocumentType(null);
                        }
                      }}
                      popupIcon={<i className="fa-light fa-chevron-down"></i>}
                      getOptionLabel={(option) =>
                        `${option.documentTypeName}`
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="filled"
                          placeholder={`${t("SelectDocumentType")}`}
                        />
                      )}
                      PopperComponent={StyledPopper}
                      noOptionsText={`${t("NoData")}`}
                      value={
                        selectedDocumentType
                          ? filteredCompanyDocumentType.find(
                            (item) => item.documentTypeName === selectedDocumentType
                          )
                          : null
                      }
                    />
                  )}
                </div>
              </StyledBoxSearch>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <StyledBoxSearch>
                <Typography className="label" color="text.third">
                  {t("SearchDocumentCategories")}
                </Typography>
                <div className="search-name">
                  {(filteredCompanyDocumentGroup || []) && (
                    <StyledAutocomplete
                      options={filteredCompanyDocumentGroup || []}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          setSelectedDocumentGroup(newValue.documentGroupName);
                          setSelectedDocumentType(null);
                        } else {
                          setSelectedDocumentGroup(null);
                        }
                      }}
                      popupIcon={<i className="fa-light fa-chevron-down"></i>}
                      getOptionLabel={(option) => option.documentGroupName}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="filled"
                          placeholder={`${t("SelectDocumentCategory")}`}
                        />
                      )}
                      PopperComponent={StyledPopper}
                      noOptionsText={`${t("NoData")}`}
                      value={
                        selectedDocumentGroup
                          ? (filteredCompanyDocumentGroup || []).find(
                            (group) => group.documentGroupName === selectedDocumentGroup
                          )
                          : null
                      }
                    />
                  )}
                </div>
              </StyledBoxSearch>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Grid
                container
                spacing={2}
                justifyContent="flex-end"
              >
                <Grid item xs={12}>
                  <StyledBoxSearch>
                    <div className="wrap-search-action" style={{ display: "flex", justifyContent: "flex-end" }}>
                      <ButtonBlue
                        variant="contained"
                        startIcon={<SettingsIcon />}
                        onClick={() => handleDialog("Category")}
                        style={{ marginRight: 10 }}
                      >
                        {`${t("ManageDocumentCategories")}`}
                      </ButtonBlue>
                      <ButtonBlue
                        variant="contained"
                        startIcon={<AddIcon />}
                        onClick={() => handleDialog("Add")}
                        style={{ marginRight: 10 }}
                      >
                        {`${t("AddDocumentType")}`}
                      </ButtonBlue>
                    </div>
                  </StyledBoxSearch>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </StyledWrapFilter>
        {filteredCompanyDocumentType && filteredCompanyDocuments ? (
          <>
            <CardStyle style={{ marginTop: 4 }}>
              <TableContainer component={Paper} style={{ userSelect: 'none' }}>
                <Table sx={{ minWidth: 600 }}>
                  <TableHead>
                    <TableRow>
                      {columnHeaders.map((header, index) => (
                        <TableCell align="center">
                          {t(header.key)}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredCompanyDocumentType && filteredCompanyDocumentType.length > 0 ? (
                      setdata().map((row, index) => (
                        <React.Fragment key={index}>
                          <TableRow>
                            <TableCell align="center">
                              {row.documentTypeName}
                            </TableCell>
                            <TableCell align="center" style={{ width: '20%' }}>
                              {row.documentGroupName}
                            </TableCell>
                            <TableCell style={{ width: '27%' }}>
                              {row.detail}
                            </TableCell>
                            <TableCell style={{ width: '14%', textAlign: 'center', verticalAlign: 'middle' }}>
                              {row.updateDate}
                            </TableCell>
                            <TableCell align="center">
                              {filteredCompanyDocuments.find((doc) => doc.documentTypeName === row.documentTypeName) ? (
                                <CheckCircleOutlineIcon
                                  style={{ color: "green" }}
                                  onClick={() => handleClickOpen({
                                    ...row,
                                    fileURL: filteredCompanyDocuments.find((doc) => doc.documentTypeName === row.documentTypeName).fileURL,
                                    filename: filteredCompanyDocuments.find((doc) => doc.documentTypeName === row.documentTypeName).filename
                                  }, "edit")}
                                />
                              ) : (
                                <ButtonBlue
                                  startIcon={<FileUploadOutlinedIcon />}
                                  sx={{
                                    backgroundColor: '#FFFFFF',
                                    color: '#46CBE2',
                                    border: '2px solid #46CBE2',
                                    borderRadius: '10px',
                                  }}
                                  onClick={() => handleClickOpen(row, "import")}
                                  style={{ marginRight: 10 }}
                                >
                                  {`${t("UpLoad")}`}
                                </ButtonBlue>
                              )}
                              {typeDialog === "deleteCompanyDocInfo" && (
                                <Dialog
                                  open={openDialog}
                                  onClose={handleCloseDialog}
                                  className={classes.dialogBackground}
                                >
                                  {loading2 ? (
                                    <img width="80" alt="loading" src={LoadingIcon} />
                                  ) : (
                                    <>
                                      <DialogTitle>{t("Confirmdelete")}</DialogTitle>
                                      <DialogContent>
                                        {t("DeleteCompanyDocRow")}
                                      </DialogContent>
                                      <DialogActions>
                                        <IconButton
                                          onClick={handleCloseDialog}
                                          className={classes.cancelButton}
                                        >
                                          {t("Cancel")}
                                        </IconButton>
                                        <IconButton
                                          onClick={() => {
                                            const matchingDoc = filteredCompanyDocuments.find(
                                              (doc) => doc.documentTypeName.trim() === row.documentTypeName.trim()
                                            );
                                            const updatedFormData = {
                                              ...formData,
                                              filename: matchingDoc ? matchingDoc.filename : '',
                                            };
                                            handleConfirmDelete('deleteCompanyDocInfo', updatedFormData);
                                          }}
                                          className={classes.confirmButton}
                                        >
                                          {t("Confirm")}
                                        </IconButton>
                                      </DialogActions>
                                    </>
                                  )}
                                </Dialog>
                              )}
                            </TableCell>
                            <TableCell align="center" style={{ width: '10%' }}>
                              <IconButton
                                style={{ color: "#ffea00" }}
                                onClick={() => handleDialog2("editDoc", row)}
                              >
                                <EditIcon />
                              </IconButton>
                              <IconButton
                                style={{ color: "#ff1744" }}
                                onClick={() => handleDialog2("deleteCompanyDocInfo", row)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={5} align="center">
                          {loading ? <img width="80" alt="loading" src={LoadingIcon} /> : t("NoData")}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardStyle>
          </>
        ) : (
          <>
            <Table sx={{ minWidth: 600 }}>
              <TableHead>
                <TableRow>
                  {columnHeaders.map((header, index) => (
                    <TableCell align="center">
                      {t(header.key)}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.from({ length: 6 }, (_, index) => (
                  <TableCell key={index} align="center">
                    {t("NoData")}
                  </TableCell>
                ))}
              </TableBody>
            </Table>
          </>
        )}
      </StyledRoot>

      {typeDialog === "import" ? (
        <DialogImport
          open={openDialog}
          mode={typeDialog}
          data={formData}
          onClose={handleCloseDialog}
          dialogType={typeDialog}
          handleFileClose={handleFileClose}
          handleCloseDialog={handleCloseDialog}
        />
      ) : (
        ""
      )}
      {typeDialog === "edit" ? (
        <DialogImport
          open={openDialog}
          dialogType={typeDialog}
          data={formData}
          onClose={handleCloseDialog}
          handleClose={handleClose}
          handleFileClose={handleFileClose}
          handleCloseDialog={handleCloseDialog}
        />
      ) : (
        ""
      )}
      {open && typeDialog !== 'Category' && typeDialog !== "deleteCompanyDocInfo" ? (
        <DialogType
          open={open}
          setOpen={setOpen}
          setTypeDialog={setTypeDialog}
          mode={typeDialog}
          data={formData}
          selectedCompany={selectedCompany}
          companyDocumentGroup={filteredCompanyDocumentGroup}
          handleClose={handleClose1}
          ReloadData={ReloadData}
        />
      ) : (
        ""
      )}
      {open && typeDialog === 'Category' &&
        <EditCategoryDialog
          open={open}
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          mode={typeDialog}
          data={formData}
          dialogType={dialogType}
          selectedRow={selectedRow}
          setDialogType={setDialogType}
          setSelectedRow={setSelectedRow}
          handleConfirmDelete={handleConfirmDelete}
          handleCloseDialog={handleCloseDialog}
          handleDialog={handleDialog2}
          mergedDocumentGroups={mergedDocumentGroups}
          onClose={handleClose1}
        />
      }
    </div>
  );
};

export default companyDocumentPage
