import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Collapse,
  CircularProgress,
  Box,
  Typography,
  Paper,
  Avatar,
  Grid,
} from "@mui/material";
import { styled } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import Autocomplete from "@mui/material/Autocomplete";
import { useDispatch, useSelector } from "react-redux";
import {
  getOnBoardingEmployee,
  getOnBoardingEmployeeByID,
} from "../../../../../actions/onBoarding";
import More from "./viewmore";
import { useTranslation } from "react-i18next";
import {
  getUserDepartment,
  getUserFullName,
  getUserPosition,
  getUserDivision,
} from "../../../../../utils/userData";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import { getAffiliateOrganization } from "../../../../../actions/company";

import { exportAllToExcel } from "./exportAllToExcel";

import ButtonBlue from "../../../shared/general/ButtonBlue";

const StyledWrapBranch = styled("div")(({ theme }) => ({
  marginBottom: 36,
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  borderRadius: 12,
  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
  backgroundColor: "#ffffff",
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#ffffff",
  fontWeight: 600,
  textAlign: "center",
  borderBottom: "2px solid #e0e0e0",
  padding: "8px 16px",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#ffffff",
  },
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
    cursor: "pointer",
  },
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: 40,
  height: 40,
  marginRight: 8,
}));

const StyledCollapseRow = styled(TableRow)(({ theme }) => ({
  "& > *": {
    borderBottom: "unset",
  },
}));

const StyledBoxSearch = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(2),
}));

const StatusOnBoarding = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { result: onBoardingEmployee } = useSelector(
    (state) => state.onBoardingEmployee
  );
  const { result: onBoardingEmployeeByID } = useSelector(
    (state) => state.onBoardingEmployeeByID
  );
  const { result: affiliateOrganizationList } = useSelector(
    (state) => state.affiliateOrganization
  );

  const [progress, setProgress] = useState({});
  const [openRow, setOpenRow] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [selectedDivision, setSelectedDivision] = useState(null);

  useEffect(() => {
    dispatch(getOnBoardingEmployee());
    dispatch(getAffiliateOrganization());
  }, [dispatch]);

  useEffect(() => {
    if (onBoardingEmployee && onBoardingEmployee.length > 0) {
      const progressInit = {};

      onBoardingEmployee.forEach((row) => {
        progressInit[row.idEmployees] = 0;
        const timer = setInterval(() => {
          setProgress((prev) => {
            const newProgress = { ...prev };
            if (newProgress[row.idEmployees] < row.status) {
              newProgress[row.idEmployees] += 2;
            } else {
              clearInterval(timer);
            }
            return newProgress;
          });
        }, 10);
      });

      setProgress(progressInit);
    }
  }, [onBoardingEmployee]);

  const handleOpenMore = (row) => {
    if (openRow === row) {
      setOpenRow(null);
    } else {
      setOpenRow(row);
      dispatch(getOnBoardingEmployeeByID(row.idEmployees));
    }
  };

  const uniqueDivisions = Array.from(
    new Set(
      onBoardingEmployee
        ? onBoardingEmployee.map((row) => getUserDivision(row))
        : []
    )
  );

  const uniquePositions = Array.from(
    new Set(
      onBoardingEmployee
        ? onBoardingEmployee.map((row) => getUserPosition(row))
        : []
    )
  );

  const filteredEmployees = (onBoardingEmployee || [])
    .filter((row) =>
      getUserFullName(row).toLowerCase().includes(searchTerm.toLowerCase())
    )
    .filter((row) => {
      if (selectedCompany) {
        return row.companyName === selectedCompany.companyName;
      }
      return true;
    })
    .filter((row) => {
      if (selectedDivision) {
        return getUserDivision(row) === selectedDivision;
      }
      return true;
    })
    .filter((row) => {
      if (selectedPosition) {
        return getUserPosition(row) === selectedPosition;
      }
      return true;
    });

  return (
    <StyledWrapBranch>
      {onBoardingEmployee && onBoardingEmployee.length > 0 ? (
        <>
          <Paper
            elevation={0}
            style={{
              padding: 20,
              margin: "20px 0",
              border: "1px solid #e0e0e0",
            }}
          >
            {/* แถวแรก: ช่องค้นหาและบริษัท */}
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} md={6}>
                <StyledBoxSearch sx={{ height: 100 }}>
                  {" "}
                  {/* กำหนดขนาดให้ Box */}
                  <Typography className="label" color="text.third">
                    {t("Search")}
                  </Typography>
                  <TextFieldTheme
                    variant="outlined"
                    fullWidth
                    placeholder={t("SearchFullName")}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </StyledBoxSearch>
              </Grid>

              <Grid item xs={12} md={6}>
                <StyledBoxSearch sx={{ height: 100 }}>
                  {" "}
                  {/* กำหนดขนาดให้ Box */}
                  <Typography className="label" color="text.third">
                    {t("Company")}
                  </Typography>
                  <Autocomplete
                    fullWidth
                    options={affiliateOrganizationList || []}
                    getOptionLabel={(option) => option.companyName}
                    onChange={(event, newValue) => setSelectedCompany(newValue)}
                    renderInput={(params) => (
                      <TextFieldTheme
                        {...params}
                        variant="outlined"
                        placeholder={t("Company")}
                      />
                    )}
                  />
                </StyledBoxSearch>
              </Grid>
            </Grid>

            {/* แถวที่สอง: ช่องฝ่าย, ตำแหน่ง, และปุ่มดาวน์โหลด */}
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={4}>
                <StyledBoxSearch sx={{ height: 100 }}>
                  <Typography className="label" color="text.third">
                    {t("Division")}
                  </Typography>
                  <Autocomplete
                    fullWidth
                    options={uniqueDivisions}
                    getOptionLabel={(option) => option}
                    onChange={(event, newValue) =>
                      setSelectedDivision(newValue)
                    }
                    renderInput={(params) => (
                      <TextFieldTheme
                        {...params}
                        variant="outlined"
                        placeholder={t("Division")}
                      />
                    )}
                  />
                </StyledBoxSearch>
              </Grid>

              <Grid item xs={12} sm={4}>
                <StyledBoxSearch sx={{ height: 100 }}>
                  <Typography className="label" color="text.third">
                    {t("Position")}
                  </Typography>
                  <Autocomplete
                    fullWidth
                    options={uniquePositions}
                    getOptionLabel={(option) => option}
                    onChange={(event, newValue) =>
                      setSelectedPosition(newValue)
                    }
                    renderInput={(params) => (
                      <TextFieldTheme
                        {...params}
                        variant="outlined"
                        placeholder={t("Position")}
                      />
                    )}
                  />
                </StyledBoxSearch>
              </Grid>

              <Grid item xs={12} sm={4}>
                <ButtonBlue
                  size="small"
                  variant="outlined"
                  onClick={() => exportAllToExcel(filteredEmployees)}
                  disabled={filteredEmployees.length === 0}
                >
                  {t("Download")}
                </ButtonBlue>
              </Grid>
            </Grid>
          </Paper>

          <StyledTableContainer component={Paper} elevation={3}>
            <Table stickyHeader>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell>{t("FullName")}</StyledTableCell>
                  <StyledTableCell>{t("Division")}</StyledTableCell>
                  <StyledTableCell>{t("Department")}</StyledTableCell>
                  <StyledTableCell>{t("Section")}</StyledTableCell>
                  <StyledTableCell>{t("Position")}</StyledTableCell>
                  <StyledTableCell>{t("Status")}</StyledTableCell>
                  <StyledTableCell>{t("Descriptions")}</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {filteredEmployees.map((row, index) => (
                  <React.Fragment key={`${row.idEmployees}-${index}`}>
                    <StyledTableRow onClick={() => handleOpenMore(row)}>
                      <TableCell align="center" padding="none">
                        {index + 1}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          width: "200px",
                          maxWidth: "200px",
                          minWidth: "150px",
                        }}
                      >
                        <Box display="flex" alignItems="center">
                          <StyledAvatar
                            alt={row.firstname_TH}
                            src={
                              row.imageProfile
                                ? `data:image/jpeg;base64,${row.imageProfile}`
                                : ""
                            }
                          />
                          <Typography variant="subtitle2">
                            {getUserFullName(row)}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell align="center">
                        {getUserDivision(row)}
                      </TableCell>
                      <TableCell align="center">
                        {getUserDepartment(row)}
                      </TableCell>
                      <TableCell align="center">
                        {row.sectionName || row.sectionName_EN || ""}
                      </TableCell>
                      <TableCell align="center">
                        {getUserPosition(row)}
                      </TableCell>
                      <TableCell align="center">
                        <Box position="relative" display="inline-flex">
                          <CircularProgress
                            variant="determinate"
                            value={progress[row.idEmployees] || 0}
                            size={50}
                            thickness={4}
                            style={{
                              color:
                                progress[row.idEmployees] > 51
                                  ? "#4caf50"
                                  : "#f44336",
                            }}
                          />
                          <Box
                            top={0}
                            left={0}
                            bottom={0}
                            right={0}
                            position="absolute"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Typography
                              variant="caption"
                              component="div"
                              color="textSecondary"
                              fontSize="0.8rem"
                            >
                              {`${Math.round(progress[row.idEmployees] || 0)}%`}
                            </Typography>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell align="center">
                        {openRow === row ? (
                          <ExpandLessIcon />
                        ) : (
                          <ExpandMoreIcon />
                        )}
                      </TableCell>
                    </StyledTableRow>
                    <StyledCollapseRow>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={8}
                      >
                        <Collapse
                          in={openRow === row}
                          timeout="auto"
                          unmountOnExit
                        >
                          <More data={onBoardingEmployeeByID} />
                        </Collapse>
                      </TableCell>
                    </StyledCollapseRow>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </StyledTableContainer>
        </>
      ) : (
        <Typography variant="body1" color="textSecondary">
          {t("NoItems")}
        </Typography>
      )}
    </StyledWrapBranch>
  );
};

export default StatusOnBoarding;
