import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  Chip,
  Container,
  Grid,
  IconButton,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import CardStyle from "../../../../../shared/general/Card";
import Loading from "../../../../../shared/loading";
import { useDispatch, useSelector } from "react-redux";
import { getTeamObjectiveByIdTeamObjective } from "../../../../../../../actions/okrs";
import dayjs from "dayjs";
import EditIcon from "@mui/icons-material/Edit";
import AssessmentIcon from "@mui/icons-material/Assessment";
import { useTranslation } from "react-i18next";
import StatusSuccess from "../../Organization/Information/statusSuccess";
import RatingChip from "../../Organization/Information/ratingChip";
import { useParams } from "react-router-dom";
import EvaluationObjective from "./evaluationObjective";
import KeyResult from "./keyResult";
import DialogEditObjective from "./editObjective";

const StyledRoot = styled(Box)({
  "& .objective-information-container": {
    padding: "16px",
    "& .objective-name": {
      fontSize: "20px",
      fontWeight: "500",
    },
    "& .objective-description": {
      paddingTop: "16px",
      fontSize: "14px",
    },
    "& .impact-header": {
      paddingRight: "8px",
      color: "#919eab",
    },
  },
  "& .key-result-topic-container": {
    margin: "16px 0",
    padding: "0 16px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .MuiTypography-root": {
      fontSize: "18px",
    },
  },
  "& .key-result-wrap": {
    // marginBottom: "24px",
    height: "100%",
  },
  "& .key-result-wrap:last-child": {
    marginBottom: "0",
  },
  "& .key-result-list-container": {
    "& .key-result-item-container": {
      padding: "16px",
      "& .key-result-name": {
        flexGrow: 1,
        fontWeight: "500",
      },
      "& .key-result-description": {
        paddingTop: "16px",
        fontSize: "14px",
      },
      "& .due-date-container": {
        paddingTop: "16px",
        display: "flex",
        alignItems: "center",
        color: "#919eab",
        fontSize: "14px",
        "& .MuiSvgIcon-root": {
          marginRight: "8px",
        },
        "& .MuiTypography-root, & .MuiSvgIcon-root": {
          fontSize: "inherit",
          color: "inherit",
        },
      },
      "& .MuiDivider-root": {
        paddingTop: "16px",
      },
      "& .manager-label": {
        paddingRight: "8px",
        color: "#919eab",
      },
    },
  },
});

const TeamInformationPage = () => {
  const { idTeamObjective } = useParams();
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const {
    result: teamObjective,
    isFetching,
    isError,
  } = useSelector((state) => state.teamObjective);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const isManager = userProfile && userProfile.roles.includes("ROLE_MANAGER");

  const [openDialog, setOpenDialog] = useState(false);
  const [openEvaluation, setOpenEvaluation] = useState(false);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleClickOpenEvaluation = () => {
    setOpenEvaluation(true);
  };

  const handleCloseEvaluation = () => {
    setOpenEvaluation(false);
  };

  useEffect(() => {
    dispatch(getTeamObjectiveByIdTeamObjective(idTeamObjective));
  }, []);

  if (isError) {
    return (
      <StyledRoot className="page">
        <Container maxWidth="lg">
          <Typography
            textAlign="center"
            fontSize="32px"
            paddingTop="24px"
          >{`${t("NoData")}`}</Typography>
        </Container>
      </StyledRoot>
    );
  }

  if (isFetching) {
    return (
      <StyledRoot className="page">
        <Container maxWidth="lg">
          <Box display="flex" justifyContent="center" paddingTop="24px">
            <Loading />
          </Box>
        </Container>
      </StyledRoot>
    );
  }

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg" sx={{ marginTop: 3 }}>
        {teamObjective && (
          <Fragment>
            <CardStyle style={{ backgroundColor: "white" }}>
              <Box padding="24px">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "8px",
                  }}
                >
                  <Chip
                    label={`Objective ${dayjs()
                      .set("year", teamObjective.year)
                      .format(
                        i18n.resolvedLanguage === "th" ? "BBBB" : "YYYY"
                      )}, Q${teamObjective.quarter}`}
                    sx={{
                      border: "2px solid #DB4178",
                      color: "#DB4178",
                      bgcolor: "transparent",
                      fontSize: "20px",
                    }}
                  />
                  <Box display="flex" alignItems="center" gap="8px">
                    {isManager && (
                      <IconButton onClick={handleClickOpen}>
                        <EditIcon />
                      </IconButton>
                    )}
                    {isManager &&
                      dayjs().isAfter(
                        dayjs(teamObjective.createDate).endOf("quarter")
                      ) && (
                        <IconButton onClick={handleClickOpenEvaluation}>
                          <AssessmentIcon />
                        </IconButton>
                      )}
                    {teamObjective.isSuccess !== null && (
                      <StatusSuccess status={teamObjective.isSuccess} />
                    )}
                  </Box>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Stack padding="8px">
                    <Typography fontSize="24px" fontWeight={500}>
                      {teamObjective.objectiveName}
                    </Typography>
                    {teamObjective.description && (
                      <Typography
                        fontSize="16px"
                        paddingTop="16px"
                        whiteSpace="pre-line"
                      >
                        <span style={{ paddingRight: "8px" }}>{`${t(
                          "Descriptions"
                        )}`}</span>
                        {teamObjective.description}
                      </Typography>
                    )}
                    <Typography fontSize="16px">
                      <span style={{ paddingRight: "8px" }}>{`${t(
                        "TheResultingOutcome"
                      )}`}</span>
                      {teamObjective.impactName}
                    </Typography>
                  </Stack>
                  {teamObjective.rating !== null && (
                    <div style={{ height: "60px" }}>
                      <RatingChip rating={teamObjective.rating} />
                    </div>
                  )}
                </div>
              </Box>
            </CardStyle>
            <Box
              className="key-result-topic-container"
              style={{ marginTop: "30px" }}
            >
              <Typography>Key Result</Typography>
              <Typography>
                {teamObjective.keyResults.length} {`${t("List")}`}
              </Typography>
            </Box>
            <CardStyle style={{ backgroundColor: "white" }}>
              {teamObjective.keyResults.map((keyResult, index) => (
                <Grid key={index} item xs={12} md={4}>
                  <Box className="key-result-wrap">
                    <KeyResult data={keyResult} />
                  </Box>
                </Grid>
              ))}
            </CardStyle>
            {openDialog && (
              <DialogEditObjective
                open={openDialog}
                handleClose={handleClose}
                data={{
                  objectiveName: teamObjective.objectiveName,
                  impactName: teamObjective.impactName,
                }}
              />
            )}
            {openEvaluation && (
              <EvaluationObjective
                open={openEvaluation}
                handleClose={handleCloseEvaluation}
                data={teamObjective}
              />
            )}
          </Fragment>
        )}
      </Container>
    </StyledRoot>
  );
};

export default TeamInformationPage;
