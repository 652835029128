import React, { useState, useEffect, useRef } from "react";
import { Loader } from "@googlemaps/js-api-loader";
import { styled } from "@mui/material/styles";
import {
    Box,
    Divider,
    Grid,
    IconButton,
    InputBase,
    Paper,
} from "@mui/material";
import "../../styles.css";

import ButtonBlue from "../../../../../shared/general/ButtonBlue";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { useTranslation } from "react-i18next";
const mapStyles = {
    position: "relative",
    width: "100%",
    height: "350px",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
};

const resetContainer = {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: "10px",
};

const StylePaper = styled(Paper)({
    display: "flex",
    alignItems: "center",
    width: "100%",
    borderRadius: 8,
    marginBottom: 16,
    boxShadow:
        "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
});

const StyleWrapButton = styled("div")({
    marginTop: 8,
    width: "100%",
    display: "flex",
    justifyContent: "center",
});

const InitialValues = () => ({
    locationId: 0,
    locationName: "",
    location: [],
});

function EditAndPositionMap(props) {
    const { t } = useTranslation();
    const { radius, selectedGroup, handleChangePositions } = props;
    const [search, setSearch] = useState("");
    const [values, setValues] = useState(InitialValues);

    let map, marker, circle;

    const selectedPosition = selectedGroup && selectedGroup.positions && selectedGroup.positions.length > 0 && selectedGroup.positions[0];

    const onMapLoad = () => {
        const google = window.google;

        if (selectedGroup && selectedGroup.idGpsType === 2) {
            const pos = {
                lat: selectedGroup.lat,
                lng: selectedGroup.lng,
            };

            const myLatlng = new google.maps.LatLng(pos.lat, pos.lng);
            const myOptions = {
                zoom: 16,
                center: myLatlng,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
            };

            map = new google.maps.Map(document.getElementById("editMap"), myOptions);

            setMapLocation(myLatlng);
        } else {
            if (selectedPosition && selectedPosition.lat && selectedPosition.lng) {
                const pos = {
                    lat: selectedPosition.lat,
                    lng: selectedPosition.lng,
                };

                const myLatlng = new google.maps.LatLng(pos.lat, pos.lng);
                const myOptions = {
                    zoom: 16,
                    center: myLatlng,
                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                };

                map = new google.maps.Map(document.getElementById("editMap"), myOptions);

                setMapLocation(myLatlng);
            }
        };

        onSearchBox();
        onMapClick();
    };

    const onSearchBox = () => {
        const google = window.google;

        const input = document.getElementById("pac-input");
        const searchBox = new google.maps.places.SearchBox(input);

        searchBox.addListener("places_changed", () => {
            const places = searchBox.getPlaces();
            if (places.length === 0) return;

            const place = places[0];
            if (place.geometry && place.geometry.location) {
                setMapLocation(place.geometry.location);
            }
        });
    };

    const onMapClick = () => {
        const google = window.google;

        map.addListener("click", (e) => {
            const location = e.latLng;

            setMapLocation(location);
        });
    };

    const setMapLocation = (location) => {
        const google = window.google;

        if (marker) marker.setMap(null);
        if (circle) circle.setMap(null);

        if (google.maps.marker && google.maps.marker.AdvancedMarkerElement) {
            marker = new google.maps.marker.AdvancedMarkerElement({
                position: location,
                map: map,
            });
        } else {
            marker = new google.maps.Marker({
                position: location,
                map: map,
            });
        };

        circle = new google.maps.Circle({
            strokeColor: "#FF0000",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#FF0000",
            fillOpacity: 0.35,
            map: map,
            center: location,
            radius: radius,
        });

        map.setCenter(location);

        handleChangePositions([{
            lat: location.lat(),
            lng: location.lng(),
            idGroupGpsLocations: selectedGroup.idGroupGpsLocations
        }]);
    };

    const handleOnReset = () => {
        initialize();
        if (marker) marker.setMap(null);
        if (circle) circle.setMap(null);
        setValues({ ...values, location: [] });
    };

    const handleClearInput = () => {
        document.getElementById("pac-input").value = "";
        setSearch("");
    };

    const loader = new Loader({
        apiKey: "AIzaSyBJEmVrMq5Zgj27dilri-OK76pn7pgQCho",
        version: "weekly",
        libraries: ["places", "drawing", "geometry"],
    });

    const initialize = () => {
        loader.load().then(() => {
            onMapLoad();
        });
    };

    useEffect(() => {
        if (selectedGroup && selectedGroup.positions) {
            initialize();
        }
    }, [selectedGroup]);

    return (
        <>
            <div>
                <StylePaper>
                    <InputBase
                        style={{ ml: 1, flex: 1 }}
                        id="pac-input"
                        class="controls"
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder={t("SearchLocation")}
                        inputProps={{ "aria-label": t("SearchLocation") }}
                    />
                    <IconButton style={{ padding: "10px" }} aria-label="search">
                        <SearchIcon />
                    </IconButton>
                    {search.length > 0 ? (
                        <>
                            <Divider
                                style={{ height: 28, margin: 0.5 }}
                                orientation="vertical"
                            />
                            <IconButton
                                style={{ padding: "10px" }}
                                aria-label="directions"
                                onClick={handleClearInput}
                            >
                                <ClearIcon />
                            </IconButton>
                        </>
                    ) : null}
                </StylePaper>
            </div>
            <Box>
                <div id="editMap" style={mapStyles}>
                    Loading ...
                </div>
            </Box>
            <StyleWrapButton>
                <ButtonBlue startIcon={<RestartAltIcon />} onClick={handleOnReset}>
                    Reset
                </ButtonBlue>
            </StyleWrapButton>
        </>
    );
}

export default EditAndPositionMap;