import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Button,
  Container,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
} from "@mui/material";
import Swal from 'sweetalert2';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import CheckIcon from '@mui/icons-material/Check';
import LockIcon from '@mui/icons-material/Lock';
import CardStyle from "../../shared/general/Card";
import ButtonBlue from "../../shared/general/ButtonBlue";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import {
  getDocumentGroupName,
  getDocumentTypeName,
  deleteEmployeeDocumentInfo,
} from "../../../../actions/document";
import DialogType from "./dialogType";
//Translator TH-EN
import { useTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';
import LoadingIcon from '../../assets/social-media.gif';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "120px",
    marginBottom: "120px",
  },
  iconButton: {
    transition: 'transform 0.3s ease',
    color: '#000000',
    '&:hover': {
      transform: 'scale(1.0)',
      color: '#3f51b5',
    },
  },
  cancelButton: {
    color: 'red',
    border: '1px solid red',
    borderRadius: '5px',
    padding: '10px 20px',
    fontSize: '16px',
    fontWeight: 'bold',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    '&:hover': {
      backgroundColor: 'rgba(255, 0, 0, 0.1)',
    },
  },
  confirmButton: {
    color: 'green',
    border: '1px solid green',
    borderRadius: '5px',
    padding: '10px 20px',
    fontSize: '16px',
    fontWeight: 'bold',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    '&:hover': {
      backgroundColor: 'rgba(0, 255, 0, 0.1)',
    },
  },
  dialogBackground: {
    '& .MuiDialog-paper': {
      backgroundColor: '##BEBEBE',
      borderRadius: '10px',
      maxWidth: '450px',
      height: '26vh',
      maxHeight: '700px',
    },
    '& .css-ut4szi': {
      fontSize: '24px',
      width: '90vw',
      height: '90vh',
    },
    '& .MuiDialog-container': {
      '& .MuiPaper-root': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: 'none',
        border: '1px solid black',
        borderRadius: '10px',
        background: 'linear-gradient(to bottom, #FFFFFF, #F0F8FF)',
      },
    },
    '& .MuiBackdrop-root': {
      backdropFilter: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      maxWidth: '40vw',
      maxHeight: '60vh',
      borderRadius: '10px',
    },
    '& .MuiBackdrop-root.MuiModal-backdrop': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      maxWidth: '0vw',
      maxHeight: '0vh',
      borderRadius: '10px',
    },
  },
  pageTitle: {
    color: "#16243D",
    fontSize: "1.5rem",
    fontWeight: 600,
  },
  paper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(4),
    borderRadius: "20px",
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  description: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    marginBottom: theme.spacing(3),
  },
  button_container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  button: {
    borderColor: "#007afe",
    color: "#ffffff",
    backgroundColor: "#007afe",
    borderRadius: 8,
    "&:hover": {
      borderColor: "#0046b7",
      backgroundColor: "#0046b7",
    },
  },
  iconButton: {
    transition: 'transform 0.3s ease',
    color: '#000000',
    '&:hover': {
      transform: 'scale(1.2)',
      color: '#3f51b5',
    },
  },
  iconButton: {
    transition: 'transform 0.3s ease',
    color: '#000000',
    '&:hover': {
      transform: 'scale(1.2)',
      color: '#00CC00',
    },
  },
  iconButtonDelete: {
    transition: 'transform 0.3s ease',
    color: '#000000',
    '&:hover': {
      transform: 'scale(1.2)',
      color: '#FF0000',
    },
  },
}));

const StyledRoot = styled(Box)({
  backgroundColor: "#FFFFFF !important",
});
const StyledBox = styled(Box)({
  "&.open": {
    paddingLeft: 16,
    width: "calc(100% - 1000px)",
  },
  "&.close": {
    paddingLeft: 0,
    width: "100%",
  },
});
const StyledCellHeader = styled(TableCell)({
  borderBottom: "1px dashed rgba(224, 224, 224, 1)",
  color: "#637381",
  backgroundColor: "#f4f6f8",
  "& .MuiTypography-root": {
    color: "#637381",
    backgroundColor: "#f4f6f8",
    "&.weekend": {
      fontStyle: "oblique",
    },
    "&.workday": {
      fontWeight: 600,
    },
  },
  "&.sticky": {
    padding: 0,
    position: "sticky",
    left: 0,
    zIndex: 4,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    boxShadow: "20px 0px 20px #EEEEEE",
    "& .MuiTableCell-root": {
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
    },
  },
  "&:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
});

const StyledRowContent = styled(TableRow)({
  cursor: "pointer",
  textDecoration: "none",
  "&.MuiTableRow-hover:hover": {
    backgroundColor: "#f7f7f7",
    "& .sticky": {
      backgroundColor: "#f7f7f7",
    },
  },
});

const StyledCellContent = styled(TableCell)(({ theme }) => ({
  borderBottom: "opx",
  padding: 8,
  "&.sticky": {
    position: "sticky",
    left: 0,
    backgroundColor: "#FFFFFF",
    boxShadow: "8px 20px 20px #EEEEEE",
  },
  "& .avatar": {
    minWidth: 240,
    "& .MuiAvatar-root": {
      marginRight: 8,
    },
    display: "flex",
    alignItems: "center",
  },
  "& .fullname": {
    fontWeight: 600,
  },
  "& .position": {
    color: theme.palette.text.secondary,
    fontSize: 14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: 300,
  },
  "& .secondaryAction": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& .location": {
      fontWeight: 600,
    },
  },
  "&:first-of-type": {
    paddingLeft: 0,
  },
  "&:last-of-type": {
    paddingRight: 24,
  },
}));

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  marginTop: 24,
  "& .MuiToggleButtonGroup-grouped": {
    margin: theme.spacing(0.5),
    color: "#46cbe2",
    border: "1px solid #46cbe2",
    backgroundColor: "#FFFFFF",
    "&:hover": {
      borderColor: "#247785",
      backgroundColor: "#247785",
      color: "#FFFFFF",
    },
    "&.Mui-selected": {
      color: "#FFFFFF",
      backgroundColor: "#46cbe2",
      "&:hover": {
        borderColor: "#247785",
        backgroundColor: "#247785",
        color: "#FFFFFF",
      },
    },
    "&.Mui-disabled": {
      border: 0,
    },
    "&:not(:first-of-type)": {
      borderRadius: 8,
      borderLeft: "1px solid #46cbe2",
    },
    "&:first-of-type": {
      borderRadius: 8,
    },
  },
}));

const StyledBoxSearch = styled(Box)({
  marginTop: 22,
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const DialogNewDocument = () => {
  const { idCompany } = useParams();
  console.log('idCompany', idCompany);

  const classes = useStyles();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [view, setView] = useState(1);
  const [open, setOpen] = useState(false);
  const [typeDialog, setTypeDialog] = useState(null);
  const { result: docGroup } = useSelector((state) => state.documentGroup);
  console.log('xxx docGroup', docGroup);
  const { result: docNameItem } = useSelector((state) => state.documentItem);
  console.log('xxx docName : ', docNameItem);
  const [doc, setDoc] = useState({ idDocumentGroup: 1, idDocumentType: 1, documentTypeName: 'default' });
  const [idEmployees, setIdEmployees] = React.useState(1);
  const [idDocumentGroup, setIdDocumentGroup] = useState(1);
  const [formData, setFormData] = useState(null);
  const [docName, setDocName] = useState();

  const [openDialog, setOpenDialog] = useState(false);
  const [reload, setReload] = useState();
  console.log('reload', reload);

  useEffect(() => {
    if (docNameItem) {
      setDocName(docNameItem);
    }
  }, [docNameItem]);

  useEffect(() => {
    dispatch(getDocumentGroupName());
    if (idCompany) {
      dispatch(getDocumentTypeName(idDocumentGroup, idEmployees, doc.idDocumentType, doc.documentTypeName, idCompany));
    }
    handleClose();
  }, [idDocumentGroup]);

  useEffect(() => {
    if (idCompany) {
      dispatch(getDocumentTypeName(idDocumentGroup, idEmployees, doc.idDocumentType, doc.documentTypeName, idCompany));
    }
  }, [reload]);

  const handleChangeView = (event, newView) => {
    if (newView) {
      setView(newView);
      setIdDocumentGroup(newView);
    }
  };

  const handleDialog = (type, formData, d) => {
    setTypeDialog(type);
    if (type === 'delete') {
      setOpenDialog(true);
    } else {
      setOpen(true);
    }
    if (formData) {
      const updatedFormData = {
        ...formData,
        idCompany: idCompany
      };
      setFormData(updatedFormData);
    }
  };

  const handleClose = () => {
    setTypeDialog(null);
    setOpen(false);
    setReload(reload === 1 ? 2 : 1);
    setFormData(null);
  };

  const handleCloseDialog = () => {
    setTypeDialog(null);
    setOpen(false);
    setFormData(null);
  };

  const [loading, setLoading] = useState(false);

  const [buttonStates, setButtonStates] = useState({});

  const handleButtonClick = (row) => {
    setButtonStates((prevState) => ({
      // ...prevState,
      idCompany: idCompany,
      idDocumentType: row.idDocumentType,
      idDocumentGroup: row.idDocumentGroup,
      documentTypeName: row.documentTypeName,
      isActivate: !row.isActivate,
    }));
  };

  useEffect(() => {
    if (
      buttonStates.idCompany !== undefined &&
      buttonStates.idDocumentType !== undefined &&
      buttonStates.idDocumentGroup !== undefined &&
      buttonStates.documentTypeName !== undefined &&
      buttonStates.isActivate !== undefined
    ) {
      handleDialog('activate', buttonStates);
    }
  }, [buttonStates]);

  const handleFileClose = () => {
    if (reload === 1) setReload(2);
    else setReload(1);
  };

  const handleConfirmDelete = async (type, formData) => {
    console.log('type asxsds', formData);
    console.log('type ffff', type);

    setOpenDialog(false);

    try {
      let result;
      console.log(`Switching case: ${type}`);

      setLoading(true);
      switch (type) {
        case 'deleteEmDocInfo':
          result = await dispatch(deleteEmployeeDocumentInfo(formData));
          if (result) {
            setLoading(false);
          }
          break;
        default:
          console.log('Unknown delete type');
          throw new Error('Unknown delete type');
      }
      if (result) {
        Swal.fire({
          icon: 'success',
          title: 'ลบข้อมูลสำเร็จ!!',
          showConfirmButton: false,
          timer: 1500,
        });
        handleFileClose();
      } else {
        Swal.fire({
          icon: 'error',
          title: 'ไม่สามารถลบข้อมูลได้!!',
          text: 'กรุณาลองใหม่อีกครั้ง',
          confirmButtonText: 'OK',
        });
      }
    } catch (error) {
      console.error('Error deleting document:', error);
      Swal.fire({
        icon: 'error',
        title: 'เกิดข้อผิดพลาดในการลบข้อมูล!!',
        text: 'กรุณาลองใหม่อีกครั้ง',
        confirmButtonText: 'OK',
      });
    } finally {
      handleCloseDialog(); // ปิดไดอะล็อกสุดท้ายเสมอ
    }
  };

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <div
          style={{
            marginBottom: 8,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            className="wrap-search-action"
            style={{ display: "flex", justifyContent: "flex-start" }}
          >
            <Typography variant="h4" style={{ paddingTop: 8, userSelect: 'none' }}>
              {`${t("EditDocumentName")}`}
            </Typography>
          </div>
          <StyledBoxSearch>
            <div
              className="wrap-search-action"
              style={{ justifyContent: "flex-end" }}
            >
              <ButtonBlue
                variant="contained"
                startIcon={<FileUploadOutlinedIcon />}
                onClick={() => handleDialog("Add")}
              >
                {`${t("AddDocumentName")}`}
              </ButtonBlue>
            </div>
          </StyledBoxSearch>
        </div>
        <StyledToggleButtonGroup
          value={view}
          exclusive
          onChange={handleChangeView}
          aria-label="view"
          size="small"
        >
          {docGroup &&
            docGroup.map((v, index) => {
              return (
                <ToggleButton
                  name={index}
                  value={v.idDocumentGroup}
                  size="small"
                  style={{ minWidth: 150, marginRight: 12 }}
                >
                  {v.documentGroupName}
                </ToggleButton>
              );
            })}
        </StyledToggleButtonGroup>

        <StyledRoot>
          <Container maxWidth="lg">
            <CardStyle style={{ marginTop: 8 }}>
              <Box style={{ padding: "24px" }}>
                <Box style={{ display: "flex", position: "relative" }}>
                  <StyledBox className={"close"}>
                    <TableContainer style={{ width: "100%", maxHeight: 600, userSelect: 'none' }}>
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            <StyledCellHeader
                              align={"center"}
                              className="sticky"
                              style={{ minWidth: 240 }}
                            >
                              {`${t("DocumentName")}`}
                            </StyledCellHeader>
                            <StyledCellHeader>
                              <Typography variant="body2" align="center">
                                {`${t("DisplayHomepage")}`}
                              </Typography>
                            </StyledCellHeader>
                            <StyledCellHeader>
                              <Typography variant="body2" align="center">
                                {`${t("Descriptions")}`}
                              </Typography>
                            </StyledCellHeader>
                            <StyledCellHeader
                              align={"center"}
                              className="sticky"
                              style={{ minWidth: 10, maxWidth: '10px' }}
                            >
                              <Typography variant="body2" align="center">
                                {`${t("Activate")}`}
                              </Typography>
                            </StyledCellHeader>
                            <StyledCellHeader
                              align={"center"}
                              className="sticky"
                              style={{ minWidth: 20, maxWidth: '40px' }}
                            >
                              <Typography variant="body2" align="center">
                                {`${t("EditData")}`}
                              </Typography>
                            </StyledCellHeader>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {docName && docName != ''
                            ? docName.map((row, index) => (
                              <StyledRowContent>
                                <StyledCellContent
                                  key={`${index}`}
                                  className="sticky"
                                  style={{
                                    borderBottom:
                                      "1px dashed rgba(224, 224, 224, 1)",
                                  }}
                                >
                                  <Typography className="fullname">
                                    {row.documentTypeName}
                                  </Typography>
                                </StyledCellContent>
                                <StyledCellContent
                                  className="sticky"
                                  style={{
                                    borderBottom:
                                      "1px dashed rgba(224, 224, 224, 1)",
                                  }}
                                >
                                  <div className="flex">
                                    {row.isShow === 1 ? (
                                      <Typography
                                        style={{ padding: 2 }}
                                        variant="body2"
                                        align="center"
                                      >
                                        {`${t("Display")}`}
                                      </Typography>
                                    ) : (
                                      <Typography
                                        style={{ padding: 2 }}
                                        variant="body2"
                                        align="center"
                                      >
                                        {`${t("NotDisplayed")}`}
                                      </Typography>
                                    )}
                                  </div>
                                </StyledCellContent>
                                <StyledCellContent
                                  key={`${index}detail`}
                                  className="sticky"
                                  style={{
                                    borderBottom:
                                      "1px dashed rgba(224, 224, 224, 1)",
                                  }}
                                >
                                  <Typography
                                    className="fullname"
                                    align="center"
                                  >
                                    {row.detail}
                                  </Typography>
                                </StyledCellContent>

                                <StyledCellContent
                                  key={`${index}edit`}
                                  className="sticky"
                                  style={{
                                    borderBottom:
                                      "1px dashed rgba(224, 224, 224, 1)",
                                  }}
                                  align="center"
                                >
                                  {row.idDocumentType == 1 ||
                                    row.idDocumentType == 2 ||
                                    row.idDocumentType == 3 ||
                                    row.idDocumentType == 4 ||
                                    row.idDocumentType == 5 ? (
                                    "-"
                                  ) : (
                                    <Button
                                      variant="contained"
                                      onClick={() => {
                                        handleButtonClick(row);
                                        handleDialog("activate", buttonStates);
                                      }}
                                      style={{
                                        backgroundColor: row.isActivate === 0
                                          ? '#BEBEBE'
                                          : '#87CEFA',
                                        color: '#FFFFFF',
                                      }}
                                    >
                                      {(row.isActivate === 0 ? (
                                        <LockIcon />
                                      ) : (
                                        <CheckIcon />
                                      )
                                      )}
                                    </Button>
                                  )}
                                </StyledCellContent>

                                <StyledCellContent
                                  key={`${index}edit`}
                                  className="sticky"
                                  style={{
                                    borderBottom:
                                      "1px dashed rgba(224, 224, 224, 1)",
                                  }}
                                  align="center"
                                >
                                  {row.idDocumentType == 1 ||
                                    row.idDocumentType == 2 ||
                                    row.idDocumentType == 3 ||
                                    row.idDocumentType == 4 ||
                                    row.idDocumentType == 5 ? (
                                    // "-"
                                    <>
                                      <IconButton
                                        style={{ color: "#778899" }}
                                        onClick={() =>
                                          handleDialog("editImDoc", row)
                                        }
                                        className={classes.iconButton}
                                      >
                                        <DriveFileRenameOutlineIcon style={{ fontSize: "27px" }} />
                                      </IconButton>
                                      <IconButton
                                        style={{
                                          borderRadius: "50%",
                                          padding: "10px",
                                          color: "#DCDCDC",
                                          pointerEvents: "none"
                                        }}
                                        disabled={true}
                                      >
                                        <HorizontalRuleIcon />
                                      </IconButton>
                                    </>
                                  ) : (
                                    <>
                                      <IconButton
                                        style={{ color: "#778899" }}
                                        onClick={() =>
                                          handleDialog("edit", row)
                                        }
                                        className={classes.iconButton}
                                      >
                                        <DriveFileRenameOutlineIcon style={{ fontSize: "27px" }} />
                                      </IconButton>
                                      <IconButton
                                        style={{
                                          backgroundColor: "#fce4ec",
                                          borderRadius: "50%",
                                          padding: "10px",
                                          color: "rgba(255, 23, 68, 0.9)",
                                        }}
                                        onClick={() => handleDialog("delete", row)}
                                      >
                                        <DeleteOutlineIcon />
                                      </IconButton>
                                      {typeDialog === "delete" && (
                                        <Dialog
                                          open={openDialog}
                                          onClose={handleCloseDialog}
                                          className={classes.dialogBackground}
                                        >
                                          {loading ? (
                                            <img width="80" alt="loading" src={LoadingIcon} />
                                          ) : (
                                            <>
                                              <DialogTitle >{t("Confirmdelete")} {formData.documentTypeName}</DialogTitle>
                                              <DialogContent>
                                                {t("DeleteEmployeeDocRow")}
                                              </DialogContent>
                                              <DialogActions>
                                                <IconButton
                                                  onClick={handleCloseDialog}
                                                  className={classes.cancelButton}
                                                >
                                                  {t("Cancel")}
                                                </IconButton>
                                                <IconButton
                                                  onClick={() => {
                                                    handleConfirmDelete('deleteEmDocInfo', formData);
                                                  }}
                                                  className={classes.confirmButton}
                                                >
                                                  {t("Confirm")}
                                                </IconButton>
                                              </DialogActions>
                                            </>
                                          )}
                                        </Dialog>
                                      )}
                                    </>
                                  )}
                                </StyledCellContent>
                              </StyledRowContent>
                            ))
                            : `${t("NoData")}`}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </StyledBox>
                </Box>
              </Box>
            </CardStyle>
          </Container>
        </StyledRoot>
      </Container>
      {open && typeDialog !== 'delete' ? (
        <DialogType
          companyProfile={idCompany}
          open={open}
          idCompany={idCompany}
          mode={typeDialog}
          data={formData}
          buttonStates={buttonStates}
          handleClose={handleClose}
          handleCloseDialog={handleCloseDialog}
        />
      ) : (
        ""
      )}
    </StyledRoot>
  );
};

export default DialogNewDocument;
