import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  Container,
  IconButton,
  Typography,
  styled,
  Button,
  Slider,
  LinearProgress,
} from "@mui/material";
import CardStyle from "../../../../../shared/general/Card";
import { AccessTimeRounded } from "@mui/icons-material";
import TextFieldTheme from "../../../../../shared/general/TextFieldTheme";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import {
  addIndividualKeyResultProgress,
  getAllIndividualKeyResultProgress,
  updateIndividualKeyResult,
  updateIndividualKeyResultProgress,
} from "../../../../../../../actions/okrs";
import { Controller, useForm } from "react-hook-form";
import Edit from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import { useTranslation } from "react-i18next";

const PrettoSlider = styled(Slider)({
  color: "rgba(219, 65, 120, 1)",
  height: 8,
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    "&:hover": {
      boxShadow: "0 0 0 8px rgba(219, 65, 120, 0.16)",
    },
    "&:focus, &.Mui-active": {
      boxShadow: "0 0 0 14px rgba(219, 65, 120, 0.16)",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: "rgba(219, 65, 120, 1)",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&:before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
});

const ResponsiveButtonBlue = styled(Button)(({ theme }) => ({
  backgroundColor: "rgba(219, 65, 120, 1)",
  width: "10%",
  height: "50px",
  fontSize: "15px",
  fontWeight: "bold",
  borderRadius: "7px",
  "&:hover": {
    backgroundColor: "#C53B6C",
  },
  [theme.breakpoints.down("sm")]: {
    width: "10%",
    fontSize: "14px",
    height: "40px",
  },
}));

const UpdateProgressIndividualKeyResult = (props) => {
  const { match } = props;
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();

  const [IndividualKeyResult, setIndividualKeyResult] = useState(null);
  const [progressPercent, setProgressPercent] = useState(0);

  const useHookForm = useForm({
    defaultValues: {
      message: "",
    },
  });

  const getValueStatus = (status) => {
    switch (status) {
      case null:
        return "";
      case 1:
        return "1";
      case 0:
        return "0";
    }
  };

  const onSubmit = async (formData) => {
    const dataToSend = { ...formData, progressPercent };

    const response = await dispatch(
      addIndividualKeyResultProgress(
        match.params.idIndividualKeyResult,
        dataToSend
      )
    );

    if (response) {
      if (response.status === 200) {
        setIndividualKeyResult((prev) => ({
          ...prev,
          individualKeyResultProgress: [
            {
              idIndividualKeyResultProgress:
                response.data.idIndividualKeyResultProgress,
              message: response.data.message,
              createDate: response.data.createDate,
              progressPercent: response.data.progressPercent,
            },
            ...prev.individualKeyResultProgress,
          ],
        }));
        useHookForm.setValue("message", "");
        setProgressPercent(response.data.progressPercent || 0);
      }
    }
  };

  const handleUpdateKeyResultProgress = async (
    idIndividualKeyResultProgress,
    formData
  ) => {
    const responseUpdate = await dispatch(
      updateIndividualKeyResultProgress(idIndividualKeyResultProgress, formData)
    );
    if (responseUpdate) {
      const res = await dispatch(
        getAllIndividualKeyResultProgress(match.params.idIndividualKeyResult)
      );
      if (res.status === 200) {
        setIndividualKeyResult(res.data);
      }
    }
  };

  useEffect(() => {
    let isMounted = true;
    dispatch(
      getAllIndividualKeyResultProgress(match.params.idIndividualKeyResult)
    ).then((res) => {
      if (isMounted) {
        if (res.status === 200) {
          setIndividualKeyResult(res.data);
        }
      }
    });

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <div className="page">
      <Box padding="24px 0">
        <Container maxWidth="lg">
          {IndividualKeyResult && (
            <Fragment>
              <CardStyle>
                <Box padding="24px">
                  <Typography flexGrow="1" fontSize="18px" fontWeight="500">
                    {IndividualKeyResult.keyResultName}
                  </Typography>
                  <Box display="flex" alignItems="center" marginTop="8px">
                    <AccessTimeRounded
                      style={{ marginRight: "8px", fontSize: "16px" }}
                    />
                    <Typography fontSize="14px" lineHeight="1">
                      {dayjs(IndividualKeyResult.startDate).format(
                        "D MMM BBBB"
                      )}{" "}
                      -{" "}
                      {dayjs(IndividualKeyResult.endDate).format("D MMM BBBB")}
                    </Typography>
                  </Box>
                  <Box marginTop="16px">
                    <Typography variant="body2" color="text.secondary">
                      {`Progress: ${IndividualKeyResult.progressPercent}%`}
                    </Typography>
                    <LinearProgress
                      variant="determinate"
                      value={IndividualKeyResult.progressPercent}
                      sx={{
                        height: 10,
                        borderRadius: 5,
                        backgroundColor: "rgba(219, 65, 120, 0.1)",
                        "& .MuiLinearProgress-bar": {
                          backgroundColor: "rgba(219, 65, 120, 1)",
                        },
                      }}
                    />
                  </Box>
                </Box>
              </CardStyle>

              <Box marginTop="24px">
                <CardStyle>
                  <Box padding="24px">
                    <form onSubmit={useHookForm.handleSubmit(onSubmit)}>
                      <Controller
                        name="message"
                        control={useHookForm.control}
                        render={({ field }) => (
                          <TextFieldTheme
                            {...field}
                            maxRows={5}
                            multiline
                            inputProps={{
                              placeholder: `${t("WriteMessage")}`,
                            }}
                          />
                        )}
                      />

                      <Box marginTop="16px">
                        <Typography>
                          {t("ProgressPercentage")}: {progressPercent}%
                        </Typography>
                        <PrettoSlider
                          value={progressPercent}
                          onChange={(e, newValue) =>
                            setProgressPercent(newValue)
                          }
                          valueLabelDisplay="auto"
                          min={IndividualKeyResult.progressPercent || 0}
                          max={100}
                          step={1}
                          aria-labelledby="progress-slider"
                        />
                      </Box>
                      <Box
                        display="flex"
                        justifyContent="flex-end"
                        marginTop="16px"
                      >
                        <ResponsiveButtonBlue
                          variant="contained"
                          type="submit"
                          disabled={useHookForm.formState.isSubmitting}
                        >{`${t("Update")}`}</ResponsiveButtonBlue>
                      </Box>
                    </form>
                  </Box>
                </CardStyle>
              </Box>

              <Box marginTop="24px">
                {IndividualKeyResult.individualKeyResultProgress.map(
                  (progress, index) => (
                    <KeyResultProgress
                      key={index}
                      data={progress}
                      handleUpdateKeyResultProgress={
                        handleUpdateKeyResultProgress
                      }
                    />
                  )
                )}
              </Box>
            </Fragment>
          )}
        </Container>
      </Box>
    </div>
  );
};

const StyledKeyResultProgressRoot = styled(Box)({
  marginBottom: "16px",
  "&:last-child": {
    marginBottom: 0,
  },
});

const KeyResultProgress = (props) => {
  const { data, handleUpdateKeyResultProgress } = props;
  const [mode, setMode] = useState(false);
  const [edit, setEdit] = useState("");

  const handleChangeMode = () => {
    setEdit(data.message);
    setMode((prev) => !prev);
  };

  const handleUpdate = () => {
    setMode(false);
    handleUpdateKeyResultProgress(data.idIndividualKeyResultProgress, {
      message: edit,
    });
  };

  return (
    <StyledKeyResultProgressRoot>
      <CardStyle>
        <Box padding="24px">
          {mode ? (
            <TextFieldTheme
              value={edit}
              onChange={(e) => setEdit(e.target.value)}
            />
          ) : (
            <Typography whiteSpace="pre-line">{data.message}</Typography>
          )}
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems={"center"}
            gap={1}
          >
            {mode ? (
              <>
                <IconButton onClick={handleUpdate} color="success">
                  <DoneIcon />
                </IconButton>
                <IconButton onClick={handleChangeMode} color="error">
                  <ClearIcon />
                </IconButton>
              </>
            ) : (
              <IconButton onClick={handleChangeMode} color="primary">
                <Edit />
              </IconButton>
            )}
            <Typography fontSize="12px" color="text.third">
              {dayjs(data.updateDate).format("D MMM BBBB HH.mm")}
            </Typography>
          </Box>
        </Box>
      </CardStyle>
    </StyledKeyResultProgressRoot>
  );
};

export default UpdateProgressIndividualKeyResult;
