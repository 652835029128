import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";

import { Box, MenuItem, Stack, Typography } from "@mui/material";

import TextFieldTheme from "../../../../../shared/general/TextFieldTheme";
import ButtonBlue from "../../../../../shared/general/ButtonBlue";

import {
  postGpsLocations,
  updateGpsLocations,
  getAllLocations,
} from "../../../../../../../actions/gpsLocations";

//Translator TH-EN
import { useTranslation } from "react-i18next";

import AddMap from "./addMap";
import EditMap from "./editMap";
import AddAndPositionMap from "./addAndPositionMap";
import EditAndPositionMap from "./editAndPositionMap";

const StyledRoot = styled("div")({
  "& .wrap-action": {
    display: "flex",
    justifyContent: "flex-end",
  },
});

const StyledContentLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
});

const FormGpsPositions = (props) => {
  const {
    handleClose,
    selectedGroup,
    mode,
    handleOpenAlert,
    handleChangeAlertType,
    selectedCompany,
  } = props;
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { result: userProfile } = useSelector((state) => state.userProfile);
  const [textField, setTextField] = useState("");
  const [selectedType, setSelectedType] = useState(null);
  const [radius, setRadius] = useState(300);
  const [radiusInform, setRadiusInform] = useState(100);
  const [formData, setFormData] = useState({
    name: "",
    idCompany: "",
    isActive: 1,
    idGroupGpsLocations: "",
    idGpsType: selectedType,
    positions: [],
    lat: null,
    lng: null,
    radius: radius,
    radiusInform: radiusInform
  });

  useEffect(() => {
    if (mode === "edit" && selectedGroup) {
      setTextField(selectedGroup.name);

      var temp = { ...formData };
      temp.name = selectedGroup.name;
      temp.positions = selectedGroup.positions;
      temp.idGpsLocations = selectedGroup.idGpsLocations;
      temp.idGpsType = selectedGroup.idGpsType;
      temp.lat = selectedGroup.lat;
      temp.lng = selectedGroup.lng;
      temp.radius = selectedGroup.radius;
      temp.radiusInform = selectedGroup.radiusInform;

      setFormData(temp);
    }
  }, [selectedGroup]);

  useEffect(() => {
    if (mode === "add") {
      handleReset();
    }
  }, [mode]);

  const handleSaveGpsLocations = async () => {
    if (userProfile) {
      formData.name = textField;
      formData.idCompany = selectedCompany.idCompany;
      formData.createBy = userProfile.idEmployees;
      formData.createDate = dayjs(new Date()).format("YYYY-MM-DD");
      formData.idGroupGpsLocations = selectedGroup.idGroupGpsLocations;
      formData.radius = (selectedType === 2 ? radius : null);
      formData.radiusInform = (selectedType === 2 ? radiusInform : null);
      formData.idGpsType = selectedType;
      formData.isActive = 1;

      const result = await dispatch(postGpsLocations(formData));
      if (result) {
        handleOpenAlert();
        if (result.status === 200) {
          handleChangeAlertType("success");
          dispatch(getAllLocations({ idCompany: selectedCompany.idCompany }));
        } else {
          handleChangeAlertType("error");
        }
        handleClose();
      } else {
        handleOpenAlert();
        handleChangeAlertType("error");
        handleClose();
      }
    } else {
      handleClose();
    };
  };

  const handleUpdateGpsLocations = async () => {
    if (userProfile) {
      formData.name = textField;
      formData.idCompany = selectedCompany.idCompany;
      formData.editBy = userProfile.idEmployees;
      formData.editDate = dayjs(new Date()).format("YYYY-MM-DD");
      formData.idGroupGpsLocations = selectedGroup.idGroupGpsLocations;
      formData.idGpsLocations = selectedGroup.idGpsLocations;

      if (formData.idGpsType === 2) {
        formData.lat = formData.positionsNew && formData.positionsNew[0].lat;
        formData.lng = formData.positionsNew && formData.positionsNew[0].lng;

        delete formData.positions;
        delete formData.positionsNew;
      };

      if (formData.positionsNew && formData.idGpsType === 1) {
        let tempPositions = selectedGroup.positions.map((item) => ({
          ...item,
          isActive: 0,
        }));

        let tempPositionsNew = formData.positionsNew.map((item) => ({
          ...item,
          idGpsLocations: selectedGroup.idGpsLocations,
          idGroupGpsLocations: selectedGroup.idGroupGpsLocations,
          isActive: formData.isActive
        }));

        formData.positions = tempPositions;
        formData.positionsNew = tempPositionsNew;
      };

      const result = await dispatch(updateGpsLocations(formData));
      if (result) {
        handleOpenAlert();
        if (result.status === 200) {
          handleChangeAlertType("success");
          if (userProfile) {
            dispatch(getAllLocations({ idCompany: selectedCompany.idCompany }));
          }
        } else {
          handleChangeAlertType("error");
        }
        handleClose();
      } else {
        handleOpenAlert();
        handleChangeAlertType("error");
        handleClose();
      }
    } else {
      handleClose();
    }
  };

  const handleChange = (event) => {
    setTextField(event.target.value);
  };

  const handleChangePositions = (positions) => {
    setFormData({ ...formData, positions: positions });
  };

  const handleChangePositionsNew = (positions) => {
    setFormData({ ...formData, positionsNew: positions });
  };

  const handleReset = () => {
    setTextField("");
    setFormData({
      name: "",
      idCompany: "",
      isActive: true,
      idGpsLocations: "",
      idGroupGpsLocations: "",
      idGpsType: null,
      positions: null,
      lat: null,
      lng: null,
      radius: radius,
      radiusInform: radiusInform
    });
  };

  const handleTypeChange = (newType) => {
    if (formData) {
      setFormData((prevState) => ({
        ...prevState,
        idGpsType: newType,
      }));
    }

    setSelectedType(newType);
  };

  const handleRadiusChange = (newRadius) => {
    if (!isNaN(newRadius)) {
      if (formData) {
        setFormData((prevState) => ({
          ...prevState,
          radius: newRadius,
        }));
      }

      setRadius(newRadius);
    }
  };

  const handleRadiusInformChange = (newRadius) => {
    if (!isNaN(newRadius)) {
      if (formData) {
        setFormData((prevState) => ({
          ...prevState,
          radiusInform: newRadius,
        }));
      }

      setRadiusInform(newRadius);
    }
  };

  return (
    <StyledRoot>
      <>
        <div style={{ marginBottom: 16 }}>
          <div style={{ marginBottom: 16 }}>

            <Stack justifyContent="space-between" alignItems="center" direction={"row"}>
              <StyledContentLabel color="text.third" gutterBottom>
                {t("LocationName")}
              </StyledContentLabel>

            </Stack>

            <TextFieldTheme
              onChange={handleChange}
              name={"name"}
              value={textField}
            />
          </div>

          {textField && (
            <div style={{ marginBottom: 16 }}>
              <TextFieldTheme
                select
                name="idGpsType"
                label="เลือกประเภท"
                onChange={(e) => handleTypeChange(e.target.value)}
                value={(formData && formData.idGpsType) || selectedType}
              >
                <MenuItem value={1}>
                  สร้างกรอบแผนที่
                </MenuItem>
                <MenuItem value={2}>
                  ปักหมุด
                </MenuItem>
              </TextFieldTheme>
            </div>
          )}

          {(selectedType === 1 || (formData && formData.idGpsType === 1)) &&
            (
              <div>
                {mode === "add" ? (
                  <AddMap
                    selectedGroup={selectedGroup}
                    handleChangePositions={handleChangePositions}
                  />
                ) : (
                  <EditMap
                    selectedGroup={selectedGroup}
                    handleChangePositions={handleChangePositionsNew}
                  />
                )}
              </div>
            )
          }

          {(selectedType === 2 || (formData && formData.idGpsType) === 2) &&
            (
              <div>
                <TextFieldTheme
                  label="ใส่จำนวนรัศมี (m)"
                  value={formData && formData.radius}
                  name="radius"
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (!isNaN(inputValue) && inputValue.trim() !== "") {
                      handleRadiusChange(Number(inputValue));
                    } else {
                      handleRadiusChange("");
                    }
                  }}
                  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                />

                <div style={{ marginTop: 16 }}>
                  <TextFieldTheme
                    label="ระยะเผื่อเช็คอินห่างจากรัศมีได้ไม่เกิน (ระยะเป็นเมตร (m))"
                    value={formData && formData.radiusInform}
                    name="radiusInform"
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (!isNaN(inputValue) && inputValue.trim() !== "") {
                        handleRadiusInformChange(Number(inputValue));
                      } else {
                        handleRadiusInformChange("");
                      }
                    }}
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  />
                </div>

                <div style={{ marginTop: 16 }}>
                  {mode === "add" ? (
                    <AddAndPositionMap
                      radius={radius}
                      selectedGroup={selectedGroup}
                      handleChangePositions={handleChangePositions}
                    />
                  ) : (
                    <EditAndPositionMap
                      radius={formData.radius}
                      selectedGroup={selectedGroup}
                      handleChangePositions={handleChangePositionsNew}
                    />
                  )}
                </div>
              </div>
            )
          }
        </div>

        <div className="wrap-action">
          <ButtonBlue
            onClick={() => {
              if (mode === "add") {
                handleReset();
              }
              handleClose();
            }}
          >
            {t("Cancel")}
          </ButtonBlue>

          <ButtonBlue
            onClick={mode === "add" ? handleSaveGpsLocations : handleUpdateGpsLocations}
            variant={"contained"}
            autoFocus
            disabled={formData.positions == null || textField === ""}
          >
            {t("SaveData")}
          </ButtonBlue>
        </div>
      </>
    </StyledRoot>
  );
};

export default FormGpsPositions;