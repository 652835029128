import React from "react";
import {
  Box,
  Container,
  styled,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import DailyPanel from "./DailyPanel";
import IndividualPanel from "./IndividualPanel";
import ScheduleMonthly from "./ScheduleMonthly";
//Translator TH-EN
import { useTranslation } from "react-i18next";
import EmployeeShiftPanel from "./employeeShiftPanel";
import { useSelector } from "react-redux";

const StyledRoot = styled(Box)({
  backgroundColor: "#FFFFFF !important",
});

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  marginTop: 24,
  ["@media only screen and (max-width: 600px)"]: {
    width: "100%",
    flexDirection: "column",
  },

  "& .MuiToggleButtonGroup-grouped": {
    color: "#46cbe2",
    border: "1px solid #46cbe2",
    backgroundColor: "#FFFFFF",
    "&.MuiToggleButton-root": {
      minWidth: 150,
      marginRight: 12,
      ["@media only screen and (max-width: 600px)"]: {
        marginBottom: 8,
      },
    },
    "&:hover": {
      borderColor: "#247785",
      backgroundColor: "#247785",
      color: "#FFFFFF",
    },
    "&.Mui-selected": {
      color: "#FFFFFF",
      backgroundColor: "#46cbe2",
      "&:hover": {
        borderColor: "#247785",
        backgroundColor: "#247785",
        color: "#FFFFFF",
      },
    },
    "&.Mui-disabled": {
      border: 0,
    },
    "&:not(:first-of-type)": {
      borderRadius: 8,
      borderLeft: "1px solid #46cbe2",
    },
    "&:first-of-type": {
      borderRadius: 8,
    },
  },
}));

const TimeManagement = () => {
  const [view, setView] = React.useState("daily");
  const { t, i18n } = useTranslation();
  const { result: userProfile } = useSelector((state) => state.userProfile);

  const handleChangeView = (event, newView) => {
    if (newView) {
      setView(newView);
      // setSelectedEmployee(null);
      // if (newView === "all") {
      //   dispatch(getHoliday(tempSearchDate));
      //   dispatch(getShiftPattern());
      //   //dispatch(getAttendanceCompany(search));
      // }
    }
  };

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <div style={{ marginBottom: 8 }}>
          <Typography variant="h4" style={{ paddingTop: 8 }}>
            {`${t("TimeManagement")}`}
          </Typography>
        </div>

        <StyledToggleButtonGroup
          value={view}
          exclusive
          onChange={handleChangeView}
          aria-label="view"
          size="small"
        >
          <ToggleButton
            name="status"
            value="daily"
            aria-label="daily"
            size="small"
          >
            {`${t("DailyInformation")}`}
          </ToggleButton>
          <ToggleButton
            name="status"
            value="individual"
            aria-label="individual"
            size="small"
          >
            {`${t("PersonalInformation")}`}
          </ToggleButton>
          <ToggleButton
            name="status"
            value="all-employee-monthly"
            aria-label="all-employee-monthly"
            size="small"
          >
            {`${t("AppMenu.Timeline")}`}
          </ToggleButton>
          {[1, 44, 37, 38, 39, 40, 41, 42, 43, 49, 60, 19].includes(userProfile.idCompany) && (
            <ToggleButton
              name="status"
              value="employee-shift"
              aria-label="employee-shift"
              size="small"
            >
              {t("ShiftSchedule")}
            </ToggleButton>
          )}
        </StyledToggleButtonGroup>

        {view === "daily" && <DailyPanel />}
        {view === "individual" && <IndividualPanel />}
        {view === "all-employee-monthly" && <ScheduleMonthly />}
        {view === "employee-shift" && <EmployeeShiftPanel />}
      </Container>
    </StyledRoot>
  );
};

export default TimeManagement;
